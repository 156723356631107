import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { ProjectDesignData } from 'types';

import { projectDesignDuplicated, resetSubmittedForm } from 'state/slices/projectDesignsSlice';
import { AppDispatch, RootState } from 'state/store';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS, initialValues } from '../initialValues';
import { validationSchema } from '../validationSchema';
import './DuplicateDesignModal.scss';

const DuplicateDesignModal = ({
  selectedDesign,
  isModalVisible,
  handleModalClose,
}: {
  selectedDesign: ProjectDesignData;
  isModalVisible: boolean;
  handleModalClose: () => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { project } = useSelector((state: RootState) => state.project);
  const { isRequestPending, isRequestSuccessful } = useSelector(
    (state: RootState) => state.projectDesigns
  );
  const { t } = useTranslation();

  const { touched, isValid, errors, dirty, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema: validationSchema([selectedDesign]),
    onSubmit: (values) => {
      if (project?.id && selectedDesign?.id) {
        dispatch(
          projectDesignDuplicated({
            projectId: project?.id,
            designId: selectedDesign?.id,
            name: values.name,
          })
        );
      }
    },
  });

  const handleModalVisibility = useCallback(() => {
    handleModalClose();
  }, [handleModalClose]);

  useEffect(() => {
    if (isRequestSuccessful) {
      handleModalVisibility();
      dispatch(resetSubmittedForm());
    }
    return () => {
      resetForm();
    };
  }, [dispatch, handleModalVisibility, isRequestSuccessful, resetForm]);

  const isFormValid = isValid && dirty;

  return (
    // @ts-ignore antd is not compatible with @types/react v18
    <Modal
      visible={isModalVisible}
      onCancel={handleModalVisibility}
      className="DuplicateDesignModal"
      {...MODAL_PROPS}
    >
      <span className="DuplicateDesignModal-Title">
        {t('DuplicateDesignModal.createNewDesign')}
      </span>
      <form onSubmit={handleSubmit}>
        <div className="FormControl-Wrapper">
          <label htmlFor="designName">{t('DuplicateDesignModal.designName')}</label>
          <Input
            placeholder={t('DuplicateDesignModal.placeholder') ?? ''}
            className={validateStatus(touched, errors, FORM_CONTROLS.NAME, dirty)}
            type={FORM_CONTROLS.NAME}
            {...getFieldProps(FORM_CONTROLS.NAME)}
            id="designName"
            autoFocus
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.NAME, dirty)}
          </div>
        </div>
        <div className="DuplicateDesignModal-ButtonWrapper">
          <Button onClick={handleModalClose} className="Button--White">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            htmlType="submit"
            className="Button--Blue"
            loading={isRequestPending}
            autoFocus={isFormValid}
          >
            {t('DuplicateDesignModal.button')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DuplicateDesignModal;
