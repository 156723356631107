import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CallbackEvent } from 'react-map-gl/src/components/draggable-control';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams, useRouteMatch } from 'react-router-dom';

import { Alert, Button, Input, Tooltip } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';
import { isEqual, pickBy } from 'lodash-es';
import { MAP_DEFAULTS, MAP_ZOOM } from 'screens/MapWrapper/constants';

import {
  projectRequested,
  setTempProjectValues,
  userSubmittedForm,
  userSubmittedProject,
  userUpdatedProject,
} from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftProjectConsumptionDataRoute,
  draftProjectInfo,
  newProjectConsumptionDataRoute,
  projectInfoRoute,
  rootRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDraftConsumptionDataRoute,
  scaniflyAdminDraftScaniflyInfoRoute,
  scaniflyAdminNewProjectConsumptionDataRoute,
  scaniflyAdminNewProjectScaniflyInfoRoute,
  scaniflyAdminProjectScaniflyInfoRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import ClearDataModal from './ClearDataModal';
import { MONTHS, USAGE_LIMIT } from './constants';
import './CustomerInfo.scss';
import CustomerInfoMap from './CustomerInfoMap';
import CustomerInfoUsage from './CustomerInfoUsage';
import { mapCreatedProject } from './helpers';
import { FormFields, FormValues, InitialValues, UsageMap } from './types';
import { isCoordsProvided, validationSchema } from './validationSchema';

const ConsumptionData = ({ isAdminRoute = false }: { isAdminRoute?: boolean }) => {
  const { t } = useTranslation();
  const { isDesignServiceProvider } = usePermissions();
  const [isExiting, setIsExiting] = useState(false);
  const [viewport, setViewport] = useState(MAP_DEFAULTS);
  const [pin, setPin] = useState<{ latitude: number | null; longitude: number | null }>({
    latitude: null,
    longitude: null,
  });
  const [isManualPinSetting, setIsManualPinSetting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch: AppDispatch = useDispatch();
  const {
    isLoading,
    isSubmitted,
    error: submissionError,
    project: createdProject,
    tempProjectData,
  } = useSelector((state: RootState) => state.project);

  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? newProjectConsumptionDataRoute()
        : scaniflyAdminNewProjectConsumptionDataRoute(),
    }),
  };

  const { isExact: isDraftProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? draftProjectConsumptionDataRoute(createdProject?.id)
        : scaniflyAdminDraftConsumptionDataRoute(createdProject?.id),
    }),
  };

  const isDraft =
    createdProject?.statusDescription === PROJECT_STATUSES.DRAFT ||
    !projectId ||
    createdProject?.statusDescription === PROJECT_STATUSES.NO_FLIGHT;

  // Prevent interaction with a project that does not agree on draft status.
  // if this is a draft route and not a draft project
  // or
  // if this is a draft project and not a draft route
  useEffect(() => {
    if (
      !isNewProject &&
      projectId === createdProject?.id &&
      ((isDraftProject && !isDraft) || (!isDraftProject && isDraft))
    ) {
      history.push(rootRoute());
    }
  }, [createdProject, history, isDraft, isDraftProject, isNewProject, projectId]);

  const {
    touched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    getFieldProps,
    setFieldValue,
    setValues,
    values,
    resetForm,
  } = useFormik<FormValues>({
    initialValues: InitialValues,
    validationSchema,
    onSubmit: (values) => {
      projectId ? updateCustomerInfo(projectId, values) : submitCustomerInfo(values);
    },
  });

  const isFormChanged = !isEqual(pickBy(values), mapCreatedProject(createdProject));

  const isFormValid = isFormChanged && isValid && dirty && isCoordsProvided(values);
  const isSavedFormValid = isValid && isCoordsProvided(values);

  const isEligibleForSave = createdProject ? isSavedFormValid : isFormValid;

  useEffect(() => {
    dispatch(projectRequested(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    const usage: UsageMap = {
      january: '',
      february: '',
      march: '',
      april: '',
      may: '',
      june: '',
      july: '',
      august: '',
      september: '',
      october: '',
      november: '',
      december: '',
    };
    if (!projectId && tempProjectData) {
      Object.values(MONTHS).forEach((month) => {
        const value = tempProjectData.usage[month];
        if (value) {
          usage[month as keyof UsageMap] = value;
        }
      });
      setValues((prevValues) => ({
        ...prevValues,
        [FormFields.projectName]: tempProjectData.projectName,
        [FormFields.address]: tempProjectData.address,
        [FormFields.firstName]: tempProjectData.firstName,
        [FormFields.lastName]: tempProjectData.lastName,
        [FormFields.usage]: usage,
        [FormFields.email]: tempProjectData.email,
        [FormFields.phoneNumber]: tempProjectData.phoneNumber,
        [FormFields.latitude]: tempProjectData.latitude,
        [FormFields.longitude]: tempProjectData.longitude,
      }));
      setPin((prevPin) => ({
        ...prevPin,
        latitude: tempProjectData.latitude,
        longitude: tempProjectData.longitude,
      }));
      setViewport((prevViewport) => ({
        ...prevViewport,
        latitude: tempProjectData.latitude,
        longitude: tempProjectData.longitude,
        zoom: MAP_ZOOM,
      }));
    } else if (createdProject && projectId) {
      Object.values(MONTHS).forEach((month, i) => {
        const value = createdProject.kwh[i];
        if (value) {
          usage[month as keyof UsageMap] = value.toString();
        }
      });
      setValues((prevValues) => ({
        ...prevValues,
        [FormFields.projectName]: createdProject.name,
        [FormFields.address]: createdProject.address ?? '',
        [FormFields.firstName]: createdProject.ownerDetails?.firstName ?? '',
        [FormFields.lastName]: createdProject.ownerDetails?.lastName ?? '',
        [FormFields.usage]: usage,
        [FormFields.email]: createdProject.ownerDetails?.email ?? '',
        [FormFields.phoneNumber]: createdProject.ownerDetails?.phone ?? '',
        [FormFields.latitude]: createdProject.geolocation.latitude,
        [FormFields.longitude]: createdProject.geolocation.longitude,
      }));
      if (createdProject.geolocation.latitude && createdProject.geolocation.longitude) {
        setPin((prevPin) => ({
          ...prevPin,
          latitude: createdProject.geolocation.latitude,
          longitude: createdProject.geolocation.longitude,
        }));
        setViewport((prevViewport) => ({
          ...prevViewport,
          latitude: createdProject.geolocation.latitude,
          longitude: createdProject.geolocation.longitude,
          zoom: MAP_ZOOM,
        }));
      }
    } else if (!projectId) {
      resetForm();
      setPin((prevPin) => ({
        ...prevPin,
        latitude: null,
        longitude: null,
      }));
    }
  }, [createdProject, projectId, dispatch, resetForm, isAdminRoute, tempProjectData, setValues]);

  useEffect(() => {
    setFieldValue(FormFields.latitude, pin.latitude, false);
    setFieldValue(FormFields.longitude, pin.longitude, false);
    if (pin.latitude && pin.longitude && isManualPinSetting) {
      setIsManualPinSetting(false);
    }
  }, [isManualPinSetting, pin.latitude, pin.longitude, setFieldValue]);

  useEffect(() => {
    if (Boolean(values.latitude) && Boolean(values.longitude) && isManualPinSetting) {
      setIsManualPinSetting(false);
    }
  }, [isManualPinSetting, values.latitude, values.longitude]);

  const getNextRoute = useCallback(() => {
    if (isExiting) {
      return isAdminRoute ? scaniflyAdminCustomerSupportUploadRoute() : rootRoute();
    }
    if (isAdminRoute && isNewProject) {
      return scaniflyAdminNewProjectScaniflyInfoRoute();
    }
    if (isAdminRoute && isDraft) {
      return scaniflyAdminDraftScaniflyInfoRoute(createdProject?.id);
    }
    if (isAdminRoute) {
      return scaniflyAdminProjectScaniflyInfoRoute(createdProject?.id);
    }
    if (isDraft) {
      return draftProjectInfo(createdProject?.id);
    }
    return projectInfoRoute(createdProject?.id);
  }, [createdProject, isAdminRoute, isDraft, isExiting, isNewProject]);

  useEffect(() => {
    if (isSubmitted) {
      history.push(getNextRoute());
    }
    return () => {
      isSubmitted && dispatch(userSubmittedForm());
    };
  }, [
    createdProject,
    dispatch,
    getNextRoute,
    history,
    isExiting,
    isFormValid,
    isNewProject,
    isSubmitted,
  ]);

  const renderSubmissionError = () => (
    <Alert
      type="error"
      className={cn('CustomerInfo-Error', {
        'CustomerInfo-Error--Usage': true,
      })}
      message="Oops! Something went wrong. Please try again."
      closable
    />
  );

  const submitCustomerInfo = (values: FormValues) => {
    if (isAdminRoute) {
      dispatch(setTempProjectValues(values));
      return history.push(getNextRoute());
    }
    dispatch(userSubmittedProject(values));
  };

  const updateCustomerInfo = (projectId: string, values: FormValues) => {
    if (!isFormChanged) {
      return history.push(getNextRoute());
    }
    dispatch(userUpdatedProject({ projectId, values }));
  };

  const handleCancel = () =>
    history.push(isAdminRoute ? scaniflyAdminCustomerSupportUploadRoute() : rootRoute());

  const handleSubmitAndExit = () => {
    setIsExiting(true);
    handleSubmit();
  };

  const handleMarkerDragEnd = (event: CallbackEvent) => {
    setPin({
      latitude: event.lngLat[1],
      longitude: event.lngLat[0],
    });
  };

  const getMissingRequiredFieldNames = ({
    projectName,
    address,
  }: {
    projectName: string;
    address: string;
  }) => {
    if (!projectName && !address) {
      return 'name and address';
    }
    if (!address) {
      return 'address';
    }
    if (!projectName) {
      return 'name';
    }
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleInputChange = (value: string | number, inputName: string) => {
    if (parseFloat(`${value}`) <= USAGE_LIMIT) {
      setFieldValue(inputName, value);
    }
    if (value === '') {
      setFieldValue(inputName, value);
    }
  };

  return (
    <>
      {modalVisible ? (
        <ClearDataModal
          modalVisible={modalVisible}
          hideModal={hideModal}
          handleInputChange={handleInputChange}
        />
      ) : null}
      <div className="CustomerInfo">
        <div className="CustomerInfo-Form-Wrapper">
          <form onSubmit={handleSubmit} className="CustomerInfo-Form">
            <div>
              {isAdminRoute ? (
                <div className="CustomerInfo-SubmittedFor">
                  {createdProject?.submittedFor &&
                    !isNewProject &&
                    `Submitted for: ${createdProject.submittedFor.firstName} ${createdProject.submittedFor.lastName} at ${createdProject.submittedFor.company.name}`}
                </div>
              ) : null}
              <div className="FormControl-Wrapper CustomerInfo-Form-ProjectName">
                <label htmlFor="projectName">Project Name*</label>
                <Input
                  placeholder="New Project"
                  className={validateStatus(touched, errors, FormFields.projectName)}
                  {...getFieldProps(FormFields.projectName)}
                  id="projectName"
                />
                <div className="Form-Error">
                  {renderValidationMessage(touched, errors, FormFields.projectName)}
                </div>
              </div>
              <div
                className={cn('CustomerInfo-Form-TabWrapper', {
                  'CustomerInfo-Form-TabWrapper--Usage': true,
                })}
              >
                <span className="CustomerInfo-Form-Message">
                  <span className="CustomerInfo-Form-Message--Usage">
                    Enter your customer’s electricity usage.
                  </span>
                  <span className="CustomerInfo-Form-Message--Usage">
                    You can enter this later if you don’t have it handy.
                  </span>
                </span>
              </div>
              <CustomerInfoUsage
                getFieldProps={getFieldProps}
                usage={values.usage}
                handleInputChange={handleInputChange}
                showModal={showModal}
              />

              {submissionError && renderSubmissionError()}
            </div>
            <div className="CustomerInfo-Form-Buttons">
              <div className="CustomerInfo-Form-Buttons-Wrapper">
                {isDraft ? (
                  <>
                    <Button className="Button--White" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <div>
                      {!(isAdminRoute && isNewProject) && (
                        <Tooltip
                          title={
                            !isFormValid &&
                            !isSavedFormValid &&
                            `Please enter project
                      ${getMissingRequiredFieldNames(values)}
                      before exiting`
                          }
                        >
                          <Button
                            className="Button--White"
                            onClick={handleSubmitAndExit}
                            disabled={!isEligibleForSave}
                            aria-disabled={!isEligibleForSave}
                            loading={isExiting && isLoading}
                          >
                            Save &amp; Exit
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip
                        title={
                          !isFormValid &&
                          !isSavedFormValid &&
                          `Please enter project
                    ${getMissingRequiredFieldNames(values)}
                    before continuing`
                        }
                      >
                        <Button
                          className="Button--Blue CustomerInfo-Form-Buttons-Submit"
                          disabled={!isEligibleForSave}
                          aria-disabled={!isEligibleForSave}
                          htmlType="submit"
                          loading={!isExiting && isLoading}
                        >
                          {isAdminRoute && isNewProject
                            ? t('buttonTexts.next')
                            : t('buttonTexts.saveAndContinue')}
                        </Button>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <div className="CustomerInfo-Form-Buttons-Submit-Wrapper">
                    <Tooltip
                      title={
                        !isFormValid &&
                        !isSavedFormValid &&
                        `Please enter project
                    ${getMissingRequiredFieldNames(values)}
                    before updating`
                      }
                    >
                      <Button
                        className="Button--Blue CustomerInfo-Form-Buttons-Submit"
                        disabled={isDesignServiceProvider || !isEligibleForSave}
                        aria-disabled={isDesignServiceProvider || !isEligibleForSave}
                        htmlType="submit"
                        loading={isLoading}
                        data-testid="consumption-data-update"
                      >
                        Update
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <CustomerInfoMap
          viewport={viewport}
          setViewport={setViewport}
          pin={pin}
          setPin={setPin}
          handleMarkerDragEnd={handleMarkerDragEnd}
          isManualPinSetting={isManualPinSetting}
        />
      </div>
    </>
  );
};

export default ConsumptionData;
