import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Modal } from 'antd';
import ServiceRequest from 'types/ServiceRequest';

import { serviceRequestDeletionRequested } from 'state/slices/designServices/designServicesQueueSlice';
import { AppDispatch } from 'state/store';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import './ConfirmDeleteModal.scss';

type Props = {
  isDeleteModalOpen: boolean;
  toggleDeleteModal: () => void;
  serviceRequest?: ServiceRequest;
};

const ConfirmDeleteModal = ({ isDeleteModalOpen, toggleDeleteModal, serviceRequest }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const onSuccess = (id: string) => {
    openNotification({
      type: 'success',
      title: 'Service Request Deleted',
      text: `Service request with the order ID ${id} has been successfully deleted.`,
    });
  };

  const handleDeleteServiceRequest = () => {
    if (serviceRequest) {
      dispatch(serviceRequestDeletionRequested({ id: serviceRequest.id, onSuccess }));
      toggleDeleteModal();
    }
  };

  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isDeleteModalOpen}
      onCancel={toggleDeleteModal}
      className="ConfirmDeleteModal"
      {...MODAL_PROPS}
    >
      <XIcon
        onClick={toggleDeleteModal}
        className="close-modal-icon"
        data-testid="close-modal-icon"
      />
      <>
        <h2 className="ConfirmDeleteModal-Title">
          {t('DesignService.designServiceQueue.deleteServiceRequestModal.title')}
        </h2>
        <p>
          {t('DesignService.designServiceQueue.deleteServiceRequestModal.content', {
            orderId: serviceRequest?.id ?? '',
          })}
        </p>
      </>

      <div className="ConfirmDeleteModal-Buttons-Wrapper">
        <Button onClick={toggleDeleteModal} className="Button--White">
          {t('DesignService.designServiceQueue.deleteServiceRequestModal.cancel')}
        </Button>
        <Button onClick={handleDeleteServiceRequest} className="Button--Blue">
          {t('DesignService.designServiceQueue.deleteServiceRequestModal.delete')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
