import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Modal } from 'antd';
import cn from 'classnames';
import { useFormik } from 'formik';

import {
  allMaintenanceReportsRequested,
  maintenanceReportCreationRequested,
  maintenanceReportUpdateRequested,
  resetMaintenanceReportState,
} from 'state/slices/maintenanceReport/maintenanceReportSlice';
import { AppDispatch, RootState } from 'state/store';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import { FORM_CONTROLS, initialValues } from '../constants/form';
import { validationSchema } from '../constants/validationSchema';
import './CreateReportModal.scss';

type Props = {
  isModalOpen: boolean;
  toggleModal: () => void;
  projectId: string;
  companyId: string;
  isRenameModal: boolean;
  selectedReportId?: string;
  setSelectedReportId?: Dispatch<SetStateAction<string | null>>;
};

const CreateOrRenameReportModal = ({
  isModalOpen,
  toggleModal,
  projectId,
  companyId,
  isRenameModal,
  selectedReportId,
  setSelectedReportId,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const { createdReport } = useSelector((state: RootState) => state.maintenanceReport);

  const { touched, isValid, dirty, errors, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      isRenameModal ? handleUpdateReportTitle(values.title) : handleAddNewReport(values.title);
    },
  });

  useEffect(() => {
    if (createdReport && setSelectedReportId) {
      setSelectedReportId(createdReport.id);
      dispatch(resetMaintenanceReportState());
    }
  });

  const handleAddNewReport = (title: string) => {
    if (projectId && companyId) {
      dispatch(
        maintenanceReportCreationRequested({
          projectId,
          companyId,
          title,
        })
      );
    }

    resetForm();
    toggleModal();
  };

  const onUpdateSuccess = () => {
    openNotification({
      type: 'success',
      title: 'Success!',
      text: `Report name has been successfully updated!`,
    });
    dispatch(allMaintenanceReportsRequested({ projectId }));
  };

  const handleUpdateReportTitle = (title: string) => {
    if (projectId && selectedReportId) {
      dispatch(
        maintenanceReportUpdateRequested({
          onUpdateSuccess,
          reportId: selectedReportId,
          projectId,
          title,
        })
      );
    }

    resetForm();
    toggleModal();
  };

  const isFormValid = isValid && dirty;

  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isModalOpen}
      onCancel={toggleModal}
      className="CreateReportModal"
      {...MODAL_PROPS}
    >
      <XIcon onClick={toggleModal} className="close-modal-icon" data-testid="close-modal-icon" />
      <>
        <span className="CreateReportModal-Title">
          {isRenameModal
            ? t('MaintenanceReport.buttonText.renameReport')
            : t('MaintenanceReport.buttonText.createReport')}
        </span>
        <form onSubmit={handleSubmit}>
          <label className="CreateReportModal-Label" htmlFor="createReportModal">
            {t('MaintenanceReport.createOrUpdateModal.label')}
          </label>
          <div className="FormControl-Wrapper">
            <Input
              prefix={<CategoryIcon />}
              placeholder={t('MaintenanceReport.createOrUpdateModal.placeholder') ?? 'Name'}
              className={cn(
                'CreateReportModal-Input Input--HighAndLong',
                validateStatus(touched, errors, FORM_CONTROLS.TITLE)
              )}
              id="createReportModal"
              {...getFieldProps(FORM_CONTROLS.TITLE)}
            />
            <div className="Form-Error">
              {renderValidationMessage(touched, errors, FORM_CONTROLS.TITLE)}
            </div>
          </div>

          <div className="AddNewProjectCategoryModal-Buttons-Wrapper">
            <Button onClick={toggleModal} className="Button--White">
              {t('MaintenanceReport.buttonText.cancel')}
            </Button>
            <Button
              onClick={() => handleSubmit()}
              disabled={!isFormValid}
              aria-disabled={!isFormValid}
              className="Button--Blue"
              autoFocus={isFormValid}
            >
              {isRenameModal
                ? t('MaintenanceReport.buttonText.renameReport')
                : t('MaintenanceReport.buttonText.createReport')}
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
};

export default CreateOrRenameReportModal;
