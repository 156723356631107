import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { kebabCase } from 'lodash-es';

import { resetProject } from 'state/slices/projectSlice';
import { AppDispatch } from 'state/store';

import { PAGE_TITLES } from 'helpers/constants/pageTitles';
import {
  scaniflyAdminAccessTokensRoute,
  scaniflyAdminAccountingRoute,
  scaniflyAdminAccountManagerRoute,
  scaniflyAdminAllCreditHistoryRoute,
  scaniflyAdminCustomerSupportUploadRoute,
  scaniflyAdminDesignServicesQueueRoute,
  scaniflyAdminProjectsRoute,
  scaniflyAdminReferenceMediaRoute,
  scaniflyAdminUsersRoute,
} from 'helpers/constants/routes';

import { ReactComponent as UsersIcon } from 'assets/account-profile-icon.svg';
import { ReactComponent as PaymentsInvoicesIcon } from 'assets/billing-icon.svg';
import { ReactComponent as AccountManagerIcon } from 'assets/company-settings-icon.svg';
import { ReactComponent as DesignServicesQueueIcon } from 'assets/design-queue.svg';
import { ReactComponent as LockIcon } from 'assets/login-security-icon.svg';
import { ReactComponent as MediaIcon } from 'assets/media.svg';
import { ReactComponent as ProjectsIcon } from 'assets/projects.svg';
import { ReactComponent as CreditsIcon } from 'assets/sidebar-credits.svg';
import { ReactComponent as CustomerSupportUploadIcon } from 'assets/upload-queue.svg';

import './ScaniflyAdminMenu.scss';

const ScaniflyAdminMenu = () => {
  const dispatch: AppDispatch = useDispatch();

  const menuItems = useMemo(() => {
    const items = [
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_USERS,
        route: scaniflyAdminUsersRoute(),
        icon: <UsersIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_ACCOUNT_MANAGER,
        route: scaniflyAdminAccountManagerRoute(),
        icon: <AccountManagerIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_CS_UPLOAD,
        route: scaniflyAdminCustomerSupportUploadRoute(),
        icon: <CustomerSupportUploadIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_PROJECTS,
        route: scaniflyAdminProjectsRoute(),
        icon: <ProjectsIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_ACCOUNTING,
        route: scaniflyAdminAccountingRoute(),
        icon: (
          <PaymentsInvoicesIcon className="ScaniflyAdminMenu-Button-Icon ScaniflyAdminMenu-Button-Icon--Admin-Accounting" />
        ),
      },
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_ACCESS_TOKENS,
        route: scaniflyAdminAccessTokensRoute(),
        icon: <LockIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
      {
        title: PAGE_TITLES.DESIGN_SERVICES_QUEUE,
        route: scaniflyAdminDesignServicesQueueRoute(),
        icon: <DesignServicesQueueIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_CREDIT_HISTORY,
        route: scaniflyAdminAllCreditHistoryRoute(),
        icon: <CreditsIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
      {
        title: PAGE_TITLES.SCANIFLY_ADMIN_REFERENCE_MEDIA,
        route: scaniflyAdminReferenceMediaRoute(),
        icon: <MediaIcon className="ScaniflyAdminMenu-Button-Icon" />,
      },
    ];
    return items;
  }, []);

  useEffect(() => {
    dispatch(resetProject());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ScaniflyAdminMenu">
      <div className="ScaniflyAdminMenu-Wrapper">
        {menuItems.map(
          ({ title, route, icon }: { title: string; route: string; icon: JSX.Element }) => (
            <Link
              to={route}
              className="ScaniflyAdminMenu-Button"
              id={`ScaniflyAdminMenu-Button-${kebabCase(title)}`}
              key={`ScaniflyAdminMenu-Button-${kebabCase(title)}`}
            >
              {icon}
              <span className="ScaniflyAdminMenu-Button-Text">{title}</span>
            </Link>
          )
        )}
      </div>
    </div>
  );
};

export default ScaniflyAdminMenu;
