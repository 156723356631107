import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MaintenanceReport } from '@cpm/scanifly-shared-data';
import { Dropdown, Menu, Tooltip } from 'antd';

import { RootState } from 'state/store';

import { ReactComponent as CopyIcon } from 'assets/icons/copy-icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as EllipsisIcon } from 'assets/icons/ellipsis-icon.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/new-tab-icon.svg';
import { ReactComponent as TrashcanIcon } from 'assets/icons/trashcan-icon.svg';

import colors from 'helpers/constants/colors';
import './ActionButtons.scss';

const ActionButtons = ({
  report,
  handleRenameButtonClick,
  toggleDeleteModal,
  setSelectedReportId,
}: {
  report: MaintenanceReport;
  handleRenameButtonClick: () => void;
  toggleDeleteModal: () => void;
  setSelectedReportId: Dispatch<SetStateAction<string | null>>;
}) => {
  const { t } = useTranslation();

  const { allReports, isAllReportsLoading } = useSelector(
    (state: RootState) => state.maintenanceReport
  );

  useEffect(() => {
    if (allReports && !isAllReportsLoading) {
      setSelectedReportId((oldReportId) => oldReportId ?? allReports?.[0].id);
    }
  }, [allReports, isAllReportsLoading, setSelectedReportId]);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleCopyLink = (reportId: string) => {
    navigator.clipboard.writeText(`${window.location.origin}/maintenance-report/${reportId}`);
    setIsTooltipVisible(true);
    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 1500);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <button
          aria-label={t('MaintenanceReport.buttonText.rename') ?? 'rename report'}
          className="ActionButtons-DropdownMenuButton"
          onClick={handleRenameButtonClick}
        >
          <EditIcon className="ActionButtons-DropdownMenuIcon" />
          {t('MaintenanceReport.buttonText.rename')}
        </button>
      </Menu.Item>
      <Menu.Item>
        <button
          aria-label={t('MaintenanceReport.buttonText.delete') ?? 'delete report'}
          className="ActionButtons-DropdownMenuButton"
          onClick={toggleDeleteModal}
          disabled={!report}
        >
          <TrashcanIcon className="ActionButtons-DropdownMenuIcon" />
          {t('MaintenanceReport.buttonText.delete')}
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="ActionButtons-Wrapper">
      <Tooltip
        visible={isTooltipVisible}
        placement="bottom"
        title={t('MaintenanceReport.copySuccess')}
      >
        <button
          aria-label="copy link"
          onClick={() => handleCopyLink(report.id)}
          className="ActionButtons-Button"
        >
          <CopyIcon />
        </button>
      </Tooltip>
      <a
        aria-label={t('MaintenanceReport.buttonText.newTab') ?? 'open link in new tab'}
        className="ActionButtons-Button"
        href={`${window.location.origin}/maintenance-report/${report.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <NewTabIcon fill={colors.darkGray} />
      </a>
      {/* @ts-ignore */}
      <Dropdown overlay={menu} placement="topCenter" trigger={['click']}>
        <button
          aria-label={t('MaintenanceReport.buttonText.subMenu') ?? 'open sub menu'}
          className="ActionButtons-Button"
        >
          <EllipsisIcon />
        </button>
      </Dropdown>
    </div>
  );
};

export default ActionButtons;
