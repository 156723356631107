import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Project } from 'types';
import { ProjectFilter, TableParams } from 'types/TableParams';

import { adminProjectsRequested } from 'state/slices/admin/adminProjectsSlice';
import { AppDispatch, RootState } from 'state/store';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { getSortBy } from 'helpers/utils/getSortBy';
import { getFilterByForProjects } from 'screens/DesignServices/helpers/getFilterBy';

import BaseProjects from './BaseProjects';

const Projects = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { allProjects, projectCount } = useSelector((state: RootState) => state.adminProjects);
  const [filteredData, setFilteredData] = useState(allProjects);
  const [searchText, setSearchText] = useState<string>('');
  const [tableParams, setTableParams] = useState<TableParams<Project, ProjectFilter>>({
    pagination: {
      current: 1,
    },
  });

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};
    dispatch(
      adminProjectsRequested({
        size: PROJECT_LIST_SIZE,
        pageIndex: pagination?.current ?? 1,
        sortBy: getSortBy(columnKey, order),
        filterBy: getFilterByForProjects(filters),
      })
    );
  }, [dispatch, tableParams]);

  useEffect(() => {
    const filtered = allProjects.filter((item) => {
      return (
        item.name?.includes(searchText) ||
        item?.type?.includes(searchText) ||
        item?.statusDescription?.includes(searchText) ||
        item?.id.includes(searchText) ||
        item?.company?.name?.includes(searchText)
      );
    });
    setFilteredData(filtered);
  }, [allProjects, searchText]);

  return (
    <BaseProjects
      tableName={TABLE_NAMES.PROJECTS}
      isCustomerOrUserProjectsTable={false}
      baseData={{ data: filteredData, projectCount: projectCount }}
      setTableParams={setTableParams}
      setSearchText={setSearchText}
    />
  );
};

export default Projects;
