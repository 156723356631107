import {
  ChecklistTemplateType,
  ChecklistTemplateTypeEnum,
  ChecklistType,
} from '@cpm/scanifly-shared-data';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { removeMissingMedia } from './checklistThunks';
import {
  DELETE_CHECKLIST_TEMPLATE,
  SLICE_NAMESPACE,
  UPDATE_ADMIN_CHECKLIST_TEMPLATES,
  UPDATE_CHECKLIST,
  UPDATE_CHECKLIST_TEMPLATE,
  UPDATE_CHECKLIST_TEMPLATES,
  UPDATE_CHECKLISTS,
} from './constants';
import { ChecklistsState } from './types';

const initialState: ChecklistsState = {
  checklists: {},
  checklistsOrder: {},
  templates: {},
  templatesOrder: [],
};

const checklistsSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers: {
    [UPDATE_CHECKLIST]: (state, { payload: checklist }: PayloadAction<ChecklistType>) => {
      if (checklist?.id) {
        const projectId = checklist?.project?.id;
        state.checklists[checklist.id] = {
          ...(state.checklists[checklist.id] ?? {}),
          ...checklist,
        };
        if (projectId) {
          const checklistIdsForProject = state.checklistsOrder[projectId] ?? [];
          if (checklistIdsForProject && !checklistIdsForProject?.includes(checklist.id)) {
            state.checklistsOrder[projectId] = [...checklistIdsForProject, checklist.id];
          }
        }
      }
    },
    [UPDATE_CHECKLISTS]: (state, { payload: checklists }: PayloadAction<ChecklistType[]>) => {
      if (Array.isArray(checklists)) {
        let projectId = '';
        const ids = checklists.map((checklist) => {
          projectId = checklist?.project?.id;
          state.checklists[checklist.id] = {
            ...state.checklists[checklist.id],
            ...checklist,
          };
          return checklist.id;
        });
        if (projectId) {
          state.checklistsOrder[projectId] = ids;
        }
      }
    },
    [UPDATE_CHECKLIST_TEMPLATE]: (
      state,
      { payload: checklistTemplate }: PayloadAction<ChecklistTemplateType>
    ) => {
      if (checklistTemplate?.id) {
        state.templates[checklistTemplate.id] = {
          ...(state.templates[checklistTemplate.id] ?? {}),
          ...checklistTemplate,
        };
        if (!state.templatesOrder.includes(checklistTemplate.id)) {
          state.templatesOrder = [checklistTemplate.id, ...state.templatesOrder];
        }
      }
    },
    [UPDATE_CHECKLIST_TEMPLATES]: (
      state,
      { payload: checklistTemplates }: PayloadAction<ChecklistTemplateType[]>
    ) => {
      if (Array.isArray(checklistTemplates)) {
        const ids = checklistTemplates.map((checklistTemplate) => {
          state.templates[checklistTemplate.id] = {
            ...(state.templates[checklistTemplate.id] ?? {}),
            ...checklistTemplate,
          };
          return checklistTemplate.id;
        });
        state.templatesOrder = ids;
      }
    },
    [UPDATE_ADMIN_CHECKLIST_TEMPLATES]: (
      state,
      { payload: checklistTemplates }: PayloadAction<ChecklistTemplateType[]>
    ) => {
      if (Array.isArray(checklistTemplates)) {
        const existingIds = new Set(state.templatesOrder);
        const newIds = checklistTemplates.map((checklistTemplate) => {
          const updatedChecklistTemplate = {
            ...checklistTemplate,
            type: ChecklistTemplateTypeEnum.scaniflyDefault,
          };
          state.templates[checklistTemplate.id] = {
            ...(state.templates[checklistTemplate.id] ?? {}),
            ...updatedChecklistTemplate,
          };

          return checklistTemplate.id;
        });
        const uniqueIds = newIds.filter((id) => !existingIds.has(id));
        state.templatesOrder = [...state.templatesOrder, ...uniqueIds];
      }
    },
    [DELETE_CHECKLIST_TEMPLATE]: (state, { payload: templateId }: PayloadAction<string>) => {
      if (templateId) {
        delete state.templates[templateId];
        state.templatesOrder = state.templatesOrder.filter((id) => id !== templateId);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeMissingMedia.fulfilled, (state, { payload }) => {
      const projectId = payload.project.id;
      state.checklists[payload.id] = {
        ...(state.checklists[payload.id] ?? {}),
        ...payload,
      };
      const checklistIdsForProject = state.checklistsOrder[projectId] ?? [];
      if (checklistIdsForProject && !checklistIdsForProject?.includes(payload.id)) {
        state.checklistsOrder[projectId] = [...checklistIdsForProject, payload.id];
      }
    });
  },
});

const { actions } = checklistsSlice;
export { actions };

export default checklistsSlice.reducer;
