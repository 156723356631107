import { ReferenceMedia } from '@cpm/scanifly-shared-data';
import { createSelector } from 'reselect';

import { RootState } from 'state/store';

/**
 * For an explanation of why the strange EMPTY_ARRAY usage
 * https://reselect.js.org/usage/handling-empty-array-results
 */
const EMPTY_ARRAY: [] = [];

const selectReferenceMediaMap = (state: RootState): { [key: string]: ReferenceMedia[] } =>
  state.referenceMedia.referenceMediaByCategory;

const categoryIdPassThrough = (_: RootState, param: string): string => param;
const selectMediaByCategory = createSelector(
  [selectReferenceMediaMap, categoryIdPassThrough],
  (mediaByCategory, categoryId): ReferenceMedia[] => {
    if (!mediaByCategory[categoryId]?.length) {
      return EMPTY_ARRAY;
    }
    return mediaByCategory[categoryId];
  }
);

export { selectMediaByCategory, selectReferenceMediaMap };
