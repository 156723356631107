import { useContext, useEffect, useState } from 'react';
import { isMobile as isMobileDevice, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, Modal, Tooltip } from 'antd';
import { SidebarContext } from 'screens/Sidebar';

import { RootState } from 'state/store';

import { getConfigS3DV3 } from 'api/config/configService';

import { ACCESS } from 'helpers/constants/access';
import { MODAL_PROPS } from 'helpers/constants/modals';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import { useViewportSize } from 'helpers/hooks/useViewportSize';

import { ReactComponent as ShareIcon } from 'assets/icons/share-icon.svg';

import { getLayoutWidth } from '../helpers';
import ThreeDEmbedModal from './ThreeDEmbedModal/ThreeDEmbedModal';
import './ThreeDView.scss';
import { ThreeDViewProps } from './types';

const ThreeDView = ({ projectId, designId }: ThreeDViewProps) => {
  const { t } = useTranslation();
  const isMobile = isMobileDevice || isTablet;
  const iframeHeightOffset = isMobile ? 300 : 162;

  const [isModelLinkCopied, setIsModelLinkCopied] = useState(false);
  const [isAnimationLinkCopied, setIsAnimationLinkCopied] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modelUrl, setModelUrl] = useState<string>();

  const { width, height } = useViewportSize();

  const { isSidebarCollapsed } = useContext(SidebarContext);

  const { project } = useSelector((state: RootState) => state.project);

  const { isScaniflyAdmin, isDesignServiceProvider } = usePermissions();

  const featureToggleS3D = useFeatureToggle(ACCESS.S3D_V3);

  // TODO: remove when viewer embed mode works without the auth token
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    const getModelUrl = async (userId?: string) => {
      const useV3 =
        (isScaniflyAdmin || isDesignServiceProvider) && userId
          ? (await getConfigS3DV3(userId)).data.useV3
          : featureToggleS3D;

      const host = useV3 ? process.env.REACT_APP_S3D_URL : process.env.REACT_APP_VIEWER_URL;

      setModelUrl(`${host}?ID=${projectId}&did=${designId}&embed=true`);
    };

    if (project?.createdById) {
      getModelUrl(project.createdById);
    }
  }, [
    project?.createdById,
    projectId,
    designId,
    isScaniflyAdmin,
    isDesignServiceProvider,
    featureToggleS3D,
  ]);

  const copyText = (toCopy: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = toCopy; // create text area with value to copy, then remove it from the dom
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  const handleShareCopy = () => {
    if (modelUrl) {
      copyText(modelUrl);
      setIsModelLinkCopied(true); // set tooltip to display 'copied!' for a few seconds before reverting to original value
      setTimeout(setIsModelLinkCopied, 3000, false);
    }
  };

  const handleAnimationCopy = () => {
    if (modelUrl) {
      copyText(`${modelUrl}&animation=default`);
      setIsAnimationLinkCopied(true); // set tooltip to display 'copied!' for a few seconds before reverting to original value
      setTimeout(setIsAnimationLinkCopied, 3000, false);
    }
  };

  const handleModalOpen = () => setModalVisible(true);

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <div className={`ThreeDView ${isMobile ? 'mobileCol' : ''}`}>
      {designId && (
        <>
          {/* @ts-ignore antd is not compatible with @types/react v18 */}
          <Modal
            visible={modalVisible}
            onCancel={handleModalClose}
            title={t('Designs.threeDModelEmbed')}
            {...MODAL_PROPS}
          >
            {modelUrl ? <ThreeDEmbedModal modelUrl={modelUrl} /> : null}
          </Modal>
          <div className="ThreeDView-Container">
            <div className={`ThreeDView-Buttons ${isMobile ? 'mobileCol' : ''}`}>
              <Tooltip
                placement="top"
                title={isModelLinkCopied ? t('Designs.copied') : t('Designs.copyToClipboad')}
                arrowPointAtCenter
              >
                <Button onClick={handleShareCopy} data-testid="share-3d-model">
                  <ShareIcon />
                  {t('Designs.shareThreeDModel')}
                </Button>
              </Tooltip>
              <Tooltip
                placement="top"
                title={
                  isAnimationLinkCopied ? t('Designs.copied') : t('Designs.copyAnimationToClipboad')
                }
                arrowPointAtCenter
              >
                <Button onClick={handleAnimationCopy} data-testid="share-3d-model-animation">
                  <ShareIcon />
                  {t('Designs.shareThreeDModelAnimation')}
                </Button>
              </Tooltip>
              <Tooltip placement="top" title={t('Designs.generateEmbed')} arrowPointAtCenter>
                <Button onClick={handleModalOpen} data-testid="embed-3d-model">
                  <ShareIcon />
                  {t('Designs.embedModel')}
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className="IframeWrapper">
            {modelUrl && height ? (
              <iframe
                title="3d model"
                role="img"
                width={getLayoutWidth(width ?? 0, isSidebarCollapsed)}
                height={(height - iframeHeightOffset).toString()}
                allowFullScreen
                frameBorder={0}
                src={`${modelUrl}&token=${token}`}
              />
            ) : (
              <p>{t('Designs.loading')}</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ThreeDView;
