import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CopyOutlined } from '@ant-design/icons';
import {
  DESIGN_STATUS_FILTERS,
  DESIGN_TIER_LEVEL_FILTERS,
  TIER_LEVEL,
} from '@cpm/scanifly-shared-data';
import { SortOrder } from 'antd/lib/table/interface';
import { DATE_OPTIONS } from 'screens/ScaniflyAdmin/CustomerSupportUpload/constants';
import Revision from 'types/Revision';
import ServiceRequest from 'types/ServiceRequest';

import { Scanifly3DButton } from 'components';
import ViewFormButton from '../components/ViewFormButton/ViewFormButton';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import { handleCopyClick } from 'helpers/utils/handleCopyClick';
import { getCorrectRouteForDesigns } from 'screens/DesignServices/helpers/getCorrectRouteForDesigns';

import { TextValueMap } from '@cpm/scanifly-shared-data/lib/util/types';
import { Tooltip } from 'antd';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { getFormattedTimeDifference } from 'helpers/utils/getFormattedTimeDifference';
import { REMOTE_DESIGN_STATUSES } from 'screens/DesignServices/constants';
import { TooltipWrapper } from '../TableData';
import ViewRevisionsButton from './ViewRevisionsButton/ViewRevisionsButton';

type Props = {
  handleFormModalOpen: () => void;
  handleViewRevisionsClick: (
    project: { id: string; name: string },
    modelRevision: Revision | Revision[]
  ) => void;
  setFormData: Dispatch<SetStateAction<{}>>;
  setProjectName: Dispatch<SetStateAction<string>>;
  designServicesPrelimAccess: boolean;
};

const TableData = ({
  handleFormModalOpen,
  handleViewRevisionsClick,
  setFormData,
  setProjectName,
  designServicesPrelimAccess,
}: Props) => {
  const { t } = useTranslation();

  const updatedTierFiltersBasedOnAccess = designServicesPrelimAccess
    ? DESIGN_TIER_LEVEL_FILTERS
    : DESIGN_TIER_LEVEL_FILTERS.filter(
        (tier) => tier.value !== TIER_LEVEL.siteModeling && tier.value !== TIER_LEVEL.maxFill
      );

  type Column = {
    title: string | (() => JSX.Element);
    key?: string;
    width: string;
    dataIndex: string | string[];
    sorter?: (a: ServiceRequest, b: ServiceRequest) => number;
    render?: (name: string, item: ServiceRequest) => any;
    filters?: TextValueMap<string>[];
    defaultSortOrder?: SortOrder;
  };

  const columns: Column[] = [
    {
      title: 'Project',
      key: 'projectName',
      dataIndex: ['project', 'name'],
      width: '18rem',
      sorter: (a: ServiceRequest, b: ServiceRequest) =>
        a.project?.name.localeCompare(b.project?.name),
      render: (name: string, item: ServiceRequest) => {
        return (
          <Link data-testid="project-link" to={getCorrectRouteForDesigns(item)}>
            {name}
          </Link>
        );
      },
    },
    {
      title: 'Design Status',
      width: '18rem',
      dataIndex: 'status',
      filters: DESIGN_STATUS_FILTERS,
      render: (_: any, item: ServiceRequest) => {
        return DESIGN_STATUS_FILTERS.find((project) => project.value === item.status)?.text;
      },
    },
    {
      title: 'Tier Level',
      width: '18rem',
      dataIndex: 'tierLevel',
      filters: updatedTierFiltersBasedOnAccess,
      render: (_: any, item: ServiceRequest) => {
        return `${
          updatedTierFiltersBasedOnAccess.find((project) => project.value === item.tierLevel)?.text
        }${item.upgradedFrom ? ' ' + t('DesignService.uploadQueue.upgradeTag') : ''}${
          item.remote ? `\n${t('DesignService.uploadQueue.remoteTag')}` : ''
        }`;
      },
    },
    {
      title: t('TableHeadings.expedited'),
      width: '12rem',
      dataIndex: 'isExpedited',
      key: 'isExpedited',
      render: (_: any, item: ServiceRequest) => {
        return `${item?.isExpedited ? t('Generic.yes') : t('Generic.no')}`;
      },
    },
    {
      title: t('TableHeadings.newBuildPlans'),
      width: '15rem',
      dataIndex: 'newBuildPlansRequired',
      render: (_: any, item: ServiceRequest) => {
        return `${item?.form?.newBuildPlansRequired ? t('Generic.yes') : t('Generic.no')}`;
      },
    },
    {
      title: 'Revisions',
      dataIndex: 'modelRevisionCount',
      key: 'modelRevisionCount',
      width: '12rem',
      sorter: (a: ServiceRequest, b: ServiceRequest) =>
        (a?.modelRevisionCount ?? '').localeCompare(b?.modelRevisionCount ?? ''),
      render: (modelRevisionCount: string) => modelRevisionCount ?? '0',
    },
    {
      title: 'Revision Reasons',
      dataIndex: 'modelRevision',
      key: 'modelRevision',
      width: '18rem',
      render: (_: any, item: ServiceRequest) => (
        <ViewRevisionsButton item={item} handleViewRevisionsClick={handleViewRevisionsClick} />
      ),
    },
    {
      title: 'Scanifly3D',
      dataIndex: 'scanifly3d',
      width: '15rem',
      render: (_: any, item: ServiceRequest) => (
        <Scanifly3DButton
          projectId={item.project?.id}
          buttonClass="Button--Blue"
          isDesignServicesQueue={true}
          isServiceRequestRemote={REMOTE_DESIGN_STATUSES.includes(item.tierLevel)}
        />
      ),
    },
    {
      title: 'Intake Form',
      dataIndex: 'intakeForm',
      width: '12rem',
      render: (_: any, item: ServiceRequest) => (
        <ViewFormButton
          item={item}
          handleModalOpen={handleFormModalOpen}
          setFormData={setFormData}
          setProjectName={setProjectName}
        />
      ),
    },
    {
      title: () => {
        return (
          <TooltipWrapper>
            <Tooltip
              placement="topLeft"
              title={t('tooltipTexts.turnaroundTime')}
              arrowPointAtCenter
            >
              <QuestionMarkIcon />
            </Tooltip>
            {t('TableHeadings.turnaroundTime')}
          </TooltipWrapper>
        );
      },
      dataIndex: 'completedDate',
      key: 'completedDate',
      width: '22.5rem',
      render: (_, item) => {
        return item?.completedDate
          ? getFormattedTimeDifference(new Date(item.completedDate), new Date(item.createdAt))
          : '--';
      },
    },
    {
      title: 'Type',
      width: '18rem',
      dataIndex: ['project', 'type'],
      filters: PROJECT_TYPE_FILTERS,
      render: (_: any, item: ServiceRequest) => {
        return (
          PROJECT_TYPE_FILTERS.find((project) => project.value === item.project.type)?.text ?? '--'
        );
      },
    },
    {
      title: 'Order ID',
      dataIndex: 'id',
      width: '10rem',
      render: (id: string, item: ServiceRequest) => {
        return (
          <div className="DesignServices-OrderButtonWrapper">
            {id}
            <CopyOutlined
              role="button"
              aria-label="copy order id"
              className="DesignServices-OrderButtonWrapper-CopyIcon"
              onClick={() => handleCopyClick(id, 'Order ID', item.project.name)}
            />
          </div>
        );
      },
    },
    {
      title: 'Requested At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '22.5rem',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a: ServiceRequest, b: ServiceRequest) => a.createdAt.localeCompare(b.createdAt),
      render: (createdAt: string) => {
        return new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
      },
    },
  ];

  return columns;
};

export default TableData;
