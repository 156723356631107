import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import cn from 'classnames';

import { companySubscriptionInfoRequested } from 'state/slices/companySlice';
import { projectsRequested } from 'state/slices/projectsSlice';
import { AppDispatch, RootState } from 'state/store';

import {
  accountChecklistTemplateManagerRoute,
  designServicesCompanyQueueRoute,
  designServicesProviderQueueRoute,
  newProjectCustomerInfoRoute,
  projectsListRoute,
  scaniflyAdminNewProjectConsumptionDataRoute,
  scaniflyAdminNewProjectCustomerInfoRoute,
  scaniflyAdminNewProjectScaniflyInfoRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';

import { ReactComponent as CollapseTrigger } from 'assets/icons/collapse-trigger.svg';

import { SIDEBAR_WIDTH } from './constants';
import SidebarBody from './Menus/SidebarBody';
import SidebarFooter from './Menus/SidebarFooter';
import SidebarHeader from './Menus/SidebarHeader';
import './Sidebar.scss';
import { SidebarContext } from './SidebarProvider';

const { Sider } = Layout;
const selectedKey = '';

const Sidebar = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { isDesignServiceProvider } = usePermissions();

  const { isSidebarCollapsed, toggleisSidebarCollapsed } = useContext(SidebarContext);

  const [isMyProjectsPathActive, setIsMyProjectsPathActive] = useState(true);
  const [isTeamPathActive, setIsTeamPathActive] = useState(false);
  const [isScaniflyAdminPathActive, setIsScaniflyAdminPathActive] = useState(false);
  const [isScaniflyAdminView, setIsScaniflyAdminView] = useState(
    location.pathname.startsWith('/scanifly-admin/projects') ||
      location.pathname.startsWith('/scanifly-admin/new-project')
  );
  const [isChecklistTemplateManagerPathActive, setIsChecklistTemplateManagerPathActive] =
    useState(false);

  const [isDesignServicesProviderQueuePathActive, setIsDesignServicesProviderQueuePathActive] =
    useState(true);
  const [isDesignServicesCompanyQueuePathActive, setIsDesignServicesCompanyQueuePathActive] =
    useState(true);
  const { project } = useSelector((state: RootState) => state.project);
  const { company, companySubscriptionInfo } = useSelector((state: RootState) => state.company);

  const isProjectView =
    location.pathname.includes(`projects/${project?.id}`) ||
    location.pathname.includes(`designServices/`) ||
    location.pathname.includes(`maintenance-report/project/${project?.id}`);
  const isDraftProjectView = location.pathname.includes('new');

  // Not sure how to simplify this monstrosity so I'll just explain it via this comment
  // if this is not a scanifly admin view
  //  path = newProjectCustomerInfoRoute
  // if this is a scanfily admin view and the route contains scanifly-info
  //  path = scaniflyAdminNewProjectScaniflyInfoRoute
  // if this is a scanifly admin view and the route contains consumption-data
  //  path = scaniflyAdminNewProjectConsumptionDataRoute
  // otherwise
  //  path = scaniflyAdminNewProjectCustomerInfoRoute
  const { isExact: isNewProjectView } = {
    ...useRouteMatch({
      path: !isScaniflyAdminView
        ? newProjectCustomerInfoRoute()
        : location.pathname.includes('scanifly-info')
          ? scaniflyAdminNewProjectScaniflyInfoRoute()
          : location.pathname.includes('consumption-data')
            ? scaniflyAdminNewProjectConsumptionDataRoute()
            : scaniflyAdminNewProjectCustomerInfoRoute(),
    }),
  };

  useEffect(() => {
    const { pathname } = location;
    // @ts-ignore @todo projectsRequested requires a whole lot of params and this component was not passing them
    pathname !== projectsListRoute() && dispatch(projectsRequested({}));

    setIsMyProjectsPathActive(
      (pathname.startsWith('/projects') || pathname.startsWith('/new-project')) &&
        !isScaniflyAdminPathActive
    );
    setIsTeamPathActive(pathname.startsWith('/team-list'));
    setIsScaniflyAdminPathActive(pathname.startsWith('/scanifly-admin'));
    setIsScaniflyAdminView(
      pathname.startsWith('/scanifly-admin/projects') ||
        pathname.startsWith('/scanifly-admin/new-project')
    );
    setIsDesignServicesProviderQueuePathActive(pathname === designServicesProviderQueueRoute());
    setIsDesignServicesCompanyQueuePathActive(pathname === designServicesCompanyQueueRoute());
    setIsChecklistTemplateManagerPathActive(pathname === accountChecklistTemplateManagerRoute());
  }, [dispatch, location, isScaniflyAdminPathActive]);

  useEffect(() => {
    if (!isDesignServiceProvider && company && !companySubscriptionInfo) {
      dispatch(companySubscriptionInfoRequested(company?.id));
    }
  }, [company, companySubscriptionInfo, dispatch, isDesignServiceProvider]);

  return (
    <Sider
      collapsible
      breakpoint={!isMobile ? 'md' : undefined}
      collapsed={isSidebarCollapsed}
      onCollapse={toggleisSidebarCollapsed}
      className={cn('Sidebar')}
      width={SIDEBAR_WIDTH}
      trigger={<CollapseTrigger />}
      aria-expanded={!isSidebarCollapsed}
      id="main-scanifly-sidebar"
    >
      <Menu
        mode="inline"
        defaultOpenKeys={isSidebarCollapsed ? [] : [selectedKey]}
        defaultSelectedKeys={[selectedKey]}
      >
        <div className="Sidebar-Container">
          <SidebarHeader
            isSidebarCollapsed={isSidebarCollapsed}
            isScaniflyAdminRoute={isScaniflyAdminPathActive}
            isNewProjectView={isNewProjectView}
          />
          <SidebarBody
            isSidebarCollapsed={isSidebarCollapsed}
            isScaniflyAdminPath={isScaniflyAdminPathActive}
            isScaniflyAdminProjectView={isScaniflyAdminView}
            isNewProjectView={!!isNewProjectView}
            isMyProjectsPathActive={isMyProjectsPathActive}
            isTeamPathActive={isTeamPathActive}
            isDesignServicesProviderQueuePathActive={isDesignServicesProviderQueuePathActive}
            isDesignServicesCompanyQueuePathActive={isDesignServicesCompanyQueuePathActive}
            isChecklistTemplateManagerPathActive={isChecklistTemplateManagerPathActive}
            isDraftProjectView={isDraftProjectView}
            isProjectView={isProjectView}
            className={''}
          />
        </div>
        <SidebarFooter isSidebarCollapsed={isSidebarCollapsed} className={''} />
      </Menu>
    </Sider>
  );
};
export default Sidebar;
