import { PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import { SORT } from 'screens/Projects/ProjectsList/constants';
import { BoundingBox, Folder, Project } from 'types';

import {
  adminReplaceReprocessedProjectUrl,
  adminScaniflyProjectsUrl,
  adminUpdateProjectUrl,
} from 'api/admin/adminUrls';
import API from 'api/config';

import {
  filterByBounds,
  filterByDate,
  filterByFolder,
  filterByLocation,
  filterByMore,
  filterBySearchText,
} from 'helpers/utils/projectsFilters';

import {
  cancelDesignServicesAutomationUrl,
  fetchProjectPinsUrl,
  fetchProjectsFiltersUrl,
  fetchProjectsForCSVExportUrl,
  fetchProjectsUrl,
  fetchProjectUrl,
  updateProjectsTeammatesUrl,
  uploadProjectUrl,
} from './projectsUrls';

export const createProject = (projectData: Partial<Project>) =>
  API.post(fetchProjectsUrl(), projectData);

export const updateProject = (
  projectId: string,
  projectData: Partial<Project> | { status: string }
) => API.patch(fetchProjectUrl(projectId), projectData);

export const updateProjectTypeAndMode = (projectId: string, type: PROJECT_TYPES, mode: string) =>
  API.patch(fetchProjectUrl(projectId), { type, mode });

export const fetchProject = (id: string) => API.get(fetchProjectUrl(id));

export const fetchProjectsFiltersOptions = () => API.get(fetchProjectsFiltersUrl());

export const updateProjectsTeammates = (projectId: string, userId: string) =>
  API.post(updateProjectsTeammatesUrl(projectId, userId));

export const deleteProjectsTeammates = (projectId: string, userId: string) =>
  API.delete(updateProjectsTeammatesUrl(projectId, userId));

export const uploadProject = (projectId: string) => API.post(uploadProjectUrl(projectId));

export const adminCreateProject = ({
  projectId,
  projectData,
}: {
  projectId: string;
  projectData: Partial<Project>;
}) => {
  if (projectId) {
    return API.post(adminScaniflyProjectsUrl(), {
      reprocessing: { projectId },
      ...projectData,
    });
  } else {
    return API.post(adminScaniflyProjectsUrl(), projectData);
  }
};

export const adminUpdateProject = (projectId: string, projectData: Partial<Project>) =>
  API.patch(adminUpdateProjectUrl(projectId), projectData);

export const adminReplaceReprocessedProject = (projectId: string) =>
  API.patch(adminReplaceReprocessedProjectUrl(projectId));

export const fetchProjectsForCSVExport = (companyId: string) =>
  API.get(fetchProjectsForCSVExportUrl(companyId));

export const fetchProjects = (
  pageIndex: number,
  size: number,
  folder: Folder,
  location: string,
  date: string,
  bounds: BoundingBox,
  sort: string,
  order: string,
  searchText: string,
  more: any
) => {
  const filters = {
    filterBy: {
      ...filterByDate(date),
      ...filterByBounds(bounds),
      ...filterByLocation(location),
      ...filterByFolder(folder),
      ...filterBySearchText(searchText),
      ...filterByMore(more),
    },
  };

  const sorting = {
    sortBy: {
      ...(sort && {
        [sort]: order || SORT.ASC,
      }),
    },
  };

  return API.get(fetchProjectsUrl(), {
    params: {
      pageIndex,
      size,
      ...filters,
      ...sorting,
    },
  });
};

export const fetchAllProjectPins = (
  folder: Folder,
  location: string,
  date: string,
  searchText: string,
  more: any
) => {
  const filters = {
    filterBy: {
      ...filterByDate(date),
      ...filterByLocation(location),
      ...filterByFolder(folder),
      ...filterBySearchText(searchText),
      ...filterByMore(more),
    },
  };

  return API.get(fetchProjectPinsUrl(), {
    params: {
      ...filters,
    },
  });
};

export const cancelDSAutomation = (projectId: string) =>
  API.patch(cancelDesignServicesAutomationUrl(projectId));
