import { useCallback } from 'react';

import { Button } from 'antd';
import { ProjectDesignData } from 'types';

import { fetchProjectDesigns } from 'api/projectDesigns/projectDesignsService';

import colors from 'helpers/constants/colors';
import useS3D from 'helpers/hooks/useS3D';
import { useTranslation } from 'react-i18next';
import { StyledButton } from 'screens/DesignServices/OrderDesign/DesignStatus/PendingApprovalActionButtons/PendingApprovalActionButtons';
import { PROJECT_COMPLETE_STATUSES } from 'screens/ScaniflyAdmin/CustomerSupportUpload/constants';

type ButtonProps = {
  projectId: string;
  projectStatus?: string;
  buttonClass?: string;
  isDesignServicesQueue?: boolean;
  isServiceRequestRemote?: boolean;
  isPendingApproval?: boolean;
};

const Scanifly3DButton = ({
  projectId,
  projectStatus,
  buttonClass = 'Button--White',
  isDesignServicesQueue = false,
  isServiceRequestRemote = false,
  isPendingApproval = false,
}: ButtonProps) => {
  const s3d = useS3D();
  const { t } = useTranslation();
  const isDisabled =
    isDesignServicesQueue || isPendingApproval
      ? false
      : !(projectStatus && PROJECT_COMPLETE_STATUSES.includes(projectStatus));

  const handleScanifly3D = useCallback(
    async (projectId: string) => {
      try {
        const { data } = await fetchProjectDesigns(projectId);
        let designId = data?.length ? data[0]?.id : null;
        if (isDesignServicesQueue && isServiceRequestRemote) {
          const remoteDesign = data.filter((design: ProjectDesignData) => design.remote);
          designId = remoteDesign[0].id;
        }
        if (data.length && designId) {
          window.open(s3d(data[0].projectId, designId), '_blank');
        }
      } catch (error) {
        console.error(error);
      }
    },
    [isDesignServicesQueue, isServiceRequestRemote, s3d]
  );

  if (isPendingApproval) {
    return (
      <StyledButton
        icon="reviewDesign"
        color={colors.darkBlue}
        width="15rem"
        data-testid="review-design-button"
        onClick={() => handleScanifly3D(projectId)}
      >
        {t('DesignStatusDisplay.reviewDesign')}
      </StyledButton>
    );
  }

  return (
    <Button
      className={buttonClass}
      onClick={() => handleScanifly3D(projectId)}
      id={`scanifly3d-${projectId}`}
      disabled={isDisabled}
      aria-disabled={isDisabled}
    >
      Scanifly3D
    </Button>
  );
};

export default Scanifly3DButton;
