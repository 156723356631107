import { PROJECT_TYPES, TIER_LEVEL_DESCRIPTIONS } from '@cpm/scanifly-shared-data';
import { Subscription } from 'types';

export const getAutomationServiceType = ({
  subscription,
  projectType,
}: {
  subscription: Subscription | null;
  projectType?: PROJECT_TYPES;
}) => {
  if (!subscription || !projectType) return null;

  if (subscription.automatedTrueUp) {
    return TIER_LEVEL_DESCRIPTIONS.trueUp;
  }

  if (
    (subscription.automatedWireframeLargeOnsite && projectType === PROJECT_TYPES.LARGE) ||
    (subscription.automatedWireframeSmallOnsite && projectType === PROJECT_TYPES.SMALL)
  ) {
    return TIER_LEVEL_DESCRIPTIONS.wireframe;
  }

  return null;
};
