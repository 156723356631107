import {
  ChecklistTemplateType,
  ChecklistTemplateTypeEnum,
  ChecklistType,
} from '@cpm/scanifly-shared-data';
import * as Sentry from '@sentry/react';
import { Dispatch } from 'redux';

import {
  createChecklistTemplate,
  deleteChecklistTemplate,
  fetchAllChecklists,
  fetchChecklist,
  getChecklistTemplate,
  getChecklistTemplates,
  importSiteCaptureTemplate,
  removeMissingMediaApi,
  resetChecklistTemplate,
  updateChecklistTemplate,
} from 'api/checklists/checklistsService';

import { createAppAsyncThunk } from 'state/store';
import { actions } from './checklistsSlice';
import {
  DELETE_CHECKLIST_TEMPLATE,
  REMOVE_MISSING_MEDIA,
  UPDATE_ADMIN_CHECKLIST_TEMPLATES,
  UPDATE_CHECKLIST,
  UPDATE_CHECKLISTS,
  UPDATE_CHECKLIST_TEMPLATE,
  UPDATE_CHECKLIST_TEMPLATES,
} from './constants';
import { RemoveMissingMediaPayload } from './types';

export const getAllChecklistsForProject = (projectId: string) => async (dispatch: Dispatch) => {
  const response = await fetchAllChecklists(projectId);
  if (response.data) {
    dispatch(actions[UPDATE_CHECKLISTS](response.data));
  }
  return response.data;
};

export const getChecklistById =
  (projectId: string, checklistId: string) => async (dispatch: Dispatch) => {
    const response = await fetchChecklist(projectId, checklistId);
    if (response.data) {
      dispatch(actions[UPDATE_CHECKLIST](response.data));
    }
    return response.data;
  };

export const getChecklistTemplateById = (templateId: string) => async (dispatch: Dispatch) => {
  const response = await getChecklistTemplate(templateId);
  if (response.data) {
    dispatch(actions[UPDATE_CHECKLIST_TEMPLATE](response.data));
  }
  return response.data as ChecklistTemplateType;
};

export const getAdminChecklistTemplatesForCompany =
  (companyId: string) => async (dispatch: Dispatch) => {
    const customerResponse = await getChecklistTemplates({ withUnpublished: true, companyId });
    const response = await getChecklistTemplates({ withUnpublished: true });
    if (customerResponse.data && response.data) {
      dispatch(actions[UPDATE_CHECKLIST_TEMPLATES](customerResponse.data));
      dispatch(actions[UPDATE_ADMIN_CHECKLIST_TEMPLATES](response.data));
    }
    return {
      customerTemplates: customerResponse.data,
      templates: response.data,
    };
  };

export const getChecklistTemplatesForCompany = () => async (dispatch: Dispatch) => {
  const response = await getChecklistTemplates({ withUnpublished: true });
  if (response.data) {
    dispatch(actions[UPDATE_CHECKLIST_TEMPLATES](response.data));
  }
  return response.data;
};

export const createAndStoreChecklistTemplate =
  (
    template: Omit<ChecklistTemplateType, 'id' | 'createdAt' | 'updatedAt' | 'companyId'>,
    companyId?: string
  ) =>
  async (dispatch: Dispatch) => {
    const response = await createChecklistTemplate(template, companyId);
    if (response.data) {
      dispatch(actions[UPDATE_CHECKLIST_TEMPLATE](response.data));
    }
    return response.data;
  };

export const importAndStoreSiteCaptureTemplate =
  (template: any, companyId?: string) => async (dispatch: Dispatch) => {
    const response = await importSiteCaptureTemplate(template, companyId);
    if (response.data.result) {
      dispatch(actions[UPDATE_CHECKLIST_TEMPLATE](response.data.result));
    }
    return response.data;
  };

export const updateChecklistTemplateById =
  (templateId: string, template: ChecklistTemplateType) => async (dispatch: Dispatch) => {
    const response = await updateChecklistTemplate(templateId, template);
    if (response.data) {
      dispatch(actions[UPDATE_CHECKLIST_TEMPLATE](response.data));
    }
    return response.data;
  };

export const resetChecklistTemplateById = (templateId: string) => async (dispatch: Dispatch) => {
  const response = await resetChecklistTemplate(templateId);
  if (response.data) {
    dispatch(actions[UPDATE_CHECKLIST_TEMPLATE](response.data));
  }
  return response.data;
};

export const deleteChecklistTemplateById = (templateId: string) => async (dispatch: Dispatch) => {
  const response = await deleteChecklistTemplate(templateId);
  if (response) {
    // I couldn't figure out how to make this type work without casting as any
    dispatch(actions[DELETE_CHECKLIST_TEMPLATE](templateId as any));
  }
  return true;
};

export const removeMissingMedia = createAppAsyncThunk<
  ChecklistType,
  RemoveMissingMediaPayload,
  { rejectValue: Error }
>(REMOVE_MISSING_MEDIA, async (payload, { rejectWithValue }) => {
  try {
    const response = await removeMissingMediaApi(payload);
    if (!response.data) {
      return rejectWithValue(new Error('Server error'));
    }
    return response.data;
  } catch (err: any) {
    Sentry.captureException(err);
    return rejectWithValue(err);
  }
});

export const copyChecklistTemplate =
  (template: ChecklistTemplateType, companyId?: string) => async (dispatch: Dispatch) => {
    let templateToClone = template;
    if (!template.checklistStructure) {
      templateToClone = await dispatch<any>(getChecklistTemplateById(template.id));
    }

    const newChecklistStructure = templateToClone.checklistStructure.map((section) => ({
      ...section,
      components: section.components.map((component) => {
        // eslint-disable-next-line
        const { exampleMedia, ...rest } = component;
        return rest;
      }),
    }));

    const clonedTemplate = JSON.parse(
      JSON.stringify({
        ...templateToClone,
        checklistStructure: newChecklistStructure,
        title: template.title,
        type: ChecklistTemplateTypeEnum.custom,
      })
    );

    delete clonedTemplate.id;
    delete clonedTemplate.createdAt;
    delete clonedTemplate.updatedAt;
    delete clonedTemplate.companyId;

    const newTemplate = await dispatch<any>(
      createAndStoreChecklistTemplate(clonedTemplate, companyId)
    );
    return newTemplate;
  };
