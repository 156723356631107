import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CopyOutlined } from '@ant-design/icons';
import {
  DESIGN_STATUS_FILTERS,
  DESIGN_TIER_LEVEL_FILTERS,
  mapSystemSizeValueToType,
  SYSTEM_SIZE_FILTERS,
} from '@cpm/scanifly-shared-data';
import { Button, Tooltip } from 'antd';
import { ColumnsType, SortOrder } from 'antd/lib/table/interface';
import { Revision } from 'types';
import ServiceRequest from 'types/ServiceRequest';

import styled from 'styled-components';

import { Scanifly3DButton } from 'components';
import DesignActionsDropdown from './components/DesignActionsDropdown/DesignActionsDropdown';
import ViewFormButton from './components/ViewFormButton/ViewFormButton';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import usePermissions from 'helpers/hooks/usePermissions';
import { getFormattedTimeDifference } from 'helpers/utils/getFormattedTimeDifference';
import { handleCopyClick } from 'helpers/utils/handleCopyClick';
import { getCorrectRouteForDesigns } from '../helpers/getCorrectRouteForDesigns';

import { ReactComponent as DollarSignIcon } from 'assets/icons/dollar-sign-solid.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as MapIcon } from 'assets/icons/map-solid.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';

import { DATE_OPTIONS } from '../../ScaniflyAdmin/CustomerSupportUpload/constants';
import { REMOTE_DESIGN_STATUSES } from '../constants';
import ViewRevisionsButton from './DSCompanyQueue/ViewRevisionsButton/ViewRevisionsButton';

type Props = {
  toggleViewFormModal: () => void;
  setFormData: Dispatch<SetStateAction<{}>>;
  setProjectName: Dispatch<SetStateAction<string>>;
  handleViewRevisionsClick: (
    project: { id: string; name: string },
    modelRevision: Revision | Revision[]
  ) => void;
  handleToggleDeleteModal: (serviceRequestId: string) => void;
  handleToggleEditDSPModal: (serviceRequestId: string) => void;
  handleToggleChargeExtraCreditsModal: (serviceRequestId: string) => void;
  handleToggleDeleteNearmapModal: (serviceRequestId: string) => void;
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 13rem;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 1rem;
  }
`;

const TableData = ({
  toggleViewFormModal,
  setFormData,
  setProjectName,
  handleViewRevisionsClick,
  handleToggleDeleteModal,
  handleToggleEditDSPModal,
  handleToggleChargeExtraCreditsModal,
  handleToggleDeleteNearmapModal,
}: Props) => {
  const { t } = useTranslation();
  const { isScaniflyAdmin } = usePermissions();

  const columns: ColumnsType<ServiceRequest> = [
    {
      title: t('TableHeadings.project'),
      key: 'projectName',
      dataIndex: ['project', 'name'],
      width: '18rem',
      sorter: (a: ServiceRequest, b: ServiceRequest) =>
        a.project?.name.localeCompare(b.project?.name),
      render: (name: string, item: ServiceRequest) => {
        return (
          <Link data-testid="project-link" to={getCorrectRouteForDesigns(item)}>
            {name}
          </Link>
        );
      },
    },
    {
      title: t('TableHeadings.designStatus'),
      width: '18rem',
      dataIndex: 'status',
      filters: DESIGN_STATUS_FILTERS,
      render: (_: any, item: ServiceRequest) => {
        return DESIGN_STATUS_FILTERS.find((project) => project.value === item.status)?.text;
      },
    },
    {
      title: t('TableHeadings.tierLevel'),
      width: '18rem',
      dataIndex: 'tierLevel',
      filters: DESIGN_TIER_LEVEL_FILTERS,
      render: (_: any, item: ServiceRequest) => {
        return `${
          DESIGN_TIER_LEVEL_FILTERS.find((project) => project.value === item.tierLevel)?.text
        }${item.upgradedFrom ? ' ' + t('DesignService.uploadQueue.upgradeTag') : ''}${
          item.remote ? `\n${t('DesignService.uploadQueue.remoteTag')}` : ''
        }`;
      },
    },
    {
      title: t('TableHeadings.expedited'),
      width: '12rem',
      dataIndex: 'isExpedited',
      render: (_: any, item: ServiceRequest) => {
        return `${item?.isExpedited ? t('Generic.yes') : t('Generic.no')}`;
      },
    },
    {
      title: t('TableHeadings.newBuildPlans'),
      width: '15rem',
      dataIndex: 'newBuildPlansRequired',
      render: (_: any, item: ServiceRequest) => {
        return `${item?.form?.newBuildPlansRequired ? t('Generic.yes') : t('Generic.no')}`;
      },
    },
    {
      title: t('TableHeadings.designActions'),
      dataIndex: 'action',
      width: '18rem',
      render: (_: any, item: ServiceRequest) => {
        return (
          <DesignActionsDropdown
            tierLevel={item?.tierLevel}
            designId={item?.id}
            projectName={item?.project?.name}
            status={item?.status}
          />
        );
      },
    },
    {
      title: t('TableHeadings.scanifly3d'),
      dataIndex: 'scanifly3d',
      width: '15rem',
      render: (_: any, item: ServiceRequest) => (
        <Scanifly3DButton
          projectId={item.project?.id}
          buttonClass="Button--Blue"
          isDesignServicesQueue={true}
          isServiceRequestRemote={REMOTE_DESIGN_STATUSES.includes(item.tierLevel)}
        />
      ),
    },
    {
      title: t('TableHeadings.intakeForm'),
      dataIndex: 'intakeForm',
      width: '12rem',
      render: (_: any, item: ServiceRequest) => (
        <ViewFormButton
          item={item}
          handleModalOpen={toggleViewFormModal}
          setFormData={setFormData}
          setProjectName={setProjectName}
        />
      ),
    },
    {
      title: t('TableHeadings.revisionCount'),
      width: '15rem',
      dataIndex: 'modelRevisionCount',
      render: (modelRevisionCount: string) => modelRevisionCount ?? '0',
    },
    {
      title: t('TableHeadings.revisionReasons'),
      dataIndex: 'modelRevision',
      key: 'modelRevision',
      width: '18rem',
      render: (_: any, item: ServiceRequest) => (
        <ViewRevisionsButton item={item} handleViewRevisionsClick={handleViewRevisionsClick} />
      ),
    },
    {
      title: t('TableHeadings.company'),
      dataIndex: 'companyName',
      key: 'companyName',
      width: '18rem',
      sorter: (a: ServiceRequest, b: ServiceRequest) =>
        (a?.companyName ?? '').localeCompare(b?.companyName ?? ''),
      render: (companyName: string) => companyName ?? '--',
    },
    {
      title: t('TableHeadings.systemSize'),
      width: '18rem',
      dataIndex: 'systemSize',
      filters: SYSTEM_SIZE_FILTERS,
      render: (_: any, item: ServiceRequest) => {
        const systemSize =
          !item?.systemSize && item?.form?.systemSize
            ? mapSystemSizeValueToType(item?.form?.systemSize ?? '')
            : item?.systemSize ?? '-';
        return SYSTEM_SIZE_FILTERS.find((project) => project.value === systemSize)?.text ?? '--';
      },
    },
    {
      title: t('TableHeadings.type'),
      width: '18rem',
      dataIndex: ['project', 'type'],
      filters: PROJECT_TYPE_FILTERS,
      render: (_: any, item: ServiceRequest) => {
        return (
          PROJECT_TYPE_FILTERS.find((project) => project.value === item.project.type)?.text ?? '--'
        );
      },
    },
    {
      title: t('TableHeadings.orderId'),
      dataIndex: 'id',
      width: '10rem',
      render: (id: string, item: ServiceRequest) => {
        return (
          <div className="DesignServices-OrderButtonWrapper">
            {id}
            <CopyOutlined
              role="button"
              aria-label={t('TableLabels.copyOrderId')}
              className="DesignServices-OrderButtonWrapper-CopyIcon"
              onClick={() => handleCopyClick(id, t('TableHeadings.orderId'), item.project.name)}
            />
          </div>
        );
      },
    },
    {
      title: t('TableHeadings.requestedAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '22.5rem',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a: ServiceRequest, b: ServiceRequest) => a.createdAt.localeCompare(b.createdAt),
      render: (createdAt: string) => {
        return new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
      },
    },
    {
      title: () => {
        return (
          <TooltipWrapper>
            <Tooltip
              placement="topLeft"
              title={t('tooltipTexts.turnaroundTime')}
              arrowPointAtCenter
            >
              <QuestionMarkIcon />
            </Tooltip>
            {t('TableHeadings.turnaroundTime')}
          </TooltipWrapper>
        );
      },
      dataIndex: 'completedDate',
      key: 'completedDate',
      width: '22.5rem',
      render: (_, item) => {
        return item?.completedDate
          ? getFormattedTimeDifference(new Date(item.completedDate), new Date(item.createdAt))
          : '--';
      },
    },
    {
      title: () => {
        return (
          <TooltipWrapper>
            <Tooltip placement="topLeft" title={t('tooltipTexts.designTime')} arrowPointAtCenter>
              <QuestionMarkIcon />
            </Tooltip>
            {t('TableHeadings.designTime')}
          </TooltipWrapper>
        );
      },
      dataIndex: 'designStartDate',
      key: 'designStartDate',
      width: '22.5rem',
      render: (_, item) => {
        return item?.designStartDate && item?.completedDate
          ? getFormattedTimeDifference(new Date(item.designStartDate), new Date(item.completedDate))
          : '--';
      },
    },
  ];

  const deleteButton = {
    title: t('TableHeadings.scaniflyAdminActions'),
    dataIndex: 'scaniflyAdminActions',
    key: 'scaniflyAdminActions',
    width: '20rem',
    render: (_: any, serviceRequest: ServiceRequest) => {
      return (
        <ButtonContainer>
          <Tooltip
            placement="top"
            title={t('DesignService.deleteServiceRequestTooltip')}
            arrowPointAtCenter
          >
            <Button
              onClick={() => handleToggleDeleteModal(serviceRequest.id)}
              className="Button--Red Button--Icon"
              aria-label={t('TableLabels.deleteServiceRequest')}
              id={`delete-modal-${serviceRequest?.id}`}
            >
              <TrashIcon />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={t('DesignService.changeDSPTooltip')} arrowPointAtCenter>
            <Button
              onClick={() => handleToggleEditDSPModal(serviceRequest.id)}
              className="Button--Green Button--Icon"
              aria-label={t('TableLabels.editDSP')}
              id={`edit-dsp-modal-${serviceRequest?.id}`}
            >
              <EditIcon />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={t('DesignService.chargeExtraTooltip')} arrowPointAtCenter>
            <Button
              onClick={() => handleToggleChargeExtraCreditsModal(serviceRequest.id)}
              className="Button--Orange Button--Icon"
              aria-label={t('TableLabels.chargeExtraCredits')}
              id={`charge-extra-credits-modal-${serviceRequest?.id}`}
            >
              <DollarSignIcon />
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={t('DesignService.deleteNearmapTooltip')}
            arrowPointAtCenter
          >
            <Button
              onClick={() => handleToggleDeleteNearmapModal(serviceRequest.id)}
              className="Button--Teal Button--Icon"
              aria-label={t('TableLabels.deleteNearmap')}
              id={`delete-nearmap-modal-${serviceRequest?.id}`}
            >
              <MapIcon />
            </Button>
          </Tooltip>
        </ButtonContainer>
      );
    },
  };

  const designServiceProvider = {
    title: t('TableHeadings.designServiceProvider'),
    width: '22rem',
    dataIndex: 'designServiceProviderName',
    render: (designServiceProviderName: string) => designServiceProviderName ?? '--',
  };

  if (isScaniflyAdmin) {
    columns.splice(1, 0, designServiceProvider);
    return [...columns, deleteButton];
  }

  return columns;
};

export default TableData;
