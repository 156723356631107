import {
  createApiKey as createApiKeyApi,
  deleteApiKey as deleteApiKeyApi,
  fetchCurrentUsersApiKeys,
} from 'api/apikeys';
import { Dispatch } from 'redux';
import { RootState } from 'state/store';
import { actions } from './apiKeySlice';
import { CREATE_API_KEY, DELETE_API_KEY, GET_USER_API_KEYS, SET_REQUEST_STATE } from './constants';
import { selectApiKeyRequestStatus } from './selectors';
import { RequestStatus } from './types';

export const getCurrentUserApiKeys =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    const getRequestStatus = selectApiKeyRequestStatus(getState(), GET_USER_API_KEYS);
    if (getRequestStatus.status === RequestStatus.inProgress) return;

    try {
      dispatch(
        actions[SET_REQUEST_STATE]({ request: GET_USER_API_KEYS, status: RequestStatus.inProgress })
      );
      const response = await fetchCurrentUsersApiKeys();
      if (response.data) {
        dispatch(actions[GET_USER_API_KEYS](response.data));
      }
      dispatch(
        actions[SET_REQUEST_STATE]({ request: GET_USER_API_KEYS, status: RequestStatus.complete })
      );
      return response.data;
    } catch (e: any) {
      dispatch(
        actions[SET_REQUEST_STATE]({
          request: GET_USER_API_KEYS,
          status: RequestStatus.failed,
          error: e.message,
        })
      );
      throw e;
    }
  };

export const createApiKey =
  (name: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    const createRequestStatus = selectApiKeyRequestStatus(getState(), CREATE_API_KEY);
    if (createRequestStatus.status === RequestStatus.inProgress) return;

    try {
      dispatch(
        actions[SET_REQUEST_STATE]({ request: CREATE_API_KEY, status: RequestStatus.inProgress })
      );
      const response = await createApiKeyApi(name);
      if (response.data) {
        dispatch(actions[CREATE_API_KEY](response.data));
      }
      dispatch(
        actions[SET_REQUEST_STATE]({ request: CREATE_API_KEY, status: RequestStatus.complete })
      );
      return response.data;
    } catch (e: any) {
      dispatch(
        actions[SET_REQUEST_STATE]({
          request: CREATE_API_KEY,
          status: RequestStatus.failed,
          error: e.message,
        })
      );
      throw e;
    }
  };

export const deleteApiKey =
  (keyId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    const deleteRequestStatus = selectApiKeyRequestStatus(getState(), DELETE_API_KEY);
    if (deleteRequestStatus.status === RequestStatus.inProgress) return;

    try {
      dispatch(
        actions[SET_REQUEST_STATE]({ request: DELETE_API_KEY, status: RequestStatus.inProgress })
      );
      const response = await deleteApiKeyApi(keyId);
      dispatch(actions[DELETE_API_KEY](keyId));
      dispatch(
        actions[SET_REQUEST_STATE]({ request: DELETE_API_KEY, status: RequestStatus.complete })
      );
      return response.data;
    } catch (e: any) {
      dispatch(
        actions[SET_REQUEST_STATE]({
          request: DELETE_API_KEY,
          status: RequestStatus.failed,
          error: e.message,
        })
      );
      throw e;
    }
  };
