import { useDispatch, useSelector } from 'react-redux';

import cn from 'classnames';

import { invoiceStatusRefreshRequested } from 'state/slices/admin/adminInvoicesSlice';
import { AppDispatch, RootState } from 'state/store';

import { ReactComponent as RefreshIcon } from 'assets/icons/refresh-icon.svg';

export const RefreshButton = ({ month, year }: { month: number; year: number }) => {
  const dispatch: AppDispatch = useDispatch();

  const handleRefresh = (month: number, year: number) => {
    dispatch(invoiceStatusRefreshRequested({ month, year, withNotification: true }));
  };

  const { isInvoiceRefreshLoading } = useSelector((state: RootState) => state.adminInvoices);

  return (
    <RefreshIcon
      onClick={() => {
        if (isInvoiceRefreshLoading) return;
        handleRefresh(month, year);
      }}
      className={cn('Accounting-RefreshIcon', {
        'Accounting-RefreshIcon--Spinning': isInvoiceRefreshLoading,
      })}
    />
  );
};
