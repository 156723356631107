import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ChecklistType } from '@cpm/scanifly-shared-data';
import { Spin } from 'antd';

import {
  getAllChecklistsForProject,
  removeMissingMedia,
  RemoveMissingMediaPayload,
  selectChecklistsForProject,
} from 'state/slices/checklists';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { getProjectMediaCategories } from 'state/slices/projectMediaCategories';

import { Container, ProjectName, TileWrapper, Wrapper } from './components';

import { ErrorDisplay } from 'components';
import usePermissions from 'helpers/hooks/usePermissions';
import { openNotification } from 'helpers/utils/openNotification';
import { useTranslation } from 'react-i18next';
import { ChecklistInfo } from './ChecklistInfo';
import ChecklistTile from './ChecklistTile/ChecklistTile';
import ChecklistRootView from './ChecklistView';

const Checklists = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const { isQa, isScaniflyAdmin } = usePermissions();

  const { project, isProjectRequestedLoading } = useSelector((state: RootState) => state.project);
  const checklists = useSelector((state: RootState) =>
    selectChecklistsForProject(state, projectId)
  );

  const [selectedChecklist, setSelectedChecklist] = useState<ChecklistType>();
  const [isRemovingMedia, setIsRemovingMedia] = useState<boolean>(false);

  const getProjectMediaCategoriesAction = useCallback(
    async (projectId: string): Promise<void> => {
      await dispatch<any>(getProjectMediaCategories(projectId));
    },
    [dispatch]
  );

  const removeMissingMediaAction = useCallback(
    async (payload: RemoveMissingMediaPayload) => {
      setIsRemovingMedia(true);
      try {
        await dispatch(removeMissingMedia(payload)).unwrap();
      } catch (err: any) {
        openNotification({
          type: 'error',
          title: t('alertMessages.errorTitle'),
          text: (
            <div>
              {t('Checklists.saveFail')}
              <ErrorDisplay error={err} />
            </div>
          ),
        });
      } finally {
        setIsRemovingMedia(false);
      }
    },
    [dispatch, t]
  );

  useEffect(() => {
    if (!project) {
      dispatch(projectRequested(projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project?.id) {
      dispatch(getAllChecklistsForProject(project.id));
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (projectId) {
      getProjectMediaCategoriesAction(projectId!);
    }
  }, [projectId, getProjectMediaCategoriesAction]);

  const hasChecklists = checklists.length;

  return (
    <Container>
      <Wrapper wide={!hasChecklists && !isProjectRequestedLoading}>
        <ProjectName>{project?.name}</ProjectName>
        <TileWrapper>
          {hasChecklists ? (
            checklists.map((checklist) => (
              <ChecklistTile
                key={checklist.id}
                checklistId={checklist.id}
                projectName={project?.name ?? ''}
                title={checklist.title}
                updatedAt={checklist.updatedAt}
                checklistStatus={checklist?.status}
                clickable={checklist?.generatedPdf !== '' || checklist?.generatedPdf !== undefined}
                isRemovingMedia={isRemovingMedia}
                isRemoveMediaVisible={isQa || isScaniflyAdmin}
                onRemoveMediaClick={() =>
                  removeMissingMediaAction({
                    projectId: checklist.project.id,
                    checklistId: checklist.id,
                  })
                }
                onTileClick={() => {
                  setSelectedChecklist(checklist);
                }}
                selected={selectedChecklist?.id === checklist?.id}
              />
            ))
          ) : isProjectRequestedLoading ? (
            <div className="Checklists-Spinner">
              <Spin size="small" />
            </div>
          ) : (
            <ChecklistInfo />
          )}
        </TileWrapper>
      </Wrapper>
      {selectedChecklist ? (
        <ChecklistRootView
          projectId={selectedChecklist.project.id}
          checklistId={selectedChecklist.id}
        />
      ) : null}
    </Container>
  );
};

export default Checklists;
