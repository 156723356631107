import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal, Spin } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { STATISTIC_TITLES } from 'screens/ScaniflyAdmin/CreditHistory/constants/CREDIT_HISTORY_CSV_COLUMN_TITLES';
import { DesignServiceStatistics } from 'types/DesignServiceStatistics';

import styled from 'styled-components';

import { statisticsRequested } from 'state/slices/designServices/designServiceStatisticsSlice';
import { AppDispatch } from 'state/store';

import { Button, CSVExportButton } from 'components';

import colors from 'helpers/constants/colors';
import { MODAL_PROPS } from 'helpers/constants/modals';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { useAppSelector } from 'helpers/hooks/useAppSelector';
import usePermissions from 'helpers/hooks/usePermissions';
import { i18n } from 'helpers/utils/translations';

import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';

import '../ConfirmDeleteModal/ConfirmDeleteModal.scss';

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  isModalOpen: boolean;
  toggleModal: () => void;
};

const nameMapper = (name?: keyof DesignServiceStatistics) => {
  switch (name) {
    case 'totalServiceRequestCountByYear':
      return i18n.t('TableHeadings.total');
    case 'totalWireframeCountByYear':
      return i18n.t('TableHeadings.wireframe');
    case 'totalSitePlanCountByYear':
      return i18n.t('TableHeadings.sitePlan');
    case 'totalPlanSetCountByYear':
      return i18n.t('TableHeadings.planSet');
    case 'totalSiteModelingCountByYear':
      return i18n.t('TableHeadings.siteModeling');
    case 'totalMaxFillCountByYear':
      return i18n.t('TableHeadings.maxFill');
    case 'totalWireframeForLargeCountByYear':
      return i18n.t('TableHeadings.wireframeForLarge');
    case 'totalPlanSetForLargeCountByYear':
      return i18n.t('TableHeadings.planSetForLarge');
    default:
      return '';
  }
};

const barColors = [
  colors.teal,
  colors.green,
  colors.yellow,
  colors.lightBlue,
  colors.purple,
  colors.orange,
  colors.red,
];

const StatisticsModal = ({ isModalOpen, toggleModal }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { statisticsData, isLoading } = useAppSelector((state) => state.designServiceStatistics);
  const { isScaniflyAdmin } = usePermissions();
  const { t } = useTranslation();

  const years = useMemo(
    () => statisticsData?.totalServiceRequestCountByYear.map(({ year }) => year).sort() ?? [],
    [statisticsData]
  );

  const modifiedDataRef = useRef<{ name: string; [year: number]: number }[]>([]);

  useEffect(() => {
    if (!statisticsData && isScaniflyAdmin) {
      dispatch(statisticsRequested());
    }

    if (statisticsData) {
      modifiedDataRef.current = Object.entries(statisticsData).map((data) => {
        return {
          name: nameMapper(data[0] as keyof DesignServiceStatistics),
          ...Object.fromEntries(
            years.map((year) => [year, data[1].find((d) => d.year === year)?.count ?? 0])
          ),
        };
      });
    }
  }, [dispatch, isScaniflyAdmin, statisticsData, years]);

  return (
    // @ts-ignore this lib is incompatible with react18
    <Modal
      visible={isModalOpen}
      onCancel={toggleModal}
      className="ConfirmDeleteModal"
      {...MODAL_PROPS}
    >
      <XIcon onClick={toggleModal} className="close-modal-icon" data-testid="close-modal-icon" />
      <h2>{t('DesignService.statistics')}</h2>
      {!statisticsData && isLoading ? (
        <CenteredDiv>
          <Spin size="large" />
        </CenteredDiv>
      ) : (
        <BarChart
          width={500}
          height={400}
          data={modifiedDataRef.current}
          margin={{
            top: 20,
            right: 20,
            left: 10,
            bottom: 0,
          }}
          layout="vertical"
        >
          <CartesianGrid horizontal={false} stroke="#a0a0a0" strokeWidth={0.5} />
          <XAxis type="number" axisLine={false} stroke="#a0a0a0" strokeWidth={0.5} />
          <YAxis dataKey="name" type="category" orientation="left" fontSize={11} />
          <Tooltip />
          <Legend />
          {years.map((year, index) => (
            <Bar key={year} dataKey={year} stackId="a" fill={barColors[index % barColors.length]} />
          ))}
        </BarChart>
      )}
      <div className="ConfirmDeleteModal-Buttons-Wrapper">
        <CSVExportButton
          tableName={TABLE_NAMES.DESIGN_SERVICES_STATISTICS}
          prepareCSVData={() => modifiedDataRef.current}
          columnTitles={[
            ...STATISTIC_TITLES,
            ...years.map((year) => ({ title: year.toString(), dataIndex: year.toString() })),
          ]}
          dataSource={modifiedDataRef.current}
        />
        <Button onClick={toggleModal}>{t('DesignService.close')}</Button>
      </div>
    </Modal>
  );
};

export default StatisticsModal;
