import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Notification } from 'types';

import { notificationUpdate } from 'state/slices/notificationSlice';
import { AppDispatch, RootState } from 'state/store';

import { Button } from 'components';
import {
  BottomButton,
  Day,
  MentionTabTitle,
  NoNotificationMessage,
  NotificationHeader,
  NotificationList,
  Row,
  SpacingDiv,
  StyledSwitch,
  StyledTabWrapper,
  ToggleTitle,
  Wrapper,
} from './components';

import { i18n } from 'helpers/utils/translations';

import Mentions from './Mentions/Mentions';

type Props = {
  notifications: Notification[];
};

const NotificationMenu = ({ notifications }: Props) => {
  const [unread, setUnread] = useState(false);
  const [newNotifications, setNewNotifications] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const userId = currentUser?.id ?? '';

  const hideNotification = () => {
    notifications?.map((notification) => {
      const { hasRead, id: notificationId, visible } = notification;
      const hiddenToUser = [...visible, userId];
      const isHidden = visible.includes(userId) ? true : false;

      if (!isHidden) {
        dispatch(notificationUpdate({ userId, notificationId, hasRead, visible: hiddenToUser }));
      }
      return true;
    });
  };

  const readNotification = () => {
    notifications?.map((notification) => {
      const { hasRead, id: notificationId, visible } = notification;
      const read = hasRead.includes(userId) ? true : false;
      const userHasRead = [...hasRead, userId];

      if (!read) {
        dispatch(notificationUpdate({ userId, notificationId, hasRead: userHasRead, visible }));
      }
      return true;
    });
  };

  const checkNotifications = () => {
    notifications?.map((notification) => {
      if (newNotifications) return null;
      const { visible } = notification;
      const isHidden = visible.includes(userId) ? true : false;

      if (!isHidden) {
        setNewNotifications(true);
      }
      return true;
    });
  };

  const renderNoNewNotificationMessage = () => {
    checkNotifications();
    if (!newNotifications)
      return (
        <>
          <SpacingDiv />
          <NoNotificationMessage>{i18n.t('Notifications.noNotifications')}</NoNotificationMessage>
          <SpacingDiv />
        </>
      );
  };

  return (
    <Wrapper>
      <NotificationList>
        <li>
          <NotificationHeader>
            <h3>{i18n.t('Notifications.notifications')}</h3>
            <ToggleTitle>{i18n.t('Notifications.onlyShowUnread')}</ToggleTitle>
            <StyledSwitch data-test-id={'toggle-unread'} onChange={setUnread} checked={unread} />
          </NotificationHeader>

          <MentionTabTitle>{i18n.t('Notifications.mentions')}</MentionTabTitle>
          <StyledTabWrapper />
          <Row>
            <Day>{i18n.t('Notifications.mostRecent')}</Day>
            <Button data-test-id={'read-notification-button'} onClick={readNotification}>
              {i18n.t('Notifications.markAllAsRead')}
            </Button>
          </Row>
          <Mentions unread={unread} notifications={notifications} />
          {renderNoNewNotificationMessage()}
        </li>
      </NotificationList>
      <BottomButton data-test-id={'bottom-button'} onClick={hideNotification}>
        {i18n.t('Notifications.clearAllNotifications')}
      </BottomButton>
    </Wrapper>
  );
};

export default NotificationMenu;
