import { UserId } from '@cpm/scanifly-shared-data';
import {
  createArraySettingsSection,
  createDesignPresetSettingsSection,
  createInverterSettingsSection,
  createSetbackDefaultsSettingsSection,
  createShadeSettingsSection,
  createSimulationSettingsSection,
  SettingsShell,
  UnitConverters,
} from '@cpm/settings-ui';

import { settingsApi as api } from 'state/slices/settingsApiSlice';

import { GoBackButton } from 'components';

import { ACCESS } from 'helpers/constants';
import { useAppSelector } from 'helpers/hooks/useAppSelector';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'state/store';
import { ButtonContainer, Container, RelativeDiv, Shell, Wrapper } from './components';

type Settings = React.ComponentProps<typeof SettingsShell>['settings'];

const roundValueForDisplay = ({ precision, value }: { value: number; precision: number }) => {
  if (!Number.isFinite(value)) return 0;
  return Number(value.toFixed(precision));
};

const M_TO_INCH = 39.3701;
const M_TO_FOOT = 3.28084;
const meterToInch = (m: number) => (Number.isFinite(m) ? m * M_TO_INCH : 0);
const inchToMeter = (inch: number) => (Number.isFinite(inch) ? inch / M_TO_INCH : 0);
const meterToFoot = (m: number) => (Number.isFinite(m) ? m * M_TO_FOOT : 0);
const footToMeter = (foot: number) => (Number.isFinite(foot) ? foot / M_TO_FOOT : 0);

const unitConverters: UnitConverters = {
  toDisplay: ({ value, precision }) =>
    roundValueForDisplay({ value: meterToFoot(value), precision }),
  toSmallValueDisplay: ({ value, precision }) =>
    roundValueForDisplay({ value: meterToInch(value), precision }),
  fromDisplay: ({ value }) => footToMeter(value),
  fromSmallValueDisplay: ({ value }) => inchToMeter(value),
  displayUnit: ({ smallValue }) => (smallValue ? 'inch' : 'ft'),
  unitSystem: 'Imperial',
};

const Scanifly3dSettings = () => {
  const { isAdmin: userIsAdmin, isSalesManager, isQa: inverterLibraryEnabled } = usePermissions();
  const monthlyLossesEnabled = useFeatureToggle(ACCESS.SIMULATION_SETTINGS_MONTHLY_LOSSES);
  const asaTsrfThresholdEnabled = useFeatureToggle(ACCESS.MODULE_FILL_ASA_TSRF_THRESHOLD);
  const arraySettingsEnabled = useFeatureToggle(ACCESS.ARRAY_SETTINGS);
  const { currentUser } = useAppSelector((state: RootState) => state.users);

  const { t } = useTranslation();
  const settings: Settings = [
    { title: t('PageTitles.scanifly3dSettings'), type: 'header' },
    createShadeSettingsSection({ api }),
    ...(userIsAdmin || isSalesManager
      ? [
          { title: t('PageTitles.companySettings'), type: 'header' } as const,
          createSimulationSettingsSection({
            api,
            featureFlags: {
              selectMonthlyShadeLossesEnabled: () => monthlyLossesEnabled,
            },
          }),
          ...(arraySettingsEnabled && userIsAdmin && currentUser
            ? [
                createArraySettingsSection({
                  api,
                  selectUserId: () => currentUser.id as UserId,
                  unitConverters,
                }),
              ]
            : []),
          ...(asaTsrfThresholdEnabled && userIsAdmin
            ? [createDesignPresetSettingsSection({ api })]
            : []),
          ...(userIsAdmin ? [createSetbackDefaultsSettingsSection({ api })] : []),
          ...(inverterLibraryEnabled && (userIsAdmin || isSalesManager)
            ? [
                { title: t('PageTitles.scanifly3dComponents'), type: 'header' } as const,
                createInverterSettingsSection({ api }),
              ]
            : []),
        ]
      : []),
  ];

  return (
    <Wrapper>
      {/* Little container div to respect padding of Wrapper */}
      <RelativeDiv>
        <ButtonContainer>
          <GoBackButton />
        </ButtonContainer>
      </RelativeDiv>
      <Container>
        <Shell settings={settings} config={{ zIndex: 1 }} />
      </Container>
    </Wrapper>
  );
};

export default Scanifly3dSettings;
