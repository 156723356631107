import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import { camelCase } from 'lodash-es';

import { RootState } from 'state/store';

import { ACCESS } from 'helpers/constants/access';

/**
 * This hook aggregates and normalizes flag sources.
 * Input formats:
 *  helpers/constants/access - notes_access
 *  Launch Darkly flag - notesAccess
 *  Env Var - REACT_APP_LD_NOTES_ACCESS
 *  local state flag - notesAccess
 *
 * Output format: notesAccess
 *
 * Flag order of priority
 * Local State > Env Variables > Launch Darkly > App Constants
 *
 * @return {[key: string]: bool}
 */
const useFlagsWithOverride = () => {
  const localStateOverrides = useSelector((state: RootState) => state.featureToggleOverrides);
  const flags = useFlags();

  const flagsAggregate: { [key: string]: boolean } = { ...flags };
  Object.values(ACCESS).forEach((enumValue) => {
    if (flagsAggregate[enumValue] === undefined) {
      flagsAggregate[enumValue] = false;
    }
  });

  const ldEnvOverrides = Object.keys(process.env).filter(
    (envVar) => envVar.startsWith('REACT_APP_LD') && !envVar.includes('CLIENT_SIDE_ID')
  );

  for (const override of ldEnvOverrides) {
    const ldFlagName = camelCase(override.substring(13));
    flagsAggregate[ldFlagName] = process.env[override] ?? -1 > 0 ? true : false;
  }

  return {
    ...flagsAggregate,
    ...localStateOverrides,
  };
};

export default useFlagsWithOverride;
