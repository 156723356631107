import { Link } from 'react-router-dom';

import { ColumnType, SortOrder } from 'antd/lib/table/interface';
import { getAdminProjectRoute } from 'screens/Projects/ProjectsList/ProjectTile/helpers';
import { Project } from 'types';
import { SubmittedFor } from 'types/Project';

import { ActionDropdown, Scanifly3DButton } from 'components';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import { scaniflyAdminUsersCustomerRoute } from 'helpers/constants/routes';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { completedDateSorter } from 'helpers/utils/completedDateSorter';
import { filterProjectsByType } from 'helpers/utils/filterProjectsByType';
import { renderProjectType } from 'helpers/utils/renderProjectType';

import { ENGINE_FILTERS } from '../constants';
import { ADMIN_STATUS_FILTERS, DATE_OPTIONS } from './constants';

export const TableColumnData = (
  openChangeUserModalForProject: (projectId: string) => void,
  openChangeStatusModalForProject: (projectId: string) => void
): ColumnType<Project>[] => {
  return [
    {
      title: 'Project',
      dataIndex: 'name',
      key: 'name',
      width: '20rem',
      sorter: (a: Project, b: Project) => a.name.localeCompare(b.name),
      render: (name: string, item: Project) => (
        <Link to={getAdminProjectRoute(item.id, item.status)}>{name}</Link>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '13rem',
      filters: PROJECT_TYPE_FILTERS,
      onFilter: (value: string | number | boolean, record: Project) =>
        filterProjectsByType(value, record),
      render: (type: string) => renderProjectType(type),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '10rem',
      filters: ADMIN_STATUS_FILTERS,
      onFilter: (value: string | number | boolean, record: Project) =>
        value.toString().includes(record.status ?? ''),
      render: (type: string) =>
        ADMIN_STATUS_FILTERS.find((el) => el.value.includes(type))?.text ??
        `Invalid Status: ${type}`,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_: any, item: Project) => (
        <ActionDropdown
          project={item}
          openChangeUserModalForProject={openChangeUserModalForProject}
          openChangeStatusModalForProject={openChangeStatusModalForProject}
          tableName={TABLE_NAMES.UPLOAD_QUEUE}
        />
      ),
    },
    {
      title: 'Scanifly3D',
      dataIndex: 'scanifly3d',
      render: (_: any, item: Project) => (
        <Scanifly3DButton projectId={item.id} projectStatus={item.status} />
      ),
    },
    {
      title: 'Company',
      dataIndex: 'submittedFor',
      key: 'company.name',
      width: '18rem',
      sorter: (a: Project, b: Project) =>
        (a.submittedFor?.company?.name ?? '').localeCompare(b.submittedFor?.company?.name ?? ''),
      render: (submittedFor: SubmittedFor) =>
        submittedFor ? (
          <a href={scaniflyAdminUsersCustomerRoute(submittedFor?.company.id)}>
            {submittedFor?.company.name}
          </a>
        ) : (
          `-`
        ),
    },
    {
      title: 'Engine',
      dataIndex: 'engine',
      key: 'engine',
      width: '10rem',
      filters: ENGINE_FILTERS,
      onFilter: (value: string | number | boolean, record: Project) =>
        record.engine?.toLowerCase() === value.toString().toLowerCase(),
      render: (engine: string) => {
        return engine ? ENGINE_FILTERS.find((el) => el.value === engine)?.text : ' - ';
      },
    },
    {
      title: 'Project Owner',
      dataIndex: 'submittedFor',
      key: 'createdByUser.firstName',
      width: '18rem',
      sorter: (a: Project, b: Project) =>
        (a.submittedFor?.firstName ?? '').localeCompare(b.submittedFor?.firstName ?? ''),
      render: (submittedFor: SubmittedFor) =>
        submittedFor ? (
          <a
            href={`mailto:${submittedFor?.email}`}
          >{`${submittedFor?.firstName} ${submittedFor?.lastName}`}</a>
        ) : (
          `-`
        ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '22.5rem',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a: Project, b: Project) => a?.createdAt.localeCompare(b?.createdAt),
      render: (createdAt: string) => {
        return new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
      },
    },
    {
      title: 'Completed At',
      dataIndex: 'completedDate',
      key: 'completedDate',
      width: '22.5rem',
      sorter: (a: Project, b: Project) => completedDateSorter(a, b),
      render: (completedDate: string) => {
        return completedDate
          ? new Date(completedDate).toLocaleDateString('en-US', DATE_OPTIONS)
          : 'Not Completed';
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      width: '35rem',
    },
  ];
};
