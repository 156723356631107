import { useSelector } from 'react-redux';

import { PERMISSION, ROLE } from '@cpm/scanifly-shared-data';
import { RootState } from 'state/store';

const usePermissions = () => {
  const { currentUser } = useSelector((state: RootState) => state.users);

  const isAdmin = Boolean(currentUser?.roles?.includes(ROLE.admin));

  const isDesignServiceProvider = Boolean(currentUser?.roles?.includes(ROLE.designServiceProvider));

  const isQa = Boolean(currentUser?.roles?.includes(ROLE.qa));

  const isScaniflyAdmin = Boolean(currentUser?.roles?.includes(ROLE.scaniflyAdmin));

  const isScaniflyBillingAdmin = Boolean(currentUser?.roles?.includes(ROLE.scaniflyBillingAdmin));

  const isSimpleDesign = Boolean(currentUser?.permissions?.includes(PERMISSION.SIMPLEDESIGN));

  const isSalesManager = Boolean(currentUser?.permissions?.includes(PERMISSION.SALESMANAGER));

  const isSimpleDesignOrSalesManager = isSalesManager || isSimpleDesign;

  const hasBillingPermissions = Boolean(currentUser?.permissions?.includes(PERMISSION.BILLING));

  const hasUploadPermissions = Boolean(currentUser?.permissions?.includes(PERMISSION.UPLOAD));

  const hasScanifly3DPermissions = Boolean(currentUser?.permissions?.includes(PERMISSION.WATT3D));

  const hasScanifly3DSettingsPermissions = isAdmin || isSalesManager;

  return {
    isAdmin,
    isDesignServiceProvider,
    isQa,
    isScaniflyAdmin,
    isScaniflyBillingAdmin,
    isSimpleDesign,
    isSalesManager,
    isSimpleDesignOrSalesManager,
    hasUploadPermissions,
    hasScanifly3DPermissions,
    hasBillingPermissions,
    hasScanifly3DSettingsPermissions,
  };
};

export default usePermissions;
