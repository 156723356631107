import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  FEATURE_LIST,
  getFeatureAvailability,
  PROJECT_STATUS,
  PROJECT_TYPES,
  TIER_LEVEL,
} from '@cpm/scanifly-shared-data';
import { Modal, Spin } from 'antd';
import { ServiceRequest } from 'types';

import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { designServicesLandingRoute, projectsListRoute } from 'helpers/constants/routes';
import { getMainSelectionSteps } from 'screens/DesignServices/helpers/getMainSelectionSteps';

import { getAutomationServiceType } from 'screens/DesignServices/helpers/getAutomationServiceType';
import { companyActiveSubscriptionRequested } from 'state/slices/admin/adminSubscriptionsSlice';
import { CancelAutomationForProcessing } from '../CancelAutomationForProcessing/CancelAutomationForProcessing';
import DesignSteps from '../DesignSteps/DesignSteps';
import './DesignSelection.scss';
import OrderResponseModal from './OrderResponseModal/OrderResponseModal';

const DesignSelection = ({ upgrade = false }: { upgrade: boolean }) => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId, step } = useParams<{ projectId: string; step: string }>();
  const { project } = useSelector((state: RootState) => state.project);
  const { company } = useSelector((state: RootState) => state.company);
  const { activeSubscription } = useSelector((state: RootState) => state.adminSubscriptions);
  const [selectedStep, setSelectedStep] = useState<number>(Number(step));
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [responseData, setResponseData] = useState({});
  const history = useHistory();

  const designServicesAccess = getFeatureAvailability(
    false,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );

  useEffect(() => {
    if (!activeSubscription && company?.id) {
      dispatch(companyActiveSubscriptionRequested(company?.id));
    }
  }, [company?.id, dispatch, activeSubscription]);

  useEffect(() => {
    if (!company) {
      return history.push(designServicesLandingRoute(projectId));
    }
    if (!designServicesAccess) {
      return history.push(projectsListRoute());
    }
    if (!project || project?.id !== projectId) {
      dispatch(projectRequested(projectId));
    }
  }, [designServicesAccess, dispatch, history, project, projectId, company]);

  const isAutomatedServicesEnabledForProject = useMemo(
    () =>
      ((activeSubscription?.automatedWireframeLargeOnsite &&
        project?.type === PROJECT_TYPES.LARGE) ||
        (activeSubscription?.automatedWireframeSmallOnsite &&
          project?.type === PROJECT_TYPES.SMALL) ||
        activeSubscription?.automatedTrueUp) &&
      !project?.cancelDSAutomationAfterProjectIsProcessed,
    [activeSubscription, project]
  );

  const isProjectProcessing = useMemo(
    () =>
      project?.status === PROJECT_STATUS.uploadProcessing ||
      project?.status === PROJECT_STATUS.adminUploadProcessing,
    [project]
  );

  const handleModalClose = () => {
    setModalVisible(false);
    setResponseData({});
  };

  const handleResponse = (
    data: Partial<ServiceRequest> & { stepNumber: number; projectId?: string }
  ) => {
    if (Object.keys(data).length) {
      const tierLevel = data?.tierLevel;
      const stepNumber = data?.stepNumber;
      const ORDER_COMPLETE = 3;

      if (
        tierLevel !== TIER_LEVEL.wireframe &&
        tierLevel !== TIER_LEVEL.wireframeForLarge &&
        stepNumber < ORDER_COMPLETE
      ) {
        handleStepChange(stepNumber);
      } else {
        handleStepChange(ORDER_COMPLETE);
        setModalVisible(true);
      }

      setResponseData(data);
    }
  };

  const handleStepChange = (step: number | string) => {
    setSelectedStep(Number(step));
  };

  const service = useMemo(
    () =>
      getAutomationServiceType({ subscription: activeSubscription, projectType: project?.type }),
    [activeSubscription, project?.type]
  );

  if (project && designServicesAccess) {
    return (
      <div data-testid="design-selection">
        {/* @ts-ignore screw you ant-d */}
        <Modal visible={modalVisible} onCancel={handleModalClose} footer={null}>
          <OrderResponseModal
            handleModalClose={handleModalClose}
            responseData={responseData}
            upgrade={upgrade}
          />
        </Modal>
        <div className="DesignSelection-ProjectTitle">{project?.name ?? ''}</div>
        <div className="DesignSelection-MainWrapper">
          {isAutomatedServicesEnabledForProject && isProjectProcessing && service ? (
            <CancelAutomationForProcessing service={service} projectId={projectId} />
          ) : (
            <DesignSteps
              current={selectedStep}
              options={getMainSelectionSteps({ handleResponse, upgrade })}
              handleStepChange={handleStepChange}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <Spin size="large"></Spin>;
  }
};

export default DesignSelection;
