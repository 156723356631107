import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BoundingBox, Folder, Project } from 'types';

import {
  fetchAllProjectPins,
  fetchProjects,
  fetchProjectsFiltersOptions,
  updateProject,
} from 'api/projects/projectsService';

const name = 'projects';

type StateType = {
  projects: Project[];
  allProjects: Project[];
  projectsLoadedSuccessfully: boolean;
  isFiltersLoading: boolean;
  isLoading: boolean;
  isProjectPinsLoading: boolean;
  statusUpdateError: any;
  statusUpdateSuccess: boolean | null;
  error: any;
  totalProjects: number;
  pageIndex: number;
  filterOptions: any;
};

export const projectsRequested = createAsyncThunk(
  `${name}/projectsRequested`,
  async (
    {
      page,
      size,
      folder,
      location,
      date,
      bounds,
      sort,
      order,
      searchText,
      more,
    }: {
      page: number;
      size: number;
      folder?: Folder;
      location?: string;
      date?: string;
      bounds: BoundingBox;
      sort: string;
      order: string;
      searchText?: string;
      more?: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchProjects(
        page,
        size,
        folder as Folder,
        location as string,
        date as string,
        bounds,
        sort,
        order,
        searchText as string,
        more
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const allProjectsRequested = createAsyncThunk(
  `${name}/allProjectsRequested`,
  async (
    {
      folder,
      location,
      date,
      searchText,
      more,
    }: { folder: Folder; location: string; date: string; searchText: string; more: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchAllProjectPins(folder, location, date, searchText, more);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const projectStatusUpdateRequested = createAsyncThunk(
  `${name}/projectStatusUpdateRequested`,
  async ({ id, targetStatus }: { id: string; targetStatus: string }, { rejectWithValue }) => {
    try {
      const response = await updateProject(id, { status: targetStatus });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const filtersRequested = createAsyncThunk(
  `${name}/filtersRequested`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchProjectsFiltersOptions();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const projectsSlice = createSlice({
  name,
  initialState: {
    projects: [],
    allProjects: [],
    projectsLoadedSuccessfully: false,
    isFiltersLoading: true,
    isLoading: true,
    isProjectPinsLoading: false,
    statusUpdateError: null,
    statusUpdateSuccess: null,
    error: null,
    totalProjects: 0,
    pageIndex: 1,
    filterOptions: {},
  } as StateType,
  reducers: {
    resetProjectsLoadedSuccessfully: (state) => {
      state.projectsLoadedSuccessfully = false;
    },
    resetStatusUpdateFlags: (state) => {
      state.statusUpdateError = null;
      state.statusUpdateSuccess = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(projectsRequested.pending, (state) => {
        state.isLoading = true;
        state.projectsLoadedSuccessfully = false;
      })
      .addCase(projectsRequested.fulfilled, (state, { payload }) => {
        state.projects = payload.items;
        state.pageIndex = payload.pageIndex;
        state.projectsLoadedSuccessfully = true;
        state.totalProjects = payload.totalCount;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(projectsRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.projectsLoadedSuccessfully = false;
        state.isLoading = false;
      })
      .addCase(allProjectsRequested.pending, (state) => {
        state.isProjectPinsLoading = true;
      })
      .addCase(allProjectsRequested.fulfilled, (state, { payload }) => {
        state.allProjects = payload;
        state.error = null;
        state.isProjectPinsLoading = false;
      })
      .addCase(allProjectsRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isProjectPinsLoading = false;
      })
      .addCase(projectStatusUpdateRequested.fulfilled, (state, { payload }) => {
        state.projects = [
          ...state.projects.filter((project) => project.id !== payload.id),
          payload,
        ];
      })
      .addCase(projectStatusUpdateRequested.rejected, (state, { error }: { error: any }) => {
        state.statusUpdateError = error.message;
        state.statusUpdateSuccess = null;
      })
      .addCase(filtersRequested.pending, (state) => {
        state.isFiltersLoading = true;
      })
      .addCase(filtersRequested.fulfilled, (state, { payload }) => {
        state.filterOptions = payload;
        state.error = null;
        state.isFiltersLoading = false;
      })
      .addCase(filtersRequested.rejected, (state, { error }: { error: any }) => {
        state.error = error.message;
        state.isFiltersLoading = false;
      });
  },
});

export const { resetProjectsLoadedSuccessfully, resetStatusUpdateFlags } = projectsSlice.actions;

export default projectsSlice.reducer;
