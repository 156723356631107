import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { FieldTypeEnum, SectionType } from '@cpm/scanifly-shared-data';
import colors from 'helpers/constants/colors';
import { getChecklistById, selectChecklistById } from 'state/slices/checklists';
import { mediaThunks } from 'state/slices/media';
import { AppDispatch, RootState } from 'state/store';

import ChecklistProvider from './ChecklistProvider';
import { Header, SectionView } from './layout';

type RootViewProps = {
  projectId: string;
  checklistId: string;
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 10px;
  justify-content: left;
  padding: 1rem 1rem 0;
`;

export const Seperator = styled.div`
  width: 100%;
  height: 0.5rem;
  border-bottom: 1px solid ${colors.neutralGray};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ChecklistRootView = ({ projectId, checklistId }: RootViewProps) => {
  const dispatch: AppDispatch = useDispatch();
  const checklist = useSelector((state: RootState) => selectChecklistById(state, checklistId));
  const [checklistLoading, setChecklistLoading] = useState(false);
  const [refreshTimestamp, setRefreshTimestamp] = useState(new Date());
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const mediaCategoryId: string | undefined = useMemo(() => {
    if (!checklist.submittedResponse) return;
    for (const section of checklist.submittedResponse) {
      for (const field of section.components) {
        if (
          field.componentType === FieldTypeEnum.media &&
          field.value &&
          field.value.mediaCategoryId
        ) {
          return field.value.mediaCategoryId;
        }
      }
    }
  }, [checklist.submittedResponse]);

  const getMedia = useCallback(
    (projectId: string, categoryId: string) => {
      dispatch(mediaThunks.getCategoryMedia({ projectId, categoryId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (projectId && mediaCategoryId) {
      getMedia(projectId, mediaCategoryId);
    }
  }, [getMedia, projectId, mediaCategoryId]);

  const sectionMap: { [key: string]: SectionType } = useMemo(() => {
    if (!checklist.submittedResponse) return {};
    const tempMap: { [key: string]: SectionType } = {};
    for (const section of checklist.submittedResponse) {
      tempMap[section.id] = section;
    }
    return tempMap;
  }, [checklist.submittedResponse]);

  const getChecklist = useCallback(
    async (projectIdToUse: string, checklistIdToUse: string) => {
      setChecklistLoading(true);
      await dispatch<any>(getChecklistById(projectIdToUse, checklistIdToUse));
      setChecklistLoading(false);
      setRefreshTimestamp(new Date());
    },
    [dispatch]
  );

  const onChecklistRefresh = useCallback(async () => {
    await getChecklist(projectId, checklistId);
    if (projectId && mediaCategoryId) {
      await getMedia(projectId, mediaCategoryId);
    }
  }, [mediaCategoryId, projectId, checklistId, getChecklist, getMedia]);

  useEffect(() => {
    getChecklist(projectId, checklistId);
  }, [getChecklist, checklistId, projectId]);

  const sectionExistsInResponse = useMemo(() => {
    if (selectedSection === null || !Array.isArray(checklist?.submittedResponse)) return false;
    return checklist.submittedResponse.some((section) => section.id === selectedSection);
  }, [selectedSection, checklist.submittedResponse]);

  useEffect(() => {
    if (
      (selectedSection === null || !sectionExistsInResponse) &&
      Array.isArray(checklist.submittedResponse) &&
      checklist.submittedResponse.length > 0
    ) {
      setSelectedSection(checklist.submittedResponse[0].id);
    }
  }, [sectionExistsInResponse, selectedSection, checklist.submittedResponse]);

  return (
    <Container>
      <Header
        checklist={checklist}
        selectedSection={
          selectedSection && sectionMap[selectedSection] ? sectionMap[selectedSection] : undefined
        }
        setSelectedSection={(sectionId: string) => setSelectedSection(sectionId)}
        onChecklistRefresh={onChecklistRefresh}
        checklistLoading={checklistLoading}
        refreshTimestamp={refreshTimestamp}
      />
      <Seperator />
      <ChecklistProvider checklist={checklist}>
        <SectionView
          section={
            selectedSection && sectionMap[selectedSection] ? sectionMap[selectedSection] : undefined
          }
        />
      </ChecklistProvider>
    </Container>
  );
};

export default ChecklistRootView;
