import { Link } from 'react-router-dom';

import { CopyOutlined } from '@ant-design/icons';
import {
  CREDIT_TRANSACTION_CATEGORY,
  CREDIT_TRANSACTION_CATEGORY_FILTERS,
  DESIGN_TIER_LEVEL_FILTERS,
} from '@cpm/scanifly-shared-data';
import { Tag } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { TFunction } from 'i18next';
import CreditsTransaction from 'types/CreditTransaction';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import { projectDesignsRoute } from 'helpers/constants/routes';
import { handleCopyClick } from 'helpers/utils/handleCopyClick';
import { getTagTitleAndColor } from '../../../ScaniflyAdmin/CreditHistory/helpers/getTagTitleAndColor';

import { TextValueMap } from '@cpm/scanifly-shared-data/lib/util/types';
import { DATE_OPTIONS } from '../../../ScaniflyAdmin/CustomerSupportUpload/constants';

const TableData = (t: TFunction<'translation', undefined>) => {
  type Column = {
    title: string;
    key?: string;
    width: string;
    dataIndex: string | string[];
    sorter?: (a: CreditsTransaction, b: CreditsTransaction) => number;
    render?: (name: string, item: CreditsTransaction) => any;
    filters?: TextValueMap<string>[];
    defaultSortOrder?: SortOrder;
  };

  const ESR_DESIGNER_ID = '663363aa38df56743a6a7037';
  const ESR_DESIGNER_DISPLAY_NAME = 'Scanifly Design Services - ESR Designer';

  const columns: Column[] = [
    {
      title: t('CreditHistory.transactionId'),
      dataIndex: 'id',
      width: '15rem',
      render: (id: string) => {
        return (
          <div className="DesignServices-OrderButtonWrapper">
            {id}
            <CopyOutlined
              role="button"
              aria-label={t('CreditHistory.copyTransactionId') ?? ''}
              className="DesignServices-OrderButtonWrapper-CopyIcon"
              onClick={() => handleCopyClick(id, t('CreditHistory.transactionId') ?? '')}
            />
          </div>
        );
      },
    },
    {
      title: t('CreditHistory.category'),
      width: '14rem',
      key: 'category',
      dataIndex: 'category',
      filters: CREDIT_TRANSACTION_CATEGORY_FILTERS,
      render: (_: any, item: CreditsTransaction) => {
        const tagProps = getTagTitleAndColor(item.category);
        return (
          <Tag
            color={tagProps.color}
            className="ScaniflyAdmin-CategoryTag"
            data-testid="transaction-category-tag"
          >
            {tagProps.title}
          </Tag>
        );
      },
    },
    {
      title: t('CreditHistory.credits'),
      width: '10rem',
      dataIndex: 'credit',
      key: 'credits',
      render: (_: any, item: CreditsTransaction) => {
        if (
          item.category === CREDIT_TRANSACTION_CATEGORY.order ||
          item.category === CREDIT_TRANSACTION_CATEGORY.expiration ||
          item.category === CREDIT_TRANSACTION_CATEGORY.nearmaps ||
          item.category === CREDIT_TRANSACTION_CATEGORY.manualAdjustment
        ) {
          return item?.credit * -1;
        }
        return item?.debit;
      },
    },
    {
      title: t('CreditHistory.project'),
      key: 'projectName',
      dataIndex: ['project', 'name'],
      width: '18rem',
      sorter: (a: CreditsTransaction, b: CreditsTransaction) =>
        (a?.project?.name ?? '').localeCompare(b?.project?.name ?? ''),
      render: (name: string, item: CreditsTransaction) =>
        name ? (
          <Link data-testid="project-link" to={projectDesignsRoute(item?.project?.id)}>
            {name}
          </Link>
        ) : (
          '--'
        ),
    },
    {
      title: t('CreditHistory.projectType'),
      width: '14rem',
      dataIndex: ['project', 'type'],
      filters: PROJECT_TYPE_FILTERS,
      render: (_: any, item: CreditsTransaction) => {
        return (
          PROJECT_TYPE_FILTERS.find((project) => project.value === item?.project?.type)?.text ??
          '--'
        );
      },
    },
    {
      title: t('TableHeadings.newBuildPlans'),
      width: '15rem',
      dataIndex: 'newBuildPlansRequired',
      render: (_: any, item: CreditsTransaction) => {
        return `${item?.serviceRequest?.newBuildPlansRequired ? t('Generic.yes') : t('Generic.no')}`;
      },
    },
    {
      title: t('CreditHistory.serviceRequestId'),
      dataIndex: ['serviceRequest', 'id'],
      width: '22rem',
      render: (id: string) => {
        return id ? (
          <div className="DesignServices-OrderButtonWrapper">
            {id}
            <CopyOutlined
              role="button"
              aria-label={t('CreditHistory.copyServiceId') ?? ''}
              className="DesignServices-OrderButtonWrapper-CopyIcon"
              onClick={() => handleCopyClick(id, t('CreditHistory.serviceRequestId') ?? '')}
            />
          </div>
        ) : (
          '--'
        );
      },
    },
    {
      title: t('TableHeadings.expedited'),
      width: '18rem',
      dataIndex: ['serviceRequest', 'isExpedited'],
      render: (_: any, item: CreditsTransaction) => {
        return `${item?.serviceRequest?.isExpedited ? t('Generic.yes') : t('Generic.no')}`;
      },
    },
    {
      title: t('CreditHistory.tierLevel'),
      width: '18rem',
      key: 'tierLevel',
      dataIndex: ['serviceRequest', 'type'],
      filters: DESIGN_TIER_LEVEL_FILTERS,
      render: (_: any, item: CreditsTransaction) => {
        return `${
          DESIGN_TIER_LEVEL_FILTERS.find((project) => project.value === item?.serviceRequest?.type)
            ?.text ?? '--'
        }${
          item?.serviceRequest?.upgradedFrom ? ' ' + t('DesignService.uploadQueue.upgradeTag') : ''
        }`;
      },
    },
    {
      title: t('CreditHistory.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '22.5rem',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a: CreditsTransaction, b: CreditsTransaction) =>
        a?.createdAt?.localeCompare(b?.createdAt),
      render: (createdAt: string) => {
        return new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
      },
    },
    {
      title: t('CreditHistory.user'),
      dataIndex: 'userInfo',
      key: 'userInfo',
      width: '22.5rem',
      render: (_: any, item: CreditsTransaction) => {
        if (item?.user && (item.user?.firstName || item.user?.lastName)) {
          /**
           * We mask the name for the ESR designer and remove the email link for accounting purposes
           */
          if (item.user?.id === ESR_DESIGNER_ID) {
            return ESR_DESIGNER_DISPLAY_NAME;
          }
          return (
            <a
              href={'mailto:' + item.user?.email}
              target="_blank"
              aria-label={t('CreditHistory.user') ?? ''}
              rel="noreferrer"
            >
              {`${item.user?.firstName ?? ''} ${item.user?.lastName ?? ''}`}
            </a>
          );
        }
        return '--';
      },
    },
    {
      title: t('CreditHistory.nearmapArea'),
      dataIndex: 'nearmapArea',
      key: 'nearmapArea',
      width: '22.5rem',
      render: (_: any, item: CreditsTransaction) =>
        item?.category === CREDIT_TRANSACTION_CATEGORY.nearmaps && item?.nearmapArea
          ? `${item?.nearmapArea}m\u00B2 ${
              item?.nearmapAllDates ? t('CreditHistory.allDates') : ''
            }`
          : '--',
    },
    {
      title: t('CreditHistory.memo'),
      dataIndex: 'memo',
      key: 'memo',
      width: '22.5rem',
      render: (_: any, item: CreditsTransaction) =>
        item?.memo?.includes('Memo:')
          ? t('CreditHistory.extraCreditsWereChargedFor', {
              memo: `${item.memo.split('Memo: ')[1]}`,
            })
          : item?.memo.split('- ')[1] ?? '--',
    },
  ];

  return columns;
};

export default TableData;
