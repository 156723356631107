import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

const selectExampleMediaState = (state: RootState) => state.exampleMedia;

const selectExampleMediaMap = createSelector(
  [selectExampleMediaState],
  (exampleMediaState) => exampleMediaState.exampleMediaMap
);

const selectTemplate = (state: RootState, templateId: string) => {
  const exampleMediaMap = selectExampleMediaMap(state);
  return templateId ? exampleMediaMap[templateId] : undefined;
};

const selectExampleMedia = (state: RootState, templateId: string) => {
  const template = selectTemplate(state, templateId);
  return template ? template.media : {};
};

const selectExampleMediaOrder = createSelector(
  [selectExampleMediaState],
  (exampleMediaState) => exampleMediaState.exampleMediaOrder
);

export const selectExampleMediaByTemplate = createSelector(
  [selectExampleMedia, selectExampleMediaOrder],
  (exampleMedia, exampleMediaOrder) => {
    return exampleMediaOrder.map((mediaId: string) => exampleMedia[mediaId]).filter(Boolean);
  }
);
