import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';

import { companyMembersRequested } from 'state/slices/companySlice';

import { InviteOrChangeTeammatesPermissions } from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { USER_STATUS } from 'helpers/constants/userStatus';
import usePermissions from 'helpers/hooks/usePermissions';

import { filterTeammates } from 'helpers/utils/filterTeammates';
import { AppDispatch, RootState } from 'state/store';
import { User } from 'types';
import { NavigationBar } from '../NavigationBar/NavigationBar';
import { TeammateList } from './TeammateList/TeammateList';

const TeamList = () => {
  const { isAdmin } = usePermissions();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalClosedFromOutside, setModalClosedFromOutside] = useState(false);
  const [filteredTeammateList, setFilteredTeammateList] = useState<User[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const { companyMembers, company } = useSelector((state: RootState) => state.company);
  const { currentUser } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    dispatch(companyMembersRequested());
  }, [dispatch]);

  useEffect(() => {
    if (companyMembers) {
      setFilteredTeammateList(companyMembers);
    }
  }, [companyMembers]);

  const isUserFrozen = currentUser?.status === USER_STATUS.FROZEN;

  const handleSearchTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filteredData = filterTeammates({ userList: companyMembers, searchTerm: e.target.value });
    setFilteredTeammateList(filteredData ?? []);
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setModalClosedFromOutside(true);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Modal visible={modalVisible} onCancel={handleModalClose} {...MODAL_PROPS}>
        <InviteOrChangeTeammatesPermissions
          handleModalClose={handleModalClose}
          modalClosedFromOutside={modalClosedFromOutside}
          setModalClosedFromOutside={setModalClosedFromOutside}
          companyId={company?.id}
        />
      </Modal>
      <NavigationBar handleSearchTextInputChange={handleSearchTextInputChange} />
      <TeammateList
        companyMembers={filteredTeammateList}
        isAdmin={isAdmin}
        isUserFrozen={isUserFrozen}
        handleModalOpen={handleModalOpen}
      />
    </>
  );
};

export default TeamList;
