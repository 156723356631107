import styled from 'styled-components';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

import { ReactComponent as HazardIcon } from 'assets/icons/triangle-exclamation-solid.svg';

export const Wrapper = styled.div`
  display: flex;
  padding: 8rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const BackButtonGrid = styled.div`
  grid-template-columns: auto auto auto auto auto auto;
  display: grid;
  width: 100%;
  margin: -7rem 0 6rem -9.7rem;
  @media (max-width: 1000px) {
    margin: -7rem 0 1rem -9.7rem;
  }
`;

export const BackButtonContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
`;

export const TemplateContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const TemplateListCard = styled.div`
  margin: 2rem;
  padding: 4rem 6rem 8rem 6rem;
  display: flex;
  border: 1px solid ${colors.lightGray};
  border-radius: 1.5rem;
  background-color: ${colors.white};
  height: auto;
  width: auto;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 8rem 2rem 2rem 2rem;
    margin: 2rem;
  }
`;

export const TemplateTitle = styled.div`
  font-size: ${fontSizes.mediumSmall};
  margin: 0 1rem;
`;

export const DottedSeperator = styled.div`
  width: 100%;
  height: 0.5rem;
  border-bottom: 1px dotted ${colors.neutralGray};
`;

export const FirstSeparator = styled(DottedSeperator)`
  margin: 4rem 0 0 0;
`;

export const BottomGrid = styled.div`
  display: grid;
  margin: 4rem -4rem;
  grid-template-columns: auto auto auto auto auto auto auto;
`;

export const SaveButtonContainer = styled.div`
  grid-column: 7;
  grid-row: 4;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 24px;
`;

export const StyledHazardIcon = styled(HazardIcon)`
  vertical-align: middle;
  margin-bottom: 4px;
`;
