import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Spin } from 'antd';

import {
  allMaintenanceReportsRequested,
  resetMaintenanceReportState,
} from 'state/slices/maintenanceReport/maintenanceReportSlice';
import { notificationRequested } from 'state/slices/notificationSlice';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import ExpandableQRCode from 'components/QRCode/ExpandableQRCode';

import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import ConfirmDeleteModal from './ConfirmDeleteModal/ConfirmDeleteModal';
import { TABLE_TITLES } from './constants/TableTitles';
import CreateOrRenameReportModal from './CreateReportModal/CreateReportModal';
import FormDisplay from './FormDisplay/FormDisplay';
import './Maintenance.scss';
import ReportCard from './ReportCard/ReportCard';

const Maintenance = () => {
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
  const [isRenameModal, setIsRenameModal] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [isModalOpen, toggleModal] = useToggle();
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();

  const dispatch: AppDispatch = useDispatch();

  const { projectId } = useParams<{ projectId: string }>();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { project } = useSelector((state: RootState) => state.project);
  const { company } = useSelector((state: RootState) => state.company);

  const { t } = useTranslation();

  const { allReports, isAllReportsLoading } = useSelector(
    (state: RootState) => state.maintenanceReport
  );

  const selectedReport = allReports?.find((report) => report.id === selectedReportId);

  useEffect(() => {
    if ((!project || project.id !== projectId) && projectId) {
      dispatch(projectRequested(projectId));
    }
    if (currentUser?.id) {
      dispatch(notificationRequested(currentUser.id));
    }
  }, [currentUser?.id, dispatch, project, projectId]);

  useEffect(() => {
    dispatch(allMaintenanceReportsRequested({ projectId }));

    return () => {
      dispatch(resetMaintenanceReportState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allReports) {
      setSelectedReportId((oldReportId: string | null) => oldReportId ?? allReports[0]?.id);
    }
  }, [allReports]);

  const renderRightTab = () => {
    if (!allReports && isAllReportsLoading) {
      return <Spin size="large"></Spin>;
    }
    if (selectedReport) {
      return (
        <FormDisplay
          report={selectedReport}
          isFormEditable={isFormEditable}
          setIsFormEditable={setIsFormEditable}
        />
      );
    }
    return <section className="MaintenanceReport-Board"></section>;
  };

  const handleCardClick = (reportId: string) => {
    setSelectedReportId(reportId);
    setIsFormEditable(false);
  };

  const handleRenameButtonClick = () => {
    setIsRenameModal(true);
    toggleModal();
  };

  return (
    <>
      <CreateOrRenameReportModal
        companyId={company?.id ?? ''}
        projectId={projectId}
        selectedReportId={selectedReport?.id}
        setSelectedReportId={setSelectedReportId}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        isRenameModal={isRenameModal}
      />
      <ConfirmDeleteModal
        isDeleteModalOpen={isDeleteModalOpen}
        toggleDeleteModal={toggleDeleteModal}
        report={selectedReport}
      />
      <article className="MaintenanceReport-MainWrapper">
        <section className="MaintenanceReport-ListWrapper">
          <div className="MaintenanceReport-QRCodeContainer">
            <ExpandableQRCode />
          </div>
          <h2 className="MaintenanceReport-Title">{project?.name ?? ''}</h2>
          <section className="MaintenanceReport-DescriptionWrapper">
            <p className="description">{t('MaintenanceReport.description')}</p>
            <Button
              onClick={() => {
                setIsRenameModal(false);
                toggleModal();
              }}
              className="Button--Blue"
              data-testid="add-new-report-button"
            >
              <PlusIcon />
              {t('MaintenanceReport.buttonText.newReport')}
            </Button>
          </section>
          <div>
            <div className="MaintenanceReport-TableTitles">
              {TABLE_TITLES.map((item) => {
                return (
                  <p
                    key={item.title}
                    className={`MaintenanceReport-${
                      item.title === t('MaintenanceReport.tableColumns.date') ||
                      item.title === t('MaintenanceReport.tableColumns.actions')
                        ? 'SmallField'
                        : 'LargeField'
                    }`}
                    data-testid="maintenance-data-columns"
                  >
                    {item.title}
                  </p>
                );
              })}
            </div>
            <hr className="MaintenanceReport-Divider" />
            {allReports && allReports?.length > 0 ? (
              allReports?.map((report) => (
                <ReportCard
                  report={report}
                  selectedReport={selectedReport}
                  handleCardClick={handleCardClick}
                  key={report.id}
                  handleRenameButtonClick={handleRenameButtonClick}
                  toggleDeleteModal={toggleDeleteModal}
                  setSelectedReportId={setSelectedReportId}
                />
              ))
            ) : (
              <div className="MaintenanceReport-Placeholder">
                {t('MaintenanceReport.noReports')}
              </div>
            )}
          </div>
        </section>
        {renderRightTab()}
      </article>
    </>
  );
};

export default Maintenance;
