export const fetchProjectsUrl = () => '/projects';

export const fetchProjectsForCSVExportUrl = (companyId: string) =>
  `/projects/export/csv/${companyId}`;

export const fetchProjectUrl = (id: string) => `/projects/${id}`;

export const fetchProjectsFiltersUrl = () => '/projects/options';

export const updateProjectsTeammatesUrl = (projectId: string, userId: string) =>
  `/projects/${projectId}/teammates/${userId}`;

export const uploadProjectUrl = (projectId: string) => `/projects/${projectId}/upload`;

export const fetchProjectPinsUrl = () => '/projects/pins';

export const cancelDesignServicesAutomationUrl = (projectId: string) =>
  `/projects/${projectId}/cancelAutomation`;
