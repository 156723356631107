import { createSelector } from 'reselect';
import { MediaCategory, MediaCategoryType } from 'types';

import { RootState } from 'state/store';

/**
 * For an explanation of why the strange EMPTY_ARRAY usage
 * https://reselect.js.org/usage/handling-empty-array-results
 */
const EMPTY_ARRAY: [] = [];

const selectAllDefaultMediaCategories = (state: RootState): MediaCategory[] =>
  state.mediaCategories.allMediaCategories;

const selectCategoryTypeMap = (state: RootState): { [key: string]: MediaCategory[] } =>
  state.mediaCategories.categoriesByType;

const typePassThrough = (_: RootState, param: MediaCategoryType): string => param;
const selectCategoriesByType = createSelector(
  [selectCategoryTypeMap, typePassThrough],
  (categoriesByType, type): MediaCategory[] => {
    if (!categoriesByType[type]?.length) {
      return EMPTY_ARRAY;
    }
    return categoriesByType[type];
  }
);

export { selectAllDefaultMediaCategories, selectCategoriesByType };
