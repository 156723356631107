import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  DroneDataType,
  FEATURE_LIST,
  getFeatureAvailability,
  PROJECT_TYPES,
} from '@cpm/scanifly-shared-data';
import { Card, Tooltip } from 'antd';
import { startCase } from 'lodash';
import BetaTag from 'screens/BetaTag/BetaTag';

import { userCompanyRequested } from 'state/slices/companySlice';
import { DDSFeedbackReportDownloadUrlRequested } from 'state/slices/droneDataScoreSlice';
import { AppDispatch, RootState } from 'state/store';

import { UpgradeModal } from 'components';
import { MESSAGE_GROUP } from 'components/UpgradeTierLevelForAccess/constants';

import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark-icon.svg';

import {
  COLORS,
  FAKE_DRONE_DATA,
  thresholdDateForLargeProjects,
  thresholdDateForSmallProjects,
} from './constants';
import DownloadFeedbackPDFButton from './DownloadFeedbackPDFButton';
import './DroneDataScore.scss';
import DroneDataScoreGraph from './DroneDataScoreGraph/DroneDataScoreGraph';
import DroneDataScoreStat from './DroneDataScoreStat/DroneDataScoreStat';
import { getTooltipContent } from './helpers';
import UpgradeMessage from './UpgradeMessage';

type Props = {
  droneData?: DroneDataType;
  projectType?: PROJECT_TYPES;
  projectId?: string;
  completedDate?: string;
  droneDataScoreAvailabilityForPricingTier: boolean;
};

const DroneDataScore = ({
  droneData,
  projectType,
  projectId,
  completedDate,
  droneDataScoreAvailabilityForPricingTier = false,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const [isModalOpen, toggleModal] = useToggle();
  const { isScaniflyAdmin } = usePermissions();
  const projectCompletedDate = completedDate ? new Date(completedDate)?.getTime() : null;
  const { company } = useSelector((state: RootState) => state.company);
  const {
    projectId: UrlProjectId,
    feedbackReportUrl,
    DDSPDFReportCreatedAt,
  } = useSelector((state: RootState) => state.droneDataScore);
  const ddsPdfDownloadAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DDS_FEEDBACK_REPORT,
    company?.pricingTier
  );
  const { t } = useTranslation();
  const releaseDateForDDSv2 = new Date(2023, 8, 27);
  const completedAfterDDSv2Release = completedDate
    ? new Date(completedDate).getTime() >= releaseDateForDDSv2.getTime()
    : false;

  const releaseDateForDDSPDFReport = new Date(2023, 3, 2);
  const createdAfterDDSPDFFeatureRelease = DDSPDFReportCreatedAt
    ? new Date(DDSPDFReportCreatedAt).getTime() >= releaseDateForDDSPDFReport.getTime()
    : false;

  useEffect(() => {
    if (!company) {
      dispatch(userCompanyRequested());
    }
  }, [company, dispatch]);

  useEffect(() => {
    if (company && projectId && projectId !== UrlProjectId && ddsPdfDownloadAccess) {
      dispatch(DDSFeedbackReportDownloadUrlRequested({ projectId, companyId: company?.id }));
    }
  }, [UrlProjectId, company, ddsPdfDownloadAccess, dispatch, feedbackReportUrl, projectId]);

  const getDownloadFeedbackReportUI = () => {
    if (!ddsPdfDownloadAccess) {
      return (
        <Tooltip
          placement="bottom"
          title={
            <>
              <strong>
                <u>Upgrade</u>
              </strong>{' '}
              to unlock
            </>
          }
          overlayStyle={{ maxWidth: '30rem' }}
        >
          <DownloadFeedbackPDFButton onClick={toggleModal} />
        </Tooltip>
      );
    }

    if (feedbackReportUrl && createdAfterDDSPDFFeatureRelease) {
      return (
        <a
          href={feedbackReportUrl}
          download
          target="_blank"
          rel="noreferrer"
          className="DroneDataScore-Download"
        >
          {t('DroneDataScore.download')}
        </a>
      );
    }
  };

  const getRenderedDroneData = (
    droneData: DroneDataType,
    droneDataScoreAvailabilityForPricingTier: boolean
  ): null | DroneDataType => {
    if (!droneDataScoreAvailabilityForPricingTier) {
      return FAKE_DRONE_DATA;
    } else {
      if (projectCompletedDate) {
        if (projectType === PROJECT_TYPES.SMALL) {
          if (
            projectCompletedDate < thresholdDateForSmallProjects &&
            !Number(droneData?.droneDataScore)
          ) {
            return null;
          }
        }
        if (projectType === PROJECT_TYPES.LARGE) {
          if (projectCompletedDate < thresholdDateForLargeProjects) {
            return null;
          }
        }
      }
      return droneData ? droneData : null;
    }
  };

  const renderedDroneData = droneData
    ? getRenderedDroneData(droneData, droneDataScoreAvailabilityForPricingTier)
    : null;

  if (!renderedDroneData) {
    return null;
  }

  const droneDataStats = Object.entries(renderedDroneData).slice(1, 4) as [
    keyof DroneDataType,
    string,
  ][];

  const renderStats = (data: [keyof DroneDataType, string][]) => {
    return data.map(([key, value], index) => (
      <DroneDataScoreStat
        color={COLORS[index]}
        name={startCase(key)}
        value={Number(value)}
        completedAfterDDSv2Release={completedAfterDDSv2Release}
        key={index}
        isLast={index === data.length - 1}
      />
    ));
  };

  const selectContentToRender = () => {
    if (projectType === PROJECT_TYPES.LARGE) {
      return (
        <Card className="DroneDataScore">
          <div>{t('DroneDataScore.notAvailableLargeProject')}</div>
        </Card>
      );
    } else if (!droneDataStats.length) {
      return (
        <Card className="DroneDataScore">
          <div>{t('DroneDataScore.notAvailable')}</div>
        </Card>
      );
    }
    return (
      <>
        <UpgradeModal
          messageGroup={MESSAGE_GROUP.DDS_REPORT_DOWNLOAD}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        />
        {!droneDataScoreAvailabilityForPricingTier && <UpgradeMessage />}
        <Card className="DroneDataScore">
          <div>
            <Tooltip
              placement="topLeft"
              title={getTooltipContent(completedAfterDDSv2Release)}
              overlayStyle={{ maxWidth: '40rem' }}
              arrowPointAtCenter
            >
              <QuestionMarkIcon />
            </Tooltip>
            <div className="DroneDataScore-Container">
              <DroneDataScoreGraph
                data={droneDataStats}
                droneDataScore={Number(renderedDroneData.droneDataScore)}
              />
              <div className="DroneDataScore-Title">
                <p>{t('DroneDataScore.dds')}</p>
                <BetaTag />
              </div>
              {getDownloadFeedbackReportUI()}
            </div>
          </div>
          <div className="DroneDataScore-Stats">{renderStats(droneDataStats)}</div>
        </Card>
      </>
    );
  };

  return <div className="DroneDataScoreUpgradeMessageWrapper">{selectContentToRender()}</div>;
};

export default DroneDataScore;
