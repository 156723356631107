import { RemoveMissingMediaPayload } from 'state/slices/checklists/types';

export const fetchChecklistUrl = (projectId: string, checklistId: string) =>
  `/checklist/${projectId}/${checklistId}`;
export const fetchAllChecklistsUrl = (projectId: string) => `/checklist/${projectId}`;
export const createChecklistUrl = () => `/checklist/create`;
export const getChecklistTemplateUrl = (templateId: string) => `/checklist-template/${templateId}`;
export const getChecklistTemplatesUrl = ({
  withUnpublished,
  companyId,
}: {
  withUnpublished: boolean;
  companyId?: string;
}) =>
  `/checklist-template?withUnpublished=${withUnpublished}${companyId ? `&companyId=${companyId}` : ''}`;
export const updateChecklistTemplateUrl = (templateId: string) =>
  `/checklist-template/${templateId}`;
export const resetChecklistTemplateUrl = (templateId: string) =>
  `/checklist-template/${templateId}/reset`;
export const deleteChecklistTemplateUrl = (templateId: string) =>
  `/checklist-template/${templateId}`;
export const createChecklistTemplateUrl = (companyId?: string) =>
  `/checklist-template/create${companyId ? `?companyId=${companyId}` : ''}`;
export const importSiteCaptureTemplateUrl = (companyId?: string) =>
  `/checklist-template/import/site-capture${companyId ? `?companyId=${companyId}` : ''}`;

export const removeMissingMediaUrl = ({ checklistId, projectId }: RemoveMissingMediaPayload) =>
  `/checklist/${projectId}/${checklistId}/missing-media`;
