import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ChecklistStatusEnum } from '@cpm/scanifly-shared-data';

import {
  Container,
  Content,
  InfoRow,
  StatusTag,
  StyledChevronRight,
  Title,
  TitleRow,
  TopRow,
  UpdatedAt,
} from './components';

import { formatDateTime } from 'helpers/utils/dateFormat';

import { Button as AppButton, Tooltip } from 'components';
import colors from 'helpers/constants/colors';
import { ChecklistTileProps } from './types';

const ChecklistTile = ({
  checklistStatus,
  clickable,
  checklistId,
  isRemovingMedia,
  isRemoveMediaVisible,
  projectName,
  title,
  updatedAt,
  onRemoveMediaClick,
  onTileClick,
  selected,
}: ChecklistTileProps) => {
  const { t } = useTranslation();

  const statusText = useMemo(() => {
    switch (checklistStatus) {
      case ChecklistStatusEnum.completed:
        return t('Checklists.completed');
      case ChecklistStatusEnum.formSubmitted:
      case ChecklistStatusEnum.pdfProcessing:
        return t('Checklists.processing');
      case ChecklistStatusEnum.awaitingMedia:
        return t('Checklists.uploading');
      case ChecklistStatusEnum.draft:
        return t('Checklists.draft');
      case ChecklistStatusEnum.pdfProcessingFailed:
        return t('Checklists.failed');
      default:
        return t('Checklists.unknown');
    }
  }, [checklistStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const testId = (element: string) => `checklisttile-${element}-${checklistId}`;

  return (
    <Container
      clickable={clickable}
      data-testid={testId('container')}
      onClick={onTileClick}
      selected={selected}
    >
      <Content>
        <TopRow>
          <Title data-testid={testId('projectname')}>{projectName}</Title>
          <StatusTag
            dataTestId={testId('status')}
            statusText={statusText}
            checklistStatus={checklistStatus}
          />
        </TopRow>
        <TitleRow>
          <Title data-testid={testId('title')}>{title}</Title>
          <StyledChevronRight />
        </TitleRow>
        <InfoRow>
          <UpdatedAt data-testid={testId('updatedat')}>
            {t('Checklists.updatedAt', { updatedAt: formatDateTime(updatedAt) })}
          </UpdatedAt>
        </InfoRow>
        {isRemoveMediaVisible && checklistStatus === ChecklistStatusEnum.awaitingMedia ? (
          <Tooltip
            bottomPercent={60}
            leftPercent={25}
            title={t('Checklists.removeMissingMediaTooltip')}
          >
            <AppButton
              disabled={isRemovingMedia}
              icon={'delete'}
              color={colors.red}
              id={testId('removemedia')}
              label={t('Checklists.removeMissingMedia')}
              onClick={(e) => {
                e?.stopPropagation();
                onRemoveMediaClick();
              }}
            />
          </Tooltip>
        ) : null}
      </Content>
    </Container>
  );
};

export default ChecklistTile;
