import { Link } from 'react-router-dom';

import { ColumnType, SortOrder } from 'antd/lib/table/interface';
import {
  ALL_STATUS_FILTERS,
  DATE_OPTIONS,
} from 'screens/ScaniflyAdmin/CustomerSupportUpload/constants';
import { Project } from 'types';

import { ActionDropdown, Scanifly3DButton } from 'components';

import { PROJECT_TYPE_FILTERS } from 'helpers/constants/projectTypes';
import { scaniflyAdminUsersCustomerRoute } from 'helpers/constants/routes';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { completedDateSorter } from 'helpers/utils/completedDateSorter';
import { filterProjectsByType } from 'helpers/utils/filterProjectsByType';
import { renderProjectType } from 'helpers/utils/renderProjectType';
import { i18n } from 'helpers/utils/translations';

import { droneDataScoreFields } from '../constants/droneDataScoreFields';
import { createDroneDataColumns } from './createDroneDataColumns';
import { getLink } from './getLink';

export const getAdminProjectTableColumns = (
  tableName: string,
  openChangeStatusModalForProject: null | ((projectId: string) => void) = null
): ColumnType<Project>[] => {
  const columns = [
    {
      title: i18n.t('ScaniflyAdminTableTitles.project'),
      dataIndex: 'name',
      key: 'name',
      width: '20rem',
      sorter: (a: Project, b: Project) => a?.name.localeCompare(b?.name),
      render: (name: string, item: Project) => (
        <Link to={getLink(tableName, item?.id, item?.status, item?.statusDescription)}>{name}</Link>
      ),
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.projectType'),
      dataIndex: 'type',
      width: '12.5rem',
      filters: PROJECT_TYPE_FILTERS,
      onFilter: (value: string | number | boolean, record: Project) =>
        filterProjectsByType(value, record),
      render: (type: string) => renderProjectType(type),
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.status'),
      dataIndex: 'statusDescription',
      key: 'status',
      width: '12.5rem',
      filters: ALL_STATUS_FILTERS,
      onFilter: (value: string | number | boolean, record: Project) =>
        record.status?.toLowerCase() === value.toString().toLowerCase(),
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.actions'),
      dataIndex: 'actions',
      render: (_: any, item: Project) => {
        return (
          <ActionDropdown
            project={item}
            openChangeStatusModalForProject={openChangeStatusModalForProject ?? (() => {})}
            tableName={tableName}
          />
        );
      },
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.scanifly3d'),
      dataIndex: 'scanifly3d',
      render: (_: any, item: Project) => (
        <Scanifly3DButton projectId={item.id} projectStatus={item.status} />
      ),
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.company'),
      dataIndex: 'company',
      key: 'company.name',
      width: '18rem',
      sorter: (a: Project, b: Project) =>
        (a?.company?.name ?? '').localeCompare(b?.company?.name ?? ''),
      render: (company: { name?: string; id?: string }) =>
        company ? <a href={scaniflyAdminUsersCustomerRoute(company?.id)}>{company?.name}</a> : `-`,
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.address'),
      dataIndex: 'address',
      width: '35rem',
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '22.5rem',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a: Project, b: Project) => a?.createdAt.localeCompare(b?.createdAt),
      render: (createdAt: string) => {
        return new Date(createdAt).toLocaleDateString('en-US', DATE_OPTIONS);
      },
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.completedAt'),
      dataIndex: 'completedDate',
      key: 'completedDate',
      width: '22.5rem',
      sorter: (a: Project, b: Project) => completedDateSorter(a, b),
      render: (completedDate: string) => {
        return completedDate
          ? new Date(completedDate).toLocaleDateString('en-US', DATE_OPTIONS)
          : 'Not Completed';
      },
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '19.5rem',
      sorter: (a: Project, b: Project) => a.createdBy.localeCompare(b.createdBy),
      render: (text: string, record: Project) => (
        <a
          href={'mailto:' + record.createdBy}
          target="_blank"
          aria-label="email account holder"
          rel="noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: i18n.t('ScaniflyAdminTableTitles.projectId'),
      dataIndex: 'id',
      key: 'id',
      width: '20rem',
      sorter: (a: Project, b: Project) => a?.id.localeCompare(b?.id),
    },
  ];

  if (tableName === TABLE_NAMES.PROJECTS) return columns;
  if (tableName === TABLE_NAMES.CUSTOMER_PROJECTS || tableName === TABLE_NAMES.USER_PROJECTS) {
    const droneDataColumns = createDroneDataColumns(droneDataScoreFields);
    const filteredColumns = columns.filter((item) => item.title !== 'Company');
    return [...filteredColumns, ...droneDataColumns];
  }
  return [];
};
