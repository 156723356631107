import styled from 'styled-components';

import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right-gray.svg';
import colors from 'helpers/constants/colors';

export * from './StatusTag';

type ContainerProps = {
  selected: boolean;
  clickable: boolean;
};

export const Container = styled.div<ContainerProps>`
  flex: 1;
  border-radius: 10px;
  border: ${(props) => (props.selected ? '0.15rem' : '0.1rem')} solid;
  border-color: ${(props) => (props.selected ? colors.mainBlue : colors.neutralGray)};
  ${(props) => (props.selected ? `box-shadow: 0 0 0.5rem 0.25rem ${colors.neutralGray};` : '')}
  background-color: ${colors.white};
  margin-bottom: 2rem;
  padding: 1.2rem 1.5rem;
  min-width: 20rem;
  max-width: 40rem;

  ${(props) => (props.clickable ? `&:hover {cursor: pointer;}` : '')}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const StyledChevronRight = styled(ChevronRight)`
  color: $neutral-gray;
`;

export const Title = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: 1rem;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const UpdatedAt = styled.div`
  font-size: 1rem;
  color: ${colors.labelGray};
`;
