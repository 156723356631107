import { useDispatch } from 'react-redux';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { camelCase } from 'lodash';

import { designStatusUpdateRequested } from 'state/slices/designServices/designServicesQueueSlice';

import { DESIGN_STATUS, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { useTranslation } from 'react-i18next';
import { getActionList } from 'screens/DesignServices/helpers/getActionList';
import { getTargetStatus } from 'screens/DesignServices/helpers/getTargetStatus';
import { AppDispatch } from 'state/store';

const DesignActionsDropdown = ({
  tierLevel,
  designId,
  projectName,
  status,
}: {
  tierLevel: TIER_LEVEL;
  designId: string;
  projectName: string;
  status: DESIGN_STATUS;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  // The actions here will trigger emails to customers, so to avoid random clicks,
  // we added the confirm like the other actions in the portal admin tables
  const handleConfirm = (designId: string, projectName: string, action: string) => {
    confirm({
      title: t('DesignActions.confirmTitle'),
      content: t('DesignActions.confirmContent', { projectName, action }),
      okText: t('DesignActions.confirmOkText'),
      okButtonProps: { style: { background: colors.darkBlue } },
      onOk: () => handleAction(designId, projectName, action),
      ...CONFIRM_PROPS,
    });
  };

  const handleAction = (designId: string, projectName: string, action: string) => {
    const targetStatus = getTargetStatus(action);
    dispatch(designStatusUpdateRequested({ designId, projectName, targetStatus }));
  };

  const customActionList = getActionList({ tierLevel, status });

  const dropdownMenu = (
    <Menu>
      {customActionList.length
        ? customActionList.map((title) => (
            <Menu.Item
              onClick={() => handleConfirm(designId, projectName, title)}
              key={title}
              id={`option-${camelCase(title)}-${designId}`}
            >
              {title}
            </Menu.Item>
          ))
        : null}
    </Menu>
  );

  return (
    // @ts-ignore this lib is incompatible with react18
    <Dropdown
      overlay={dropdownMenu}
      trigger={['click']}
      disabled={!customActionList.length}
      data-testid={`select-${designId}`}
    >
      <Button className="Button--White" id={`design-tier-select-${designId}`}>
        {t('DesignActions.selectAction')}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default DesignActionsDropdown;
