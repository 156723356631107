import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ChecklistTemplateSubtypeEnum, ChecklistTemplateType } from '@cpm/scanifly-shared-data';

import { copyChecklistTemplate } from 'state/slices/checklists';
import { AppDispatch } from 'state/store';

import { Button, ModalContext } from 'components';
import { TemplateListCard, TemplateTitle } from '../components';
import {
  CardHeader,
  Description,
  ItemGrid,
  StyledEyeIcon,
  TemplateIcon,
  Title,
  ViewLink,
} from './components';

import { ReactComponent as FinancingIconHighlighted } from 'assets/finance-tab-highlighted-icon.svg';
import { ReactComponent as FinancingIcon } from 'assets/finance-tab-icon.svg';
import { ReactComponent as OperationsIconHighlighted } from 'assets/operations-tab-highlighted-icon.svg';
import { ReactComponent as OperationsIcon } from 'assets/operations-tab-icon.svg';
import { ExpandableQRCode } from 'components/QRCode';
import { ACCESS } from 'helpers/constants';
import colors from 'helpers/constants/colors';
import { accountCompanyChecklistTemplateEditorRoute } from 'helpers/constants/routes';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';

type Props = {
  selectedCompanyId?: string;
  templates: ChecklistTemplateType[];
  isAdmin?: boolean;
};

export const DefaultTemplateListCard = ({ selectedCompanyId, templates, isAdmin }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { displayInputModal } = useContext(ModalContext);

  const [activeTab, setActiveTab] = useState(ChecklistTemplateSubtypeEnum.OPERATIONS);
  const isChecklistTemplateSubtypeSelectionEnabled = useFeatureToggle(
    ACCESS.IS_CHECKLIST_TEMPLATE_SUBTYPE_SELECTION_ENABLED
  );

  const onChecklistCopyClick = useCallback(
    (template: ChecklistTemplateType) => {
      const onCopyChecklistSubmit = async (input: string) => {
        await dispatch<any>(
          copyChecklistTemplate({ ...template, title: input }, selectedCompanyId)
        );
      };
      displayInputModal({
        title: t('Checklists.copyChecklist'),
        description: t('Checklists.copyChecklistDescription', { title: template.title }),
        placeholderText:
          t('Checklists.copyChecklistCopyOf', { title: template.title }) ?? undefined,
        defaultValue: t('Checklists.copyChecklistCopyOf', { title: template.title }) ?? undefined,
        actionButtonOnClick: onCopyChecklistSubmit,
        actionButtonLabel: t('Checklists.copyChecklist'),
      });
    },
    [displayInputModal, t, dispatch, selectedCompanyId]
  );

  // Filter templates based on activeTab
  const filteredTemplates = useMemo(() => {
    if (isChecklistTemplateSubtypeSelectionEnabled) {
      const defaultTemplates = templates.map((template) => ({
        ...template,
        subtype: template.subtype || ChecklistTemplateSubtypeEnum.OPERATIONS,
      }));
      return defaultTemplates.filter((template) => template.subtype === activeTab);
    }

    return templates;
  }, [templates, activeTab, isChecklistTemplateSubtypeSelectionEnabled]);

  const templatesList = useMemo(
    () =>
      Array.isArray(filteredTemplates) &&
      filteredTemplates.map((template) => (
        <ItemGrid key={template.id}>
          <TemplateIcon templateTitle={template.title} />
          <TemplateTitle>
            {template.title.trim() !== '' ? template.title : `${t('Checklists.templateTitle')}`}
          </TemplateTitle>
          <ViewLink
            to={accountCompanyChecklistTemplateEditorRoute(template.id)}
            id="viewChecklistLink"
            title={t('Checklists.viewTemplate') as string}
          >
            <StyledEyeIcon />
          </ViewLink>
          <Button
            onClick={() => onChecklistCopyClick(template)}
            color={colors.mainBlue}
            label={t('buttonTexts.add')}
          />
        </ItemGrid>
      )),
    [filteredTemplates, onChecklistCopyClick, t]
  );

  return (
    <TemplateListCard id="default-template-card">
      <CardHeader>
        <ExpandableQRCode staticContainer />
        <Title>{t('Checklists.checklistTemplatesTitle')}</Title>
        <Description>
          {isAdmin
            ? t('Checklists.adminChecklistTemplatesDescription')
            : t('Checklists.checklistTemplatesDescription')}
        </Description>
      </CardHeader>
      {/* Add tabs only if the feature flag is enabled */}
      {isChecklistTemplateSubtypeSelectionEnabled && (
        <div className="Designs-DesignsTypes">
          <button
            onClick={() => setActiveTab(ChecklistTemplateSubtypeEnum.OPERATIONS)}
            className={activeTab === ChecklistTemplateSubtypeEnum.OPERATIONS ? 'active' : ''}
          >
            {activeTab === ChecklistTemplateSubtypeEnum.OPERATIONS ? (
              <OperationsIconHighlighted />
            ) : (
              <OperationsIcon />
            )}
            {t('Checklists.operations')}
          </button>
          <button
            onClick={() => setActiveTab(ChecklistTemplateSubtypeEnum.FINANCE)}
            className={activeTab === ChecklistTemplateSubtypeEnum.FINANCE ? 'active' : ''}
          >
            {activeTab === ChecklistTemplateSubtypeEnum.FINANCE ? (
              <FinancingIconHighlighted />
            ) : (
              <FinancingIcon />
            )}
            {t('Checklists.financing')}
          </button>
        </div>
      )}
      {templatesList}
    </TemplateListCard>
  );
};
