import { Button, ModalContext } from 'components';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import { openNotification } from 'helpers/utils/openNotification';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { cancelDesignServiceAutomation } from 'state/slices/projectSlice';
import { AppDispatch } from 'state/store';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin-top: 2rem;
  padding: 2rem 4rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 1rem;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes.mediumSmall};
`;

const StyledContainer = styled.div`
  display: flex;
  margin-bottom: 3rem;
`;

const Bold = styled.p`
  font-weight: ${fontWeights.bold};
  margin-right: 0.5rem;
`;

const StyledHeader = styled.h3`
  margin: 3rem 0;
`;

const StyledButtonWrapper = styled.div`
  align-self: flex-end !important;
  margin-bottom: 2rem;
`;

export const CancelAutomationForProcessing = ({
  service,
  projectId,
}: {
  service: string;
  projectId: string;
}) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { displayDeleteModal } = useContext(ModalContext);

  const handleError = useCallback(() => {
    openNotification({
      type: 'error',
      title: t('Generic.error'),
      text: t('DesignServicesAutomationToggles.automationCancellationError'),
    });
  }, [t]);

  const displayCancellationModal = useCallback(
    (service: string) => {
      displayDeleteModal({
        actionButtonOnClick: () => {
          dispatch(cancelDesignServiceAutomation({ projectId, handleError }));
        },
        actionButtonLabel: t('Generic.yes'),
        cancelButtonLabel: t('Generic.no'),
        title: `${t('DesignServicesAutomationToggles.cancelOrderModalTitle', { service })}`,
        description: t('DesignServicesAutomationToggles.cancelOrderModalDescription', {
          service,
        }),
      });
    },
    [dispatch, displayDeleteModal, handleError, projectId, t]
  );

  const handleClick = useCallback(
    () => displayCancellationModal(service),
    [displayCancellationModal, service]
  );

  return (
    <StyledWrapper>
      <StyledHeader>{t('DesignServicesAutomationToggles.projectProcessing')}</StyledHeader>
      <p>{t('DesignServicesAutomationToggles.willBeAutoCreatingService', { service })}</p>
      <StyledContainer>
        <Bold>{t('DesignServicesAutomationToggles.haveAQuestion')}</Bold>
        <p>{t('DesignServicesAutomationToggles.reachOut')}</p>
      </StyledContainer>
      <StyledButtonWrapper>
        <Button
          label={t('DesignServicesAutomationToggles.cancelButton', { service })}
          color={colors.red}
          width="22rem"
          icon="close"
          onClick={handleClick}
        />
      </StyledButtonWrapper>
    </StyledWrapper>
  );
};
