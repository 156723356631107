import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Row } from 'antd';
import { FieldConfig, FieldInputProps } from 'formik';

import { RootState } from 'state/store';

import { Divider } from 'components';

import { FORM_CONTROLS } from '../constants';

const AccountingProjectInfoForm = ({
  getFieldProps,
  selectedSubscriptionId,
}: {
  getFieldProps: (nameOrOptions: string | FieldConfig<any>) => FieldInputProps<any>;
  selectedSubscriptionId: string | undefined;
}) => {
  const { activeSubscription, selectedSubscription } = useSelector(
    (state: RootState) => state.adminSubscriptions
  );
  const { t } = useTranslation();

  return (
    <div className="Accounting-Form-ProjectCount">
      <h2 className="Title">{t('AccountingForms.projectInfo')}</h2>
      <Row className="Accounting-Form-Row">
        <div className="FormControl-Wrapper">
          <div className="Accounting-Form-ProjectCount-Current">
            <p>{t('AccountingForms.countToDate')}</p>
          </div>
          <label htmlFor="smallProjectCount">{t('AccountingForms.small')}</label>
          <p id="smallProjectCount">
            {selectedSubscription
              ? selectedSubscription.smallProjectsToDate
              : activeSubscription && activeSubscription.id === selectedSubscriptionId
                ? activeSubscription.smallProjectsToDate
                : 0}
          </p>
          <label htmlFor="largeProjectCount">{t('AccountingForms.large')}</label>
          <p id="largeProjectCount">
            {selectedSubscription
              ? selectedSubscription.largeProjectsToDate
              : activeSubscription && activeSubscription.id === selectedSubscriptionId
                ? activeSubscription.largeProjectsToDate
                : 0}
          </p>
        </div>
        <div className="FormControl-Wrapper">
          <p>{t('AccountingForms.projectsRemaining')}</p>
          <label htmlFor="smallProjectsRemaining">{t('AccountingForms.small')}</label>
          <p id="smallProjectsRemaining">
            {selectedSubscription
              ? selectedSubscription.smallProjectQuota -
                (selectedSubscription?.smallProjectsToDate ?? 0)
              : activeSubscription && activeSubscription.id === selectedSubscriptionId
                ? activeSubscription.smallProjectQuota -
                  (activeSubscription?.smallProjectsToDate ?? 0)
                : getFieldProps(FORM_CONTROLS.PROJECT_QUOTA_SM).value}
          </p>
          <label htmlFor="largeProjectsRemaining">{t('AccountingForms.large')}</label>
          <p id="largeProjectsRemaining">
            {selectedSubscription
              ? selectedSubscription.largeProjectQuota -
                (selectedSubscription?.largeProjectsToDate ?? 0)
              : activeSubscription && activeSubscription.id === selectedSubscriptionId
                ? activeSubscription.largeProjectQuota -
                  (activeSubscription?.largeProjectsToDate ?? 0)
                : getFieldProps(FORM_CONTROLS.PROJECT_QUOTA_LG).value}
          </p>
        </div>
      </Row>
      <Divider />
    </div>
  );
};

export default AccountingProjectInfoForm;
