import * as Sentry from '@sentry/react';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { settingsApi } from 'state/slices/settingsApiSlice';

import { environment } from 'helpers/utils';

import { reducers } from './reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const rootReducer = combineReducers({
  ...reducers,
  [settingsApi.reducerPath]: settingsApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['localPreferences'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: !environment.isProductionBuild,
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(settingsApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
