import { createContext, useCallback, useMemo, useState } from 'react';

import ConditionalModal from './ConditionalModal/ConditionalModal';
import CommercialConfirmationModal from './ConfirmationModals/CommercialConfirmationModal';
import NewBuildConfirmationModal from './ConfirmationModals/NewBuildConfirmationModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import DesignServicesAutomationConfirmationModal from './DesignServicesAutomationConfirmationModal/DesignServicesAutomationConfirmationModal';
import FormModal from './FormModal';
import ImportModal from './ImportModal';
import MediaViewModal from './MediaViewModal';
import TextInputModal from './TextInputModal';
import {
  ConditionalModalProps,
  ConfirmationModalProps,
  DeleteModalProps,
  FormModalProps,
  ImportModalProps,
  InputModalProps,
  MediaViewModalProps,
  ModalContextType,
  ModalProviderProps,
} from './types';

export const ModalContext = createContext<ModalContextType>({
  displayDeleteModal: () => {},
  displayInputModal: () => {},
  displayImportModal: () => {},
  displayConditionalModal: () => {},
  displayFormModal: () => {},
  displayNewBuildConfirmationModal: () => {},
  displayCommercialConfirmationModal: () => {},
  displayMediaViewModal: () => {},
  displayDesignServiceAutomationConfirmationModal: () => {},
});

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [deleteModalProps, setDeleteModalProps] = useState<DeleteModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonOnClick: () => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [inputModalVisible, setInputModalVisible] = useState<boolean>(false);
  const [inputModalProps, setInputModalProps] = useState<InputModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    placeholderText: 'na',
    actionButtonOnClick: (_) => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [conditionalModalVisible, setConditionalModalVisible] = useState<boolean>(false);
  const [conditionalModalProps, setConditionalModalProps] = useState<ConditionalModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonOnClick: (_) => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [formModalProps, setFormModalProps] = useState<FormModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    formFields: <></>,
    actionButtonOnClick: () => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const [newBuildConfirmationModalVisible, setNewBuildConfirmationModalVisible] =
    useState<boolean>(false);
  const [newBuildConfirmationModalProps, setNewBuildConfirmationModalProps] =
    useState<ConfirmationModalProps>({
      title: 'Error',
      description: 'This modal was not properly initialized...',
      actionButtonOnClick: () => {},
      actionButtonLabel: 'Cancel',
      onCancel: () => {},
    });

  const [commercialConfirmationModalVisible, setCommercialConfirmationModalVisible] =
    useState<boolean>(false);
  const [commercialConfirmationModalProps, setCommercialConfirmationModalProps] =
    useState<ConfirmationModalProps>({
      title: 'Error',
      description: 'This modal was not properly initialized...',
      actionButtonOnClick: () => {},
      actionButtonLabel: 'Cancel',
      onCancel: () => {},
    });

  const [
    designServicesAutomationConfirmationModalVisible,
    setDesignServicesAutomationConfirmationModalVisible,
  ] = useState<boolean>(false);
  const [
    designServicesAutomationConfirmationModalProps,
    setDesignServicesAutomationConfirmationModalProps,
  ] = useState<ConfirmationModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonOnClick: () => {},
    actionButtonLabel: 'Cancel',
    onCancel: () => {},
  });

  const displayDeleteModal = useCallback((props: DeleteModalProps) => {
    setDeleteModalProps(props);
    setDeleteModalVisible(true);
  }, []);
  const displayInputModal = useCallback((props: InputModalProps) => {
    setInputModalProps(props);
    setInputModalVisible(true);
  }, []);
  const displayConditionalModal = useCallback((props: ConditionalModalProps) => {
    setConditionalModalProps(props);
    setConditionalModalVisible(true);
  }, []);
  const displayFormModal = useCallback((props: FormModalProps) => {
    setFormModalProps(props);
    setFormModalVisible(true);
  }, []);
  const displayNewBuildConfirmationModal = useCallback((props: ConfirmationModalProps) => {
    setNewBuildConfirmationModalProps(props);
    setNewBuildConfirmationModalVisible(true);
  }, []);

  const displayDesignServiceAutomationConfirmationModal = useCallback(
    (props: ConfirmationModalProps) => {
      setDesignServicesAutomationConfirmationModalProps(props);
      setDesignServicesAutomationConfirmationModalVisible(true);
    },
    []
  );

  const displayCommercialConfirmationModal = useCallback((props: ConfirmationModalProps) => {
    setCommercialConfirmationModalProps(props);
    setCommercialConfirmationModalVisible(true);
  }, []);

  const [mediaViewModalProps, setMediaViewModalProps] = useState<MediaViewModalProps>({
    mediaMap: {},
    mediaOrder: [],
  });
  const [mediaViewModalVisible, setMediaViewModalVisible] = useState(false);
  const displayMediaViewModal = useCallback((props: MediaViewModalProps) => {
    setMediaViewModalProps(props);
    setMediaViewModalVisible(true);
  }, []);

  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [importModalProps, setImportModalProps] = useState<ImportModalProps>({
    title: 'Error',
    description: 'This modal was not properly initialized...',
    actionButtonLabel: 'error',
    actionButtonOnClick: (_) => {},
    onCancel: () => {},
  });
  const displayImportModal = useCallback((props: ImportModalProps) => {
    setImportModalProps(props);
    setImportModalVisible(true);
  }, []);

  const value = useMemo(
    () => ({
      displayDeleteModal,
      displayInputModal,
      displayImportModal,
      displayConditionalModal,
      displayFormModal,
      displayNewBuildConfirmationModal,
      displayCommercialConfirmationModal,
      displayMediaViewModal,
      displayDesignServiceAutomationConfirmationModal,
    }),
    [
      displayDeleteModal,
      displayImportModal,
      displayInputModal,
      displayConditionalModal,
      displayFormModal,
      displayNewBuildConfirmationModal,
      displayCommercialConfirmationModal,
      displayMediaViewModal,
      displayDesignServiceAutomationConfirmationModal,
    ]
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      <TextInputModal
        title={inputModalProps.title}
        subtitle={inputModalProps.subtitle}
        description={inputModalProps.description}
        instruction={inputModalProps.instruction}
        actionButtonOnClick={inputModalProps.actionButtonOnClick}
        actionButtonColor={inputModalProps.actionButtonColor}
        confirmValue={inputModalProps.confirmValue}
        defaultValue={inputModalProps.defaultValue}
        actionButtonLabel={inputModalProps.actionButtonLabel}
        isVisible={inputModalVisible}
        setIsVisible={setInputModalVisible}
        maxLength={inputModalProps.maxLength}
      />
      <DeleteConfirmationModal
        title={deleteModalProps.title}
        description={deleteModalProps.description}
        actionButtonOnClick={deleteModalProps.actionButtonOnClick}
        actionButtonLabel={deleteModalProps.actionButtonLabel}
        cancelButtonLabel={deleteModalProps.cancelButtonLabel}
        onCancel={deleteModalProps.onCancel}
        isVisible={deleteModalVisible}
        setIsVisible={setDeleteModalVisible}
      />
      <ConditionalModal
        title={conditionalModalProps.title}
        description={conditionalModalProps.description}
        actionButtonOnClick={conditionalModalProps.actionButtonOnClick}
        actionButtonLabel={conditionalModalProps.actionButtonLabel}
        onCancel={conditionalModalProps.onCancel}
        isVisible={conditionalModalVisible}
        setIsVisible={setConditionalModalVisible}
      />
      <FormModal
        title={formModalProps.title}
        description={formModalProps.description}
        formFields={formModalProps.formFields}
        actionButtonOnClick={formModalProps.actionButtonOnClick}
        actionButtonLabel={formModalProps.actionButtonLabel}
        actionButtonTestId={formModalProps.actionButtonTestId}
        onCancel={formModalProps.onCancel}
        isVisible={formModalVisible}
        setIsVisible={setFormModalVisible}
      />
      <NewBuildConfirmationModal
        title={newBuildConfirmationModalProps.title}
        description={newBuildConfirmationModalProps.description}
        actionButtonOnClick={newBuildConfirmationModalProps.actionButtonOnClick}
        actionButtonLabel={newBuildConfirmationModalProps.actionButtonLabel}
        actionButtonTestId={newBuildConfirmationModalProps.actionButtonTestId}
        onCancel={newBuildConfirmationModalProps.onCancel}
        isVisible={newBuildConfirmationModalVisible}
        setIsVisible={setNewBuildConfirmationModalVisible}
      />
      <CommercialConfirmationModal
        title={commercialConfirmationModalProps.title}
        description={commercialConfirmationModalProps.description}
        actionButtonOnClick={commercialConfirmationModalProps.actionButtonOnClick}
        actionButtonLabel={commercialConfirmationModalProps.actionButtonLabel}
        actionButtonTestId={commercialConfirmationModalProps.actionButtonTestId}
        onCancel={commercialConfirmationModalProps.onCancel}
        isVisible={commercialConfirmationModalVisible}
        setIsVisible={setCommercialConfirmationModalVisible}
      />
      <MediaViewModal
        mediaMap={mediaViewModalProps.mediaMap}
        mediaOrder={mediaViewModalProps.mediaOrder}
        initialSelectedId={mediaViewModalProps.initialSelectedId}
        isVisible={mediaViewModalVisible}
        setIsVisible={setMediaViewModalVisible}
      />
      <ImportModal
        title={importModalProps.title}
        description={importModalProps.description}
        actionButtonLabel={importModalProps.actionButtonLabel}
        actionButtonOnClick={importModalProps.actionButtonOnClick}
        onCancel={importModalProps.onCancel}
        isVisible={importModalVisible}
        setIsVisible={setImportModalVisible}
      />
      <DesignServicesAutomationConfirmationModal
        title={designServicesAutomationConfirmationModalProps.title}
        description={designServicesAutomationConfirmationModalProps.description}
        actionButtonOnClick={designServicesAutomationConfirmationModalProps.actionButtonOnClick}
        actionButtonLabel={designServicesAutomationConfirmationModalProps.actionButtonLabel}
        actionButtonTestId={designServicesAutomationConfirmationModalProps.actionButtonTestId}
        onCancel={designServicesAutomationConfirmationModalProps.onCancel}
        isVisible={designServicesAutomationConfirmationModalVisible}
        setIsVisible={setDesignServicesAutomationConfirmationModalVisible}
      />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
