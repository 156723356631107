import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { ProjectDesignData } from 'types';

import { projectDesignCreated, resetSubmittedForm } from 'state/slices/projectDesignsSlice';
import { AppDispatch, RootState } from 'state/store';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';

import { FORM_CONTROLS, initialValues } from '../initialValues';
import { validationSchema } from '../validationSchema';
import './CreateNewDesignModal.scss';

const CreateNewDesignModal = ({
  designs,
  isModalVisible,
  handleModalClose,
}: {
  designs: ProjectDesignData[];
  isModalVisible: boolean;
  handleModalClose: () => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { project } = useSelector((state: RootState) => state.project);
  const { isRequestPending, isRequestSuccessful } = useSelector(
    (state: RootState) => state.projectDesigns
  );
  const { t } = useTranslation();

  const { touched, isValid, errors, dirty, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues,
    validationSchema: validationSchema(designs),
    onSubmit: (values) => {
      if (project?.id) {
        dispatch(
          projectDesignCreated({
            projectId: project.id,
            remote: false,
            ...values,
          })
        );
      }
    },
  });

  const handleModalVisibility = useCallback(() => {
    handleModalClose();
  }, [handleModalClose]);

  useEffect(() => {
    if (isRequestSuccessful) {
      handleModalVisibility();
      dispatch(resetSubmittedForm());
    }
    return () => {
      resetForm();
    };
  }, [dispatch, handleModalVisibility, isRequestSuccessful, resetForm]);

  const isFormValid = isValid && dirty;

  return (
    /* @ts-ignore*/
    <Modal
      visible={isModalVisible}
      onCancel={handleModalVisibility}
      className="CreateNewDesignModal"
      {...MODAL_PROPS}
    >
      <span className="CreateNewDesignModal-Title">{t('NewDesignModal.createNewDesign')}</span>
      <form onSubmit={handleSubmit}>
        <div className="FormControl-Wrapper">
          <label htmlFor="designName">{t('NewDesignModal.designName')}</label>
          <Input
            placeholder={t('NewDesignModal.placeholder') ?? ''}
            className={validateStatus(touched, errors, FORM_CONTROLS.NAME, dirty)}
            type={FORM_CONTROLS.NAME}
            {...getFieldProps(FORM_CONTROLS.NAME)}
            id="designName"
            autoFocus
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FORM_CONTROLS.NAME, dirty)}
          </div>
        </div>
        <div className="CreateNewDesignModal-ButtonWrapper">
          <Button onClick={handleModalClose} className="Button--White">
            Cancel
          </Button>
          <Button
            disabled={!isFormValid}
            aria-disabled={!isFormValid}
            htmlType="submit"
            className="Button--Blue"
            loading={isRequestPending}
            autoFocus={isFormValid}
          >
            {t('NewDesignModal.button')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateNewDesignModal;
