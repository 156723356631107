import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Button, Modal, ModalProps, Spin, Table } from 'antd';

import { GoBackButton } from 'components';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { accountMenuRoute } from 'helpers/constants/routes';
import { useHistory } from 'react-router-dom';

import confirm from 'antd/lib/modal/confirm';

import {
  createApiKey,
  deleteApiKey,
  getCurrentUserApiKeys,
  RequestStatus,
  selectApiKeyGetStatus,
  selectCurrentUsersApiKeys,
} from 'state/slices/apiKeyDisplay';
import { RootState } from 'state/store';

import { ModalContext } from 'components';
import { ACCESS } from 'helpers/constants/access';
import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import { openNotification } from 'helpers/utils/openNotification';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { expandedRowRenderContent } from 'screens/AccessTokens/TableData';
import TokenDisplayModal from 'screens/AccessTokens/TokenDisplayModal';

import type { AppDispatch } from 'state/store';
import AccessTokenStyle from './AccessTokenStyle';
import { tableColumnData, TableDataRecord } from './TableData';
interface ModalProp extends ModalProps {
  role: string;
  'aria-modal': boolean;
  children: React.ReactNode;
}

export interface TableRecordData {
  id: string;
  name?: string;
  accessTokens?: Record<string, string>[];
  companyName?: string;
  data?: TableDataRecord;
  companyId?: string;
  record: string;
}

const AccessTokens: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const selfServeAccessTokensAccess = useFeatureToggle(ACCESS.SELF_SERVE_ACCESS_TOKENS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tokenDisplayModalVisible, setTokenDisplayModalVisible] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const { displayInputModal } = useContext(ModalContext);

  const apiKeyGetRequestStatus = useSelector((state: RootState) => selectApiKeyGetStatus(state));
  const apiKeys = useSelector((state: RootState) => selectCurrentUsersApiKeys(state));
  const totalCount = apiKeys.length;
  const tableData: TableDataRecord[] = apiKeys.map((apiKey, index) => {
    return {
      ...apiKey,
      title: apiKey.name,
      name: apiKey.name,
      dataIndex: `${index}`,
      accessToken: [
        {
          id: apiKey.id,
          tokenPrefix: apiKey.keyPrefix,
          createdAt: apiKey.createdAt.toString(),
        },
      ],
    };
  });

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUserApiKeys());
  }, [dispatch]);

  useEffect(() => {
    if (apiKeyGetRequestStatus.status === RequestStatus.inProgress) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [apiKeyGetRequestStatus.status]);

  const handleDelete = (token: TableRecordData) => {
    confirm({
      title: t('AccessTokens.tokenDeletePromptTitle'),
      content: t('AccessTokens.tokenDeletePromptDescription', { name: token.name }),
      okButtonProps: { style: { backgroundColor: colors.red } },
      okText: t('AccessTokens.tokenDeleteButton'),
      onOk: () => handleDeleteToken(token),
      ...CONFIRM_PROPS,
    });
  };

  const handleDeleteToken = async (token: TableRecordData) => {
    try {
      await dispatch(deleteApiKey(token.id));
      openNotification({
        type: 'success',
        title: t('AccessTokens.tokenDeleteSuccessNotificationTitle'),
        text: t('AccessTokens.tokenDeleteSuccessNotificationText'),
      });
    } catch (error) {
      console.error('Error deleting access token:', error);
      openNotification({
        type: 'error',
        title: t('AccessTokens.tokenDeleteErrorNotificationTitle'),
        text: t('AccessTokens.tokenDeleteErrorNotificationText'),
      });
    }
  };

  const onCreateTokenSubmit = useCallback(
    async (input: string) => {
      const newToken = await dispatch<any>(createApiKey(input));
      openNotification({
        type: 'success',
        title: t('AccessTokens.tokenCreateSuccessNotificationTitle'),
        text: t('AccessTokens.tokenCreateSuccessNotificationText'),
      });
      setAccessToken(newToken.key);
      setTokenDisplayModalVisible(true);
    },
    [dispatch, t]
  );
  const onCreateTokenClick = useCallback(() => {
    displayInputModal({
      title: t('AccessTokens.generateToken'),
      description: t('AccessTokens.generateTokenDescription'),
      placeholderText: t('AccessTokens.generateTokenInputPlaceholder'),
      actionButtonOnClick: onCreateTokenSubmit,
      actionButtonLabel: t('AccessTokens.generateTokenCreateButton'),
      onCancel: () => setIsLoading(false),
    });
  }, [displayInputModal, onCreateTokenSubmit, t]);

  const handleCloseTokenDisplayModal = () => {
    setTokenDisplayModalVisible(false);
    setAccessToken(null);
  };

  const MODAL_PROPS: ModalProp = {
    role: 'dialog',
    'aria-modal': true,
    destroyOnClose: true,
    centered: true,
    children: (
      <TokenDisplayModal
        handleModalClose={handleCloseTokenDisplayModal}
        accessToken={accessToken}
      />
    ),
  };

  useEffect(() => {
    if (!selfServeAccessTokensAccess) {
      history.push(accountMenuRoute());
    }
  }, [history, selfServeAccessTokensAccess]);

  if (!selfServeAccessTokensAccess) {
    return null;
  }

  return (
    <AccessTokenStyle>
      <Modal
        visible={tokenDisplayModalVisible}
        onCancel={handleCloseTokenDisplayModal}
        title={t('AccessTokens.generatedTokenModalTitle')}
        footer={null}
        {...MODAL_PROPS}
      />
      <div className="AccessToken-Wrapper">
        <div className="AccessToken-SubHeader">
          <GoBackButton />
          <p data-testid="token-count">{t('AccessTokens.tokenCount', { totalCount })}</p>
          <Button className="AccessToken--Blue" onClick={onCreateTokenClick}>
            <PlusIcon aria-hidden="true" />
            {t('AccessTokens.newAccessToken')}
          </Button>
        </div>
        <Table
          className="AccessToken-Table"
          columns={tableColumnData()}
          dataSource={tableData}
          rowKey="id"
          showHeader={!!tableData.length}
          scroll={{ x: 'auto', y: 'calc(100vh - 33rem)' }}
          expandable={{
            expandedRowRender: (record) => expandedRowRenderContent({ data: record, handleDelete }),
          }}
          loading={{
            indicator: (
              <div className="AccessToken-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isLoading,
          }}
        />
      </div>
    </AccessTokenStyle>
  );
};

export default AccessTokens;
