import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DESIGN_STATUS, TIER_LEVEL } from '@cpm/scanifly-shared-data';
import { ServiceRequest } from 'types';

import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { createDesignServiceRequest } from 'api/designServices/designServicesService';

import { openNotification } from 'helpers/utils/openNotification';

import breakpoints from 'helpers/constants/breakpoints';
import styled from 'styled-components';
import ActionButtons from '../ActionButtons/ActionButtons';
import SelectDesignOptions from '../DesignSteps/SelectDesignOptions';
import FormWrapper from '../FormWrapper/FormWrapper';

const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    min-width: 90%;
  }
`;

const SelectWrapper = ({
  stepNumber,
  handleResponse,
  upgrade = false,
}: {
  stepNumber: number;
  handleResponse: (
    data: Partial<ServiceRequest> & { stepNumber: number; projectId?: string }
  ) => void;
  upgrade?: boolean;
}) => {
  const [selected, setSelected] = useState<{ tierLevel: TIER_LEVEL; isExpedited: boolean } | null>(
    null
  );
  const [formsState, setFormsState] = useState('initial');
  const [selectedDesignInfo, setSelectedDesignInfo] = useState<
    Partial<ServiceRequest> & { projectId?: string }
  >({});
  const [isOrderButtonDisabled, setOrderButtonDisabled] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();
  const { project } = useSelector((state: RootState) => state.project);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!project && projectId) {
      if (projectId) {
        dispatch(projectRequested(projectId));
      }
    }
  }, [dispatch, project, projectId]);

  const handleDesignSelection = (selectedItem: { tierLevel: TIER_LEVEL; isExpedited: boolean }) => {
    setSelected(selectedItem);
  };

  const handleCancel = () => {
    setSelectedDesignInfo({});
    setSelected(null);
  };

  const handleWireframeOrder = () => {
    if (isOrderButtonDisabled) {
      return;
    }

    setOrderButtonDisabled(true);

    const values = {
      projectId,
      status: DESIGN_STATUS.ordered,
      tierLevel: selectedDesignInfo.tierLevel as TIER_LEVEL,
    };

    if (projectId) {
      createDesignServiceRequest(values)
        .then((response) => {
          handleResponse(response.data);
        })
        .catch((err) => {
          if (err.response?.status === 402) {
            openNotification({
              type: 'error',
              title: 'Error!',
              text: 'Insufficient credits.',
            });
          }
        })
        .finally(() => setOrderButtonDisabled(false));
    }
  };

  const StepComponents = Object.freeze({
    1: (
      <SelectDesignOptions
        selected={selected}
        handleSelection={handleDesignSelection}
        setSelectedDesignInfo={setSelectedDesignInfo}
        projectId={projectId}
        upgrade={upgrade}
        actionButtons={
          <ActionButtons
            {...selectedDesignInfo}
            isDesignSelectionPage={true}
            stepNumber={stepNumber}
            handleResponse={handleResponse}
            handleCancel={handleCancel}
            handleSubmit={handleWireframeOrder}
            setSelected={setSelected}
            upgrade={upgrade}
          />
        }
      />
    ),
    2: (
      <FormWrapper
        setFormsState={setFormsState}
        formsState={formsState}
        selectedDesignInfo={selectedDesignInfo}
        isDesignSelectionPage={true}
        stepNumber={stepNumber}
        handleResponse={handleResponse}
        handleCancel={handleCancel}
        upgrade={upgrade}
        isExpedited={selectedDesignInfo?.isExpedited}
      />
    ),
  });

  return (
    <StyledSelectWrapper>
      {StepComponents[stepNumber as keyof typeof StepComponents]}
    </StyledSelectWrapper>
  );
};

export default SelectWrapper;
