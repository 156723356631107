import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Button, Tooltip } from 'antd';
import cn from 'classnames';
import { Project, User } from 'types';

import { RootState } from 'state/store';

import {
  adminFetchAllProjects,
  adminFetchCompanyProjects,
  adminFetchScaniflyProjects,
  adminFetchUserProjects,
} from 'api/admin/adminService';
import {
  fetchAllCreditTransactionsForCompany,
  fetchAllCreditTransactionsForMonth,
  fetchCompanyProjectsForDesignServiceQueue,
  fetchProjectsForDesignServiceQueue,
} from 'api/designServices/designServicesService';
import { fetchProjectsForCSVExport } from 'api/projects/projectsService';

import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import usePermissions from 'helpers/hooks/usePermissions';
import { populateHeaders } from 'helpers/utils/populateHeaders';
import { createFileName } from './helpers/createFileName';
import { getTooltipTitle } from './helpers/getTooltipTitle';
import { handleNotifications } from './helpers/handleNotifications';

import { ReactComponent as LargeArrow } from 'assets/icons/arrow-large.svg';

import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

type DataType =
  | {
      items: unknown[];
    }[]
  | Project[]
  | User[]
  | {
      name: string;
      [year: number]: number;
    }[];

type Props = {
  tableName: string;
  prepareCSVData: Function;
  columnTitles: { title: string; dataIndex: string }[];
  dataSource?: DataType;
  month?: number;
  year?: number;
  searchTerm?: string;
  filterQuery?: string;
  companyName?: string;
  additionalStyle?: string;
  companyId?: string;
  userId?: string;
};

const CSVExportButton = ({
  tableName,
  prepareCSVData,
  columnTitles = [],
  dataSource = [],
  month = undefined,
  year = undefined,
  searchTerm = '',
  filterQuery = '',
  companyName = '',
  additionalStyle = '',
  companyId,
  userId,
}: Props) => {
  const headers = filterQuery.length
    ? populateHeaders(columnTitles).filter((item: { label: string }) => item.label !== filterQuery)
    : populateHeaders(columnTitles);
  const [csvData, setCsvData] = useState([]);
  const csvInstance = useRef<(CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const { company } = useSelector((state: RootState) => state.company);
  const { totalProjects } = useSelector((state: RootState) => state.projects);
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { designServiceQueueProjectList, designServiceQueueCompanyProjectList } = useSelector(
    (state: RootState) => state.designServicesQueue
  );
  const { creditTransactions, allCreditTransactionsByMonth } = useSelector(
    (state: RootState) => state.orderDesignServiceCredits
  );
  const { foldersAccess } = currentUser || {};
  const { isScaniflyAdmin, isDesignServiceProvider } = usePermissions();
  const isMyProjectsPage = tableName === TABLE_NAMES.MY_PROJECTS_PAGE;
  const isDesignServicesQueue = tableName === TABLE_NAMES.DESIGN_SERVICES_QUEUE;
  const isCompanyDesignServicesQueue = tableName === TABLE_NAMES.COMPANY_DESIGN_SERVICES_QUEUE;
  const isCreditsHistoryPage = tableName === TABLE_NAMES.CREDIT_HISTORY;
  const isAllCompaniesCreditsHistoryPage =
    tableName === TABLE_NAMES.CREDIT_HISTORY_FOR_ALL_COMPANIES;
  const isCompanyProjectsPage = tableName === TABLE_NAMES.CUSTOMER_PROJECTS;
  const isUserProjectsPage = tableName === TABLE_NAMES.USER_PROJECTS;
  const isAdminAllProjectsPage = tableName === TABLE_NAMES.PROJECTS;
  const isUploadQueuePage = tableName === TABLE_NAMES.UPLOAD_QUEUE;

  const droneDataScoreAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DRONE_DATA_SCORE,
    company?.pricingTier
  );

  // NOTE:
  // For full folder access (access to all folders available), the folderAccess value is null
  // For no folder access the folderAccess value is []
  const isDisabled = () => {
    if (isMyProjectsPage) {
      return foldersAccess?.length === 0 || !totalProjects;
    }
    if (isDesignServicesQueue) {
      return designServiceQueueProjectList?.length === 0;
    }
    if (isCompanyDesignServicesQueue) {
      return designServiceQueueCompanyProjectList?.length === 0;
    }
    if (isCreditsHistoryPage) {
      return creditTransactions?.items?.length === 0;
    }
    if (isAllCompaniesCreditsHistoryPage) {
      return allCreditTransactionsByMonth?.items?.length === 0;
    }
    return !dataSource.length;
  };

  const handleClick = () => {
    if (isMyProjectsPage && companyId) {
      setIsLoading(true);
      fetchProjectsForCSVExport(companyId)
        .then(({ data }) => {
          const csvData = prepareCSVData({
            dataSource: data,
            searchTerm,
            month,
            year,
            droneDataScoreAccess,
            tableName,
          });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else if (isDesignServicesQueue || isCompanyDesignServicesQueue) {
      setIsLoading(true);
      const dspCompanyId =
        isDesignServiceProvider && currentUser ? currentUser.companyId : undefined;
      (isDesignServicesQueue
        ? fetchProjectsForDesignServiceQueue(
            1,
            Number.POSITIVE_INFINITY,
            undefined,
            undefined,
            dspCompanyId
          )
        : fetchCompanyProjectsForDesignServiceQueue({
            pageIndex: 1,
            size: Number.POSITIVE_INFINITY,
            sortBy: undefined,
            filterBy: undefined,
            companyId: companyId,
          })
      )
        .then(({ data }) => {
          const csvData = prepareCSVData({ dataSource: data.items, tableName });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else if (isCreditsHistoryPage && companyId) {
      setIsLoading(true);
      fetchAllCreditTransactionsForCompany(
        companyId,
        1,
        Number.POSITIVE_INFINITY,
        undefined,
        undefined
      )
        .then(({ data }) => {
          const csvData = prepareCSVData({ dataSource: data.items, tableName });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else if (isAllCompaniesCreditsHistoryPage && month !== undefined && year) {
      setIsLoading(true);
      fetchAllCreditTransactionsForMonth({
        month,
        year,
        pageIndex: 1,
        size: Number.POSITIVE_INFINITY,
      })
        .then(({ data }) => {
          const csvData = prepareCSVData({ dataSource: data.items, tableName });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else if (isUploadQueuePage) {
      setIsLoading(true);
      adminFetchScaniflyProjects(Number.POSITIVE_INFINITY, 1)
        .then(({ data }) => {
          const csvData = prepareCSVData({ dataSource: data.items, tableName });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else if (isAdminAllProjectsPage) {
      setIsLoading(true);
      adminFetchAllProjects(Number.POSITIVE_INFINITY, 1)
        .then(({ data }) => {
          const csvData = prepareCSVData({ dataSource: data.items, tableName });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else if (companyId && isCompanyProjectsPage) {
      setIsLoading(true);
      adminFetchCompanyProjects(companyId, Number.POSITIVE_INFINITY, 1)
        .then(({ data }) => {
          const csvData = prepareCSVData({ dataSource: data.items, tableName });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else if (userId && isUserProjectsPage) {
      setIsLoading(true);
      adminFetchUserProjects(userId, Number.POSITIVE_INFINITY, 1)
        .then(({ data }) => {
          const csvData = prepareCSVData({ dataSource: data.items, tableName });
          handleNotifications(false);
          setCsvData(csvData);
          setTimeout(() => {
            csvInstance?.current?.link.click();
          }, 100);
        })
        .catch(() => {
          handleNotifications(true);
        })
        .finally(() => setIsLoading(false));
    } else {
      const data = prepareCSVData({ dataSource, searchTerm, tableName, month, year });
      setCsvData(data);
      handleNotifications(false);
      setTimeout(() => {
        csvInstance?.current?.link.click();
      }, 100);
    }
  };

  return (
    <>
      <CSVLink
        filename={createFileName({ tableName, companyName, month, year })}
        headers={headers}
        data={csvData}
        ref={csvInstance}
        asyncOnClick={true}
        onClick={(_event, done) => {
          if (csvData && csvData.length) {
            done();
            setTimeout(() => {
              setCsvData([]);
            }, 100);
          } else {
            done(false);
          }
        }}
      >
        {
          <ConditionalWrapper
            condition={isDisabled()}
            wrapper={(children) => (
              <Tooltip title={getTooltipTitle({ tableName, foldersAccess })}>{children}</Tooltip>
            )}
          >
            <Button
              onClick={(_event) => handleClick()}
              className={cn(['Button--Blue', additionalStyle])}
              loading={isLoading}
              disabled={isDisabled()}
              data-testid="csv-export-button"
            >
              <LargeArrow />
              CSV Export
            </Button>
          </ConditionalWrapper>
        }
      </CSVLink>
    </>
  );
};

export default CSVExportButton;
