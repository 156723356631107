import { FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Alert, Input, Row } from 'antd';
import { MAP_ZOOM } from 'screens/MapWrapper/constants';
import { MapViewport } from 'screens/MapWrapper/types';
import { Coordinates } from 'viewport-mercator-project';

import { RootState } from 'state/store';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { forwardGeocode } from 'helpers/utils/mapbox';

import Geocoder from '../../../../util/Geocoder';
import { DEFAULT_COUNTRY } from '../constants';
import { FormFields } from '../types';
import { CustomerInfoAddressInput, CustomerInfoAddressItem } from './CustomerInfoAddressInput';
import {
  CustomerInfoAddressInputProps,
  CustomerInfoAddressItemProps,
} from './CustomerInfoAddressInput/types';
import './CustomerInfoForm.scss';
import { CustomerInfoFormProps, GeocoderInputComponentProps } from './types';

const CustomerInfoForm = ({
  touched,
  errors,
  setFieldValue,
  handleBlur,
  getFieldProps,
  viewport,
  setViewport,
  setPin,
  pin,
  values,
  isManualPinSetting,
  setIsManualPinSetting,
}: CustomerInfoFormProps) => {
  const { t } = useTranslation();
  const { company } = useSelector((state: RootState) => state.company);

  const [proximityParams, setProximityParams] = useState<{
    proximity: Coordinates;
  } | null>(null);
  const [isAddressTouched, setIsAddressTouched] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [isAddressStartingOrEndingWithSpace, setIsAddressStartingOrEndingWithSpace] =
    useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [initialAddress, setInitialAddress] = useState('');
  const mountedRef = useRef(true);

  const { projectId } = useParams<{ projectId: string }>();

  const isManualPinSettingRequired =
    !values.longitude &&
    !values.latitude &&
    isAddressTouched &&
    isAddressValid &&
    !isAddressSelected &&
    !isAddressStartingOrEndingWithSpace;

  const getProximityData = useCallback(async (address: string) => {
    const data = await forwardGeocode(address);
    const features = data?.features;
    if (!mountedRef.current || !features || !features.length) {
      return;
    }
    const closestLocation = features[0];
    setProximityParams({
      proximity: closestLocation.center,
    });
  }, []);

  useEffect(() => {
    if (isManualPinSettingRequired) {
      setIsManualPinSetting(true);
    }

    if (!values.address) {
      setIsManualPinSetting(false);
    }

    if (values.address) {
      setInitialAddress(values.address);
      getProximityData(values.address);
    }
  }, [
    isAddressTouched,
    isAddressValid,
    isManualPinSettingRequired,
    projectId,
    setIsManualPinSetting,
    values.address,
    getProximityData,
  ]);

  useEffect(() => {
    // Cleanup asynchronous getProximityData task to avoid memory leak
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!company || values.address || proximityParams) {
      return;
    }
    const { line, city, zip } = company.address;
    // Recommended address geocoding format for handling addresses in multiple countries
    // See Mapbox docs: https://docs.mapbox.com/help/troubleshooting/address-geocoding-format-guide/
    const addressString = `${line} ${zip} ${city}`;
    getProximityData(addressString);
  }, [company, proximityParams, getProximityData, values.address]);

  useEffect(() => {
    setIsAddressTouched(false);
    setIsAddressValid(false);
  }, [projectId]);

  const handleAddressBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    const { value } = event.target;
    const isAddressEmpty = value.length === 0;
    const startsOrEndsWithSpace = value.startsWith(' ') || value.endsWith(' ');

    setIsAddressValid(!isAddressEmpty);
    setIsAddressStartingOrEndingWithSpace(startsOrEndsWithSpace);

    if (!isAddressTouched) {
      setIsAddressTouched(true);
    }

    if (!isAddressSelected && !isAddressEmpty) {
      setPin({
        latitude: null,
        longitude: null,
      });
    }
  };

  const handlePhoneNumberChange = (_value: any, _data: any, _event: any, formattedValue: any) => {
    setFieldValue(FormFields.phoneNumber, formattedValue);
  };

  const handlePhoneNumberBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    const { value } = event.target;
    // @ts-ignore the type for handleBlur is wrong or this doesn't work
    handleBlur(FormFields.phoneNumber, value);
  };

  const onGeocoderSelected = (viewport: MapViewport, item: any) => {
    setIsAddressSelected(true);
    setIsAddressValid(true);
    setViewport(viewport);
    setFieldValue(FormFields.address, item.place_name);
    setFieldValue(FormFields.latitude, item.center[1]);
    setFieldValue(FormFields.longitude, item.center[0]);
    setPin({
      latitude: item.center[1],
      longitude: item.center[0],
    });
  };

  return (
    <div className="CustomerInfoForm">
      <p className="CustomerInfoForm--Required">* {t('error.requiredField')}</p>
      <div className="CustomerInfoForm-FormWrapper">
        <div className="FormControl-Wrapper">
          <label htmlFor="address">{t('ProjectCustomerInfo.address')}*</label>
          <Geocoder
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? ''}
            onSelected={onGeocoderSelected}
            hideOnSelect
            viewport={viewport}
            inputComponent={(
              props: GeocoderInputComponentProps<Partial<CustomerInfoAddressInputProps>>
            ) =>
              CustomerInfoAddressInput({
                props,
                handleAddressBlur,
                setIsAddressSelected,
                setIsAddressStartingOrEndingWithSpace,
                setIsAddressTouched,
                setIsAddressValid,
                setPin,
                pin,
                setViewport,
                isAddressStartingOrEndingWithSpace,
                isAddressTouched,
                isAddressValid,
                getFieldProps,
                setFieldValue,
                address: values.address,
              })
            }
            itemComponent={(props: CustomerInfoAddressItemProps) => (
              <CustomerInfoAddressItem {...props} />
            )}
            pointZoom={MAP_ZOOM}
            initialInputValue={initialAddress}
            queryParams={proximityParams}
          />

          <div className="Form-Error">
            {isAddressTouched && !isAddressValid && 'Address is required'}
            {isAddressTouched && isAddressStartingOrEndingWithSpace && t('error.noSpaces')}
          </div>
        </div>
      </div>

      {(isManualPinSettingRequired || isManualPinSetting) && (
        <Alert
          className="CustomerInfoForm-ManualPinSetInfo"
          type="info"
          message={t('alertMessages.addressNotLocated')}
        />
      )}

      <Row className="CustomerInfoForm-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="latitude">{t('ProjectCustomerInfo.latitude')}</label>
          <Input
            placeholder="40.4729873"
            disabled
            {...getFieldProps(FormFields.latitude)}
            id="latitude"
            aria-disabled="true"
          />
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="longitude">{t('ProjectCustomerInfo.longitude')}</label>
          <Input
            placeholder="-70.4729873"
            disabled
            aria-disabled="true"
            id="longitude"
            {...getFieldProps(FormFields.longitude)}
          />
        </div>
      </Row>

      <Row className="CustomerInfoForm-Form-Row">
        <div className="FormControl-Wrapper">
          <label htmlFor="firstName">{t('ProjectCustomerInfo.firstName')}</label>
          <Input
            placeholder="John"
            className={validateStatus(touched, errors, FormFields.firstName)}
            id="firstName"
            {...getFieldProps(FormFields.firstName)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FormFields.firstName)}
          </div>
        </div>

        <div className="FormControl-Wrapper">
          <label htmlFor="lastName">{t('ProjectCustomerInfo.lastName')}</label>
          <Input
            placeholder="Doe"
            id="lastName"
            className={validateStatus(touched, errors, FormFields.lastName)}
            {...getFieldProps(FormFields.lastName)}
          />
          <div className="Form-Error">
            {renderValidationMessage(touched, errors, FormFields.lastName)}
          </div>
        </div>
      </Row>

      <div className="CustomerInfoForm-Form-Row FormControl-Wrapper">
        <label htmlFor="email">{t('ProjectCustomerInfo.email')}</label>
        <Input
          placeholder="john.doe@scanifly.com"
          className={validateStatus(touched, errors, FormFields.email)}
          id="email"
          type="email"
          {...getFieldProps(FormFields.email)}
        />
        <div className="Form-Error">
          {renderValidationMessage(touched, errors, FormFields.email)}
        </div>
      </div>

      <div className="FormControl-Wrapper">
        <label htmlFor="phone">{t('ProjectCustomerInfo.phone')}</label>
        <PhoneInput
          countryCodeEditable={false}
          {...getFieldProps(FormFields.phoneNumber)}
          country={DEFAULT_COUNTRY}
          onChange={handlePhoneNumberChange}
          onBlur={handlePhoneNumberBlur}
        />
      </div>
    </div>
  );
};

export default CustomerInfoForm;
