import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Modal, Tooltip } from 'antd';
import DeleteTeammateModal from 'screens/Team/TeamList/DeleteTeammateModal';
import { User } from 'types';

import styled from 'styled-components';

import { adminDeleteUser } from 'state/slices/admin/adminUsersSlice';
import { AppDispatch } from 'state/store';

import { InviteOrChangeTeammatesPermissions } from 'components';

import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import { MODAL_PROPS } from 'helpers/constants/modals';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as PencilIcon } from 'assets/icons/pencil-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-icon.svg';

const StyledContainer = styled.div`
  display: flex;
`;

const StyledButton = styled.button`
  width: 2.55rem;
  height: 2.55rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 !important;
  }

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    margin-left: 1rem;
  }

  &:first-of-type {
    &:hover {
      svg path {
        fill: ${colors.mainBlue};
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.lg}) {
    margin-left: 0.5rem;
  }
`;

const EditDeleteButton = ({ record }: { record: User }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const onDeletionSuccess = (userId: string, firstName?: string, lastName?: string) => {
    const title =
      firstName && lastName
        ? t('AdminConsoleUserManager.userRemovedWithName', {
            username: `${firstName} ${lastName}`,
          })
        : t('AdminConsoleUserManager.userRemoved');

    const text =
      firstName && lastName
        ? t('AdminConsoleUserManager.userRemoved')
        : t('AdminConsoleUserManager.userRemovedWithId', {
            userId: userId,
          });

    return openNotification({
      type: 'success',
      title: title,
      text: text,
    });
  };

  const handleDeleteModalClose = () => {
    setDeleteModalVisible(false);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalVisible(true);
  };

  const handleEditModalClose = () => {
    setEditModalVisible(false);
  };

  const handleEditModalOpen = () => {
    setEditModalVisible(true);
  };

  const handleDeleteUser = (user: User) => {
    if (user.id) {
      dispatch(
        adminDeleteUser({
          userId: user.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          onDeletionSuccess,
        })
      );
      setDeleteModalVisible(true);
    }
  };

  return (
    <StyledContainer>
      {/* @ts-ignore antd is not compatible with @types/react v18 */}
      <Modal visible={editModalVisible} onCancel={handleEditModalClose} {...MODAL_PROPS}>
        <InviteOrChangeTeammatesPermissions
          handleModalClose={handleEditModalClose}
          modalClosedFromOutside={false}
          setModalClosedFromOutside={setEditModalVisible}
          isEditModal={true}
          isAddTeammate={false}
          teammate={record}
          companyId={record.companyId}
        />
      </Modal>
      <Tooltip placement="top" title={t('AdminConsoleUserManager.editUser')}>
        <StyledButton
          onClick={handleEditModalOpen}
          className="Button--White"
          aria-label={t('AdminConsoleUserManager.editUser')}
          id={`edit-modal-${record.id}`}
        >
          <PencilIcon />
        </StyledButton>
      </Tooltip>
      {/* @ts-ignore antd is not compatible with @types/react v18 */}
      <Modal
        visible={deleteModalVisible}
        onCancel={handleDeleteModalClose}
        title={t('AdminConsoleUserManager.removeUser')}
        {...MODAL_PROPS}
      >
        <DeleteTeammateModal
          teammate={record}
          handleModalClose={handleDeleteModalClose}
          handleDelete={() => handleDeleteUser(record)}
          scaniflyAdmin
        />
      </Modal>
      <Tooltip placement="top" title={t('AdminConsoleUserManager.removeUser')}>
        <StyledButton
          onClick={handleDeleteModalOpen}
          className="Button--Red"
          aria-label={t('AdminConsoleUserManager.removeUser')}
          id={`delete-modal-${record.id}`}
        >
          <TrashIcon />
        </StyledButton>
      </Tooltip>
    </StyledContainer>
  );
};

export default EditDeleteButton;
