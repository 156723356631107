import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DESIGN_STATUS, TIER_LEVEL, TIER_LEVEL_COST_MAP } from '@cpm/scanifly-shared-data';
import { Button, Tooltip } from 'antd';
import { ServiceRequest } from 'types';

import styled from 'styled-components';

import { companySubscriptionInfoRequested, userCompanyRequested } from 'state/slices/companySlice';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { ConditionalWrapper } from 'components';

import { orderDesignRoute, upgradeDesignRoute } from 'helpers/constants/routes';
import { getOrderButtonTooltipTitle } from 'screens/DesignServices/helpers/getTooltipTitle';

import { ReactComponent as GoBackIcon } from 'assets/icons/go-back-icon.svg';

type ActionButtonsProps = {
  projectId?: string;
  status?: DESIGN_STATUS;
  tierLevel?: TIER_LEVEL;
  stepNumber: number;
  handleResponse: (
    data: Partial<ServiceRequest> & { stepNumber: number; projectId?: string }
  ) => void;
  handleCancel?: () => void;
  setSelected: (selected: { tierLevel: TIER_LEVEL; isExpedited: boolean } | null) => void;
  isDesignSelectionPage?: boolean;
  handleSubmit?: () => void;
  upgrade?: boolean;
};

const GoBack = ({ ...props }) => <GoBackIcon {...props} />;

const StyledGoBackIcon = styled(GoBack)`
  height: 1rem;
  margin-right: 0.5rem;
`;

const StyledMainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  .Button--Blue {
    button {
      height: 3.8rem !important;
      box-shadow: none;
      text-shadow: none;
      background-color: $light-gray !important;
      border: 0.1rem solid $neutral-gray !important;
      color: $white !important;
    }
  }
`;

const ActionButtons = ({
  projectId,
  status,
  tierLevel,
  isDesignSelectionPage = false,
  stepNumber,
  setSelected,
  handleResponse,
  handleCancel,
  handleSubmit,
  upgrade,
}: ActionButtonsProps) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { company, companySubscriptionInfo } = useSelector((state: RootState) => state.company);
  const { project } = useSelector((state: RootState) => state.project);
  const isOrderTypeSelected = Boolean(projectId && status && tierLevel);
  const isWireframeDesign =
    tierLevel === TIER_LEVEL.wireframe || tierLevel === TIER_LEVEL.wireframeForLarge;
  const isStepsCompleted = stepNumber === 3 || false;
  const showOrderButton = isWireframeDesign || isStepsCompleted;
  const { credits } = companySubscriptionInfo || {};
  const [userHasEnoughCredits, setUserHasEnoughCredits] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');

  const ACTION_BUTTON = {
    ORDER: 'Order',
    NEXT: 'Next',
    CANCEL: 'Cancel',
    GET_STARTED: 'Get started',
  };

  useEffect(() => {
    if (!company) {
      dispatch(userCompanyRequested());
    }
    if (company && !companySubscriptionInfo) {
      dispatch(companySubscriptionInfoRequested(company.id));
    }
  }, [company, companySubscriptionInfo, dispatch]);

  useEffect(() => {
    setUserHasEnoughCredits(
      (credits && credits?.amount >= TIER_LEVEL_COST_MAP[tierLevel as TIER_LEVEL]) ?? false
    );
  }, [credits, tierLevel, userHasEnoughCredits]);

  useEffect(() => {
    setTooltipTitle(getOrderButtonTooltipTitle({ isOrderTypeSelected, userHasEnoughCredits }));
  }, [isOrderTypeSelected, tooltipTitle, userHasEnoughCredits]);

  useEffect(() => {
    if (!project || project?.id !== projectId) {
      if (projectId) {
        dispatch(projectRequested(projectId));
      }
    }
  }, [dispatch, project, projectId]);

  const handleNext = () => {
    setSelected(null);
    if (projectId && status && tierLevel) {
      handleResponse({ projectId, status, tierLevel, stepNumber });
      if (upgrade) {
        history.push(upgradeDesignRoute(projectId, String(stepNumber)));
      } else {
        history.push(orderDesignRoute(projectId, stepNumber));
      }
    }
  };

  const handleBack = () => {
    const previousStep = stepNumber === 1 ? 1 : stepNumber - 2;
    handleResponse({ projectId, status, tierLevel, stepNumber: previousStep });
    if (upgrade) {
      history.push(upgradeDesignRoute(projectId));
    } else {
      history.push(orderDesignRoute(projectId, previousStep));
    }
  };

  return (
    <StyledMainWrapper>
      <Button
        onClick={handleBack}
        className="Button--White GoBackButton-Wrapper"
        disabled={stepNumber === 1}
        aria-disabled={stepNumber === 1}
      >
        <StyledGoBackIcon /> Back
      </Button>
      <StyledWrapper>
        {!isDesignSelectionPage ? (
          <Button
            className="Button--Cancel"
            onClick={handleCancel}
            disabled={!isOrderTypeSelected}
            aria-disabled={!isOrderTypeSelected}
          >
            {ACTION_BUTTON.CANCEL}
          </Button>
        ) : null}
        <ConditionalWrapper
          condition={!isOrderTypeSelected || !userHasEnoughCredits}
          wrapper={(children) => <Tooltip title={tooltipTitle}>{children}</Tooltip>}
        >
          {showOrderButton ? (
            <Button
              className="Button--Blue"
              onClick={handleSubmit}
              disabled={!isOrderTypeSelected || !userHasEnoughCredits}
              aria-disabled={!isOrderTypeSelected || !userHasEnoughCredits}
              data-testid="ds-order"
            >
              {ACTION_BUTTON.ORDER}
            </Button>
          ) : (
            <Button
              className="Button--Blue"
              onClick={handleNext}
              disabled={!isOrderTypeSelected || !userHasEnoughCredits}
              aria-disabled={!isOrderTypeSelected || !userHasEnoughCredits}
              data-testid="ds-get-started"
            >
              {ACTION_BUTTON.GET_STARTED}
            </Button>
          )}
        </ConditionalWrapper>
      </StyledWrapper>
    </StyledMainWrapper>
  );
};

export default ActionButtons;
