import { useDispatch } from 'react-redux';

import confirm from 'antd/lib/modal/confirm';

import { projectStatusUpdateRequested } from 'state/slices/projectsSlice';
import { AppDispatch } from 'state/store';

import { DropdownMenu, MenuMini } from 'components';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as CancelIcon } from 'assets/icons/cancel-icon.svg';

import './ProjectTile.scss';

const ProjectTileMenu = ({ name, id }: { name: string; id: string }) => {
  const [isMenuOpen, toggleMenu] = useToggle();
  const dispatch: AppDispatch = useDispatch();
  const { isSimpleDesignOrSalesManager } = usePermissions();

  const handleDelete = () => {
    confirm({
      title: 'Delete Project',
      content: 'Are you sure that you want to delete ' + name + '?',
      okText: 'Delete Project',
      okButtonProps: { style: { background: colors.red } },
      onOk: () => {
        dispatch(projectStatusUpdateRequested({ id, targetStatus: 'adminDeclined' }));
      },
      ...CONFIRM_PROPS,
    });
  };

  const handleMenuOpen = () => {
    toggleMenu();
  };

  const Dropdown = () => (
    <li>
      <button onClick={handleDelete} className="DropdownMenu-Item">
        <CancelIcon />
        Delete Project
      </button>
    </li>
  );

  if (isSimpleDesignOrSalesManager) return null;

  return (
    <div className="ProjectTile-Menu">
      <MenuMini onClick={handleMenuOpen} />
      {isMenuOpen && (
        <DropdownMenu toggleMenu={toggleMenu}>
          <Dropdown />
        </DropdownMenu>
      )}
    </div>
  );
};

export default ProjectTileMenu;
