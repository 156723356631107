import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Button } from 'antd';
import cn from 'classnames';
import MapWrapper from 'screens/MapWrapper';
import { ALL_STEPS } from 'screens/Sidebar/constants';
import { getMenuRoute } from 'screens/Sidebar/helpers';

import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { PAGE_TITLES } from 'helpers/constants/pageTitles';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftProjectOverviewRoute,
  projectsListRoute,
  rootRoute,
  scaniflyAdminDraftOverviewRoute,
} from 'helpers/constants/routes';

import { ReactComponent as AdminIcon } from 'assets/admin-icon.svg';
import { ReactComponent as BoltSolidIcon } from 'assets/bolt-solid.svg';
import { ReactComponent as FolderOpenSolidIcon } from 'assets/folder-open-solid.svg';
import { ReactComponent as UserSolidIcon } from 'assets/user-solid.svg';

import './Overview.scss';

const Overview = ({ isAdminRoute = false }: { isAdminRoute?: boolean }) => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const { isProjectRequestedLoading, project } = useSelector((state: RootState) => state.project);

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const isDraft =
    project?.statusDescription === PROJECT_STATUSES.DRAFT ||
    project?.statusDescription === PROJECT_STATUSES.NO_FLIGHT;

  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? draftProjectOverviewRoute(projectId)
        : scaniflyAdminDraftOverviewRoute(projectId),
    }),
  };

  const pins = useMemo(() => {
    return project
      ? [
          {
            location: project.geolocation,
            id: project.id,
          },
        ]
      : [];
  }, [project]);

  // On initial load request project
  useEffect(() => {
    if (!project) {
      if (!isProjectRequestedLoading && isInitialLoad) {
        dispatch(projectRequested(projectId));
        setIsInitialLoad(false);
      } else if (!isInitialLoad && !isProjectRequestedLoading) {
        history.push(projectsListRoute());
      }
    }
  }, [isInitialLoad, projectId, dispatch, project, isProjectRequestedLoading, history]);

  useEffect(() => {
    if ((projectId === project?.id && isNewProject && !isDraft) || (!isNewProject && isDraft)) {
      history.push(rootRoute());
    }
  }, [project, history, isDraft, isNewProject, projectId]);

  // This routing block is repeated often.
  // TODO: Refactor workflow routing to a single helper function
  const handleOpenCustomerInfo = () => {
    const route = getMenuRoute(ALL_STEPS.CUSTOMER_INFO, isAdminRoute, isDraft, project?.id);
    history.push(route);
  };

  const handleOpenConsumptionData = () => {
    const route = getMenuRoute(ALL_STEPS.CONSUMPTION_DATA, isAdminRoute, isDraft, project?.id);
    history.push(route);
  };

  const handleOpenScaniflyInfo = () => {
    const route = getMenuRoute(ALL_STEPS.SCANIFLY_INFO, isAdminRoute, isDraft, project?.id);
    history.push(route);
  };

  const handleOpenProjectInfo = () => {
    const route = getMenuRoute(ALL_STEPS.PROJECT_INFO, isAdminRoute, isDraft, project?.id);
    history.push(route);
  };

  const handleSaveAndExit = () => {
    handleOpenCustomerInfo();
  };

  return (
    <MapWrapper pins={pins} isClickable={false}>
      <div className="Project-Overview">
        {isAdminRoute && (
          <div className="Project-Overview-SubmittedFor">
            Submitted for: {project?.submittedFor?.firstName} {project?.submittedFor?.lastName} at{' '}
            {project?.submittedFor?.company.name}
          </div>
        )}
        <h2 className="Project-Overview-ProjectName">{project?.name}</h2>
        <div className="Project-Overview-Label">View project overview here</div>

        {/*Customer Info*/}
        <div
          role="button"
          tabIndex={0}
          className={cn('Project-Overview-CategoriesList-Category')}
          onClick={() => handleOpenCustomerInfo()}
          id={'project-overview-customer-info'}
          onKeyDown={(e) => e.key === 'Enter' && handleOpenCustomerInfo()}
        >
          <div>
            <UserSolidIcon className="Project-Overview-CategoriesList-Category-CategoryIcon" />
            <span className="Project-Overview-CategoriesList-Category-Name">
              {PAGE_TITLES.CUSTOMER_INFO}
            </span>
          </div>
        </div>

        {/*Consumption Data*/}
        <div
          role="button"
          tabIndex={0}
          className={cn('Project-Overview-CategoriesList-Category')}
          onClick={() => handleOpenConsumptionData()}
          id={'project-overview-consumption-data'}
          onKeyDown={(e) => e.key === 'Enter' && handleOpenConsumptionData()}
        >
          <div>
            <BoltSolidIcon className="Project-Overview-CategoriesList-Category-CategoryIcon" />
            <span className="Project-Overview-CategoriesList-Category-Name">
              {PAGE_TITLES.CONSUMPTION_DATA}
            </span>
          </div>
        </div>

        {/*Scanifly Info*/}
        {isAdminRoute && (
          <div
            role="button"
            tabIndex={0}
            className={cn('Project-Overview-CategoriesList-Category')}
            onClick={() => handleOpenScaniflyInfo()}
            onKeyDown={(e) => e.key === 'Enter' && handleOpenScaniflyInfo()}
            id={'project-overview-scanifly-info'}
          >
            <div>
              <AdminIcon className="Project-Overview-CategoriesList-Category-CategoryIcon" />
              <span className="Project-Overview-CategoriesList-Category-Name">
                {PAGE_TITLES.SCANIFLY_INFO}
              </span>
            </div>
          </div>
        )}

        {/*Project Info*/}
        <div
          role="button"
          tabIndex={0}
          className={cn('Project-Overview-CategoriesList-Category')}
          id={'project-overview-project-info'}
          onClick={() => handleOpenProjectInfo()}
          onKeyDown={(e) => e.key === 'Enter' && handleOpenProjectInfo()}
        >
          <div>
            <FolderOpenSolidIcon className="Project-Overview-CategoriesList-Category-CategoryIcon" />
            <span className="Project-Overview-CategoriesList-Category-Name">
              {PAGE_TITLES.PROJECT_INFO}
            </span>
          </div>
        </div>

        <div className="Project-Overview-Buttons">
          <div className="Project-Overview-Buttons-Wrapper">
            <Button onClick={handleSaveAndExit} className="Button--White">
              Next
            </Button>
          </div>
        </div>
      </div>
    </MapWrapper>
  );
};

export default Overview;
