import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'video-react/dist/video-react.css';

import { PhotoMeta } from '@cpm/scanifly-shared-data';
import { UppyFile } from '@uppy/core';
import { Button } from 'antd';
import exifr from 'exifr';
import { UPLOADING } from 'screens/Albums/ProjectCategory/constants';
import { Metadata } from 'screens/Albums/ProjectCategory/types';
import { getLayoutWidth } from 'screens/Projects/Designs/helpers';
import { SidebarContext } from 'screens/Sidebar';
import { MediaItem, SurveyMedia } from 'types';

import { projectMediaRequested } from 'state/slices/mediasSlice';
import { AppDispatch, RootState } from 'state/store';

import { ImagePanzoom, MediaCarousel, PDFViewer } from 'components';

import { ACCESS } from 'helpers/constants';
import { DRONE_IMAGES } from 'helpers/constants/categories';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import { useViewportSize } from 'helpers/hooks/useViewportSize';
import { validators } from 'helpers/utils';
import { formatFileName } from 'helpers/utils/formatFileName';
import { isPdf as checkIfPdf } from 'helpers/utils/isPdf';

import { ReactComponent as DownloadCloud } from 'assets/icons/download-cloud.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file-image-solid.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-icon.svg';

import { DownloadButtonWrapper } from 'components/ZipButton/ZipButton';
import colors from 'helpers/constants/colors';
import fontWeights from 'helpers/constants/fontWeights';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledImagePreview, StyledPlaceHolder, StyledTitleContainer } from './ImagePreview.styles';
import { isPortrait } from './ImagePreviewMeta/helpers';
import ImagePreviewMeta from './ImagePreviewMeta/ImagePreviewMeta';
import { ImagePreviewProvider } from './ImagePreviewProvider';
import { ImagePreviewProps } from './types';

export const StyledButton = styled(Button)`
  background-color: transparent;
  color: ${colors.white};
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  display: inline-block;

  svg {
    margin: 0;
  }
`;

const StyledDownloadLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: transparent;
  color: ${colors.white};
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  display: inline-block;
  font-weight: ${fontWeights.semiBold};

  &:hover {
    color: ${colors.white};
    cursor: pointer;
  }

  svg {
    margin: 0;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
`;

/**
 * Must be wrapped in <ImagePreviewProvider />
 */
const ImagePreview = ({
  image,
  setSelectedMediaItem,
  allMedia = [],
  isDroneImages = false,
}: ImagePreviewProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { width } = useViewportSize();
  const { t } = useTranslation();

  const { isSidebarCollapsed } = useContext(SidebarContext);

  const isDynamicImageResizerEnabled = useFeatureToggle(ACCESS.IS_DYNAMIC_IMAGE_RESIZER_ENABLED);

  const [metadata, setMetadata] = useState<Metadata | null | PhotoMeta>(null);
  const [isMetadataLoading, setIsMetadataLoading] = useState(false);

  const { media } = useSelector((state: RootState) => state.medias);

  const imageUrl = (image as UppyFile).data
    ? URL.createObjectURL((image as UppyFile).data)
    : media?.imgUrl || (image as MediaItem)?.imgUrl;

  const handleClose = () => {
    setSelectedMediaItem(null);
  };

  const parseMetadata = useCallback((image: ImagePreviewProps['image']) => {
    const mediaItem = image as MediaItem;
    const localMediaItem = image as UppyFile;
    if (!validators.isImage(image as MediaItem)) {
      return setMetadata(null);
    }
    if (mediaItem?.photoMeta) {
      return setMetadata({ ...mediaItem.photoMeta, ...mediaItem.geolocation });
    }
    setIsMetadataLoading(true);
    let isSubscribed = true;

    exifr
      .parse(localMediaItem?.data || mediaItem.imgUrl)
      .then((output) => {
        if (isSubscribed) {
          setIsMetadataLoading(false);
          setMetadata(output);
        }
      })
      .catch(() => {
        setIsMetadataLoading(false);
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    const mediaItem = image as MediaItem;
    const localMediaItem = image as UppyFile;
    if (!localMediaItem.data && !mediaItem.imgUrl) {
      dispatch(
        projectMediaRequested({
          id: image.id,
          mediaCategoryName: (mediaItem as SurveyMedia).mediaCategoryName || DRONE_IMAGES,
        })
      );
    }
  }, [image, dispatch]);

  useEffect(() => {
    const mediaItem = image as MediaItem;
    const localMediaItem = image as UppyFile;
    if (localMediaItem?.data || mediaItem?.imgUrl) {
      parseMetadata(image);
    }
    if (media) {
      parseMetadata(media);
    }
  }, [image, media, parseMetadata]);

  const isPdf = checkIfPdf(image);
  const isVideoOrImage = validators.isVideoOrImage(image as MediaItem);
  const isOnlyOne = allMedia.length <= 1;

  const showCarousel = !isPdf && isVideoOrImage && !isOnlyOne && imageUrl;
  return (
    <ImagePreviewProvider isDynamicImageResizerEnabled={isDynamicImageResizerEnabled}>
      <StyledImagePreview style={{ width: getLayoutWidth(width ?? 0, isSidebarCollapsed) }}>
        <StyledTitleContainer>
          <div className="title">
            <FileIcon />
            {!((image || media) as MediaItem).fileId && !isDroneImages
              ? UPLOADING
              : formatFileName((image || media) as SurveyMedia)}
          </div>
          <StyledWrapper>
            <StyledDownloadLink
              id="image-download"
              href={imageUrl}
              download
              target="_blank"
              rel="noreferrer"
              className="ImagePreview-DownloadButton"
            >
              <DownloadButtonWrapper>
                <DownloadCloud />
                <p>{t('buttonTexts.download')}</p>
              </DownloadButtonWrapper>
            </StyledDownloadLink>
            <StyledButton className="Button--Icon icon" onClick={handleClose} aria-label="close">
              <XIcon />
            </StyledButton>
          </StyledWrapper>
        </StyledTitleContainer>

        {/* PDF */}
        {isPdf && imageUrl ? <PDFViewer imageUrl={imageUrl} /> : null}

        {/* Single image or video */}
        {!isPdf && isVideoOrImage && isOnlyOne && imageUrl ? (
          <ImagePanzoom
            thumbnailUrl={(image as MediaItem).thumbnailUrl}
            imageUrl={imageUrl}
            isPortrait={isPortrait(metadata)}
          />
        ) : null}

        {/* Multiple image or video */}
        {showCarousel ? (
          <MediaCarousel
            imageUrl={imageUrl}
            isPortrait={isPortrait(metadata)}
            allMedia={allMedia}
            setSelectedMediaItem={setSelectedMediaItem}
          />
        ) : null}

        {/* Unsupported */}
        {!isPdf && !isVideoOrImage ? (
          <StyledPlaceHolder className="ImagePanzoom">
            <FileIcon className="image" />
          </StyledPlaceHolder>
        ) : null}

        {/* Meta */}
        <ImagePreviewMeta metadata={metadata} image={image} isLoading={isMetadataLoading} />
      </StyledImagePreview>
    </ImagePreviewProvider>
  );
};

export default ImagePreview;
