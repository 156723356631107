import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';
import moment from 'moment';

import { maintenanceReportRequested } from 'state/slices/maintenanceReport/maintenanceReportSlice';
import { AppDispatch, RootState } from 'state/store';

import { fullMonthDateFormat } from 'helpers/utils/dateFormat';

import { ReactComponent as ScaniflyLogo } from 'assets/scaniflylogo.svg';

import ImageDisplay from './ImageDisplay/ImageDisplay';
import InspectionNotes from './InspectionNotes/InspectionNotes';
import './PublicReportDisplay.scss';

const PublicReportDisplay = () => {
  const dispatch: AppDispatch = useDispatch();
  const { reportId } = useParams<{ reportId: string }>();
  const { t } = useTranslation();

  const { report, isReportLoading } = useSelector((state: RootState) => state.maintenanceReport);

  useEffect(() => {
    if (reportId) {
      dispatch(maintenanceReportRequested({ reportId }));
    }
  }, [dispatch, reportId]);

  if (isReportLoading || report?.id !== reportId) {
    return (
      <article className="PublicReportDisplay">
        <div className="PublicReportDisplay-Spinner">
          <Spin size="large" data-testid="maintenance-report-spinner" />
        </div>
      </article>
    );
  }

  const {
    companyLogo,
    createdAt,
    project,
    createdBy,
    summary,
    weatherConsiderations,
    issues,
    recommendedFixesAndImprovements,
    allPhotosImages,
    thermalImages,
    generalMaintenanceImages,
    notesForGeneralMaintenance,
    notesForThermal,
  } = report;

  const inspectionData = {
    summary,
    weatherConsiderations,
    issues,
    recommendedFixesAndImprovements,
  };

  const isFormDataMissing = !summary?.length;

  return (
    <article className="PublicReportDisplay">
      <>
        {companyLogo ? (
          <section className="PublicReportDisplay-LogoWrapper">
            <img
              alt={t('MaintenanceReport.publicDisplay.companyLogo') ?? 'Company Logo'}
              src={`data:image/png;base64,${companyLogo}`}
              className="PublicReportDisplay-Logo"
            />
          </section>
        ) : null}
        <section className="PublicReportDisplay-HeadingWrapper">
          <h2 data-testid="maintenance-report-title">{report?.title}</h2>
          <p data-testid="maintenance-report-date">
            {moment.utc(createdAt).format(fullMonthDateFormat)}
          </p>
        </section>
        <section className="PublicReportDisplay-ProjectInfoWrapper">
          <div className="PublicReportDisplay-ProjectInfo">
            <h2 data-testid="maintenance-report-project-name">{project.name}</h2>
            <div
              className="PublicReportDisplay-Address"
              data-testid="maintenance-report-project-address"
            >
              {project.address}
            </div>
            <div className="PublicReportDisplay-Inspector">
              <h3>{t('MaintenanceReport.publicDisplay.inspector')}</h3>
              <p data-testid="maintenance-report-inspector">
                {createdBy.firstName} {createdBy.lastName}
              </p>
            </div>
          </div>
        </section>
        {allPhotosImages?.length ? (
          <ImageDisplay
            imageArray={allPhotosImages}
            title={t('MaintenanceReport.publicDisplay.allPhotos')}
          />
        ) : null}
        {generalMaintenanceImages?.length ? (
          <ImageDisplay
            imageArray={generalMaintenanceImages}
            title={t('MaintenanceReport.publicDisplay.generalMaintenance')}
            notes={notesForGeneralMaintenance}
          />
        ) : null}
        {thermalImages?.length ? (
          <ImageDisplay
            imageArray={thermalImages}
            title={t('MaintenanceReport.publicDisplay.thermalPhotos')}
            notes={notesForThermal}
          />
        ) : null}
        {!isFormDataMissing && <InspectionNotes data={inspectionData} />}
        <section className="PublicReportDisplay-DataWrapper">
          <p className="PublicReportDisplay-FillInItem">
            {t('MaintenanceReport.fillInArea.customerName')}
          </p>
          <p className="PublicReportDisplay-FillInItem">
            {t('MaintenanceReport.fillInArea.signature')}
          </p>
          <p className="PublicReportDisplay-FillInItem">{t('MaintenanceReport.fillInArea.date')}</p>
        </section>
        <section className="PublicReportDisplay-Footer">
          <p>{t('MaintenanceReport.publicDisplay.poweredBy')}</p>
          <ScaniflyLogo className="Logo" />
        </section>
      </>
    </article>
  );
};

export default PublicReportDisplay;
