import { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Button, Checkbox } from 'antd';
import cn from 'classnames';
import {
  DDS_CSV_COLUMN_TITLES,
  MY_PROJECTS_TABLE_CSV_COLUMN_TITLES,
  SORT,
  SORT_OPTIONS,
} from 'screens/Projects/ProjectsList/constants';
import { handleMyProjectsCSVExport } from 'screens/Projects/ProjectsList/helpers';

import { RootState } from 'state/store';

import { CSVExportButton } from 'components';

import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as LargeArrow } from 'assets/icons/arrow-large.svg';
import { ReactComponent as DropdownArrow } from 'assets/icons/dropdown-arrow.svg';
import { ReactComponent as GridViewIcon } from 'assets/icons/grid-view-icon.svg';
import { ReactComponent as ListViewIcon } from 'assets/icons/list-view-icon.svg';

import './FilterHelpers.scss';

interface FilterHelpersProps {
  handleCheckAll: () => void;
  isCheckedAll: boolean;
  checkedProjects: string[];
  handleFilterSave: (key: string, value: string | boolean) => void;
  savedFilters: { [key: string]: string | boolean };
  isListView: boolean;
  setIsListView: () => void;
  toggleEditFoldersModalOpen: (isOpen: boolean) => void;
  totalProjects: number;
}

const FilterHelpers = ({
  handleCheckAll,
  isCheckedAll,
  checkedProjects,
  handleFilterSave,
  savedFilters,
  isListView,
  setIsListView,
  toggleEditFoldersModalOpen,
  totalProjects,
}: FilterHelpersProps) => {
  const { t } = useTranslation();
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isAsc, toggleisAsc] = useToggle();
  const sortRef = useOnclickOutside(() => setIsSortOpen(false));
  const { company } = useSelector((state: RootState) => state.company);
  const { isScaniflyAdmin, isSimpleDesignOrSalesManager } = usePermissions();

  const DDS_TITLES = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DRONE_DATA_SCORE,
    company?.pricingTier
  )
    ? DDS_CSV_COLUMN_TITLES
    : [];

  const handleSortOrder = () => {
    handleFilterSave(SORT.ORDER, isAsc ? SORT.DESC : SORT.ASC);
    toggleisAsc();
  };

  const handleEditFolders = () => toggleEditFoldersModalOpen(true);

  const handleClickSort = () => {
    setIsSortOpen(!isSortOpen);
  };

  return (
    <>
      <div className="FilterHelpers">
        <div className="FilterHelpers-All" data-testid="total-project-count">
          <Checkbox
            onChange={handleCheckAll}
            checked={isCheckedAll && checkedProjects.length > 0}
            aria-checked={isCheckedAll && checkedProjects.length}
            id="filterHelpers-SelectAll"
          />
          <label htmlFor="filterHelpers-SelectAll">
            {t('ProjectFilters.select')} • {checkedProjects.length} / {totalProjects || 0}
          </label>
        </div>
        <div className="FilterHelpers-ButtonWrapper">
          <CSVExportButton
            tableName={TABLE_NAMES.MY_PROJECTS_PAGE}
            columnTitles={[...MY_PROJECTS_TABLE_CSV_COLUMN_TITLES, ...DDS_TITLES]}
            prepareCSVData={handleMyProjectsCSVExport}
            additionalStyle="FilterHelpers-CSVExportButton"
            companyId={company?.id ?? ''}
          />
          <div className="FilterHelpers-Switch">
            <div className="FilterHelpers-ViewSwitch-Wrapper">
              <button
                disabled={isListView}
                aria-disabled={isListView}
                onClick={() => setIsListView()}
                className="ViewSwitchButton"
                aria-label="list view"
                aria-pressed={isListView}
              >
                <ListViewIcon />
              </button>
              <button
                disabled={!isListView}
                aria-disabled={isListView}
                onClick={() => setIsListView()}
                className="ViewSwitchButton"
                aria-label="grid view"
                aria-pressed={!isListView}
              >
                <GridViewIcon />
              </button>
            </div>
            <div className="FilterHelpers-Sort" ref={sortRef}>
              <Button
                className={cn('Button--Filter FilterHelpers-Sort-Btn', {
                  'Button--Active': isSortOpen,
                })}
                onClick={handleClickSort}
                data-testid="project-sort-button"
              >
                Sort
                <span
                  className={cn({
                    'FilterHelpers-Sort-Arrow--Desc': isSortOpen,
                  })}
                  data-testid="project-sort-arrow"
                >
                  <DropdownArrow />
                </span>
              </Button>
              {isSortOpen && (
                <div className="FilterHelpers-Sort-Dropdown ignore-onclickoutside">
                  <div className="FilterHelpers-Sort-Items">
                    {SORT_OPTIONS.map(({ label, value }) => (
                      <div key={value} className="FilterHelpers-Sort-Item">
                        <div
                          className={cn('FilterHelpers-Sort-Arrow', {
                            'FilterHelpers-Sort-Arrow--Desc': !isAsc,
                            'FilterHelpers-Sort-Arrow--Hidden': savedFilters[SORT.SORT] !== value,
                          })}
                          onClick={handleSortOrder}
                          aria-hidden={savedFilters[SORT.SORT] !== value}
                          data-testid={`project-sort-option-${value}}`}
                        >
                          <LargeArrow data-testid={`project-sort-arrow-${value}}`} />
                        </div>
                        <span
                          role="button"
                          tabIndex={0}
                          className={cn({
                            'FilterHelpers-Sort-Item--Active': savedFilters[SORT.SORT] === value,
                          })}
                          onMouseDown={() =>
                            savedFilters[SORT.SORT] === value
                              ? handleSortOrder()
                              : handleFilterSave(SORT.SORT, value)
                          }
                        >
                          {label}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {checkedProjects.length > 0 && !isSimpleDesignOrSalesManager && (
        <div className="EditFolders">
          <Button className="EditFolders-Button Button--White" onClick={handleEditFolders}>
            {t('ProjectFilters.edit')}
          </Button>
        </div>
      )}
    </>
  );
};

export default FilterHelpers;
