import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Input, Spin, Table } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { debounce } from 'lodash-es';
import CreditsTransaction from 'types/CreditTransaction';
import { CreditTransactionFilter, Extra, Pagination, Sorter, TableParams } from 'types/TableParams';

import { adminCompanyNameRequested } from 'state/slices/admin/adminCompanyUsersSlice';
import { creditTransactionsRequested } from 'state/slices/designServices/orderDesignServiceCreditsSlice';
import { resetProject } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { CSVExportButton, GoBackButton, SidebarTag } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';

import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import { getSortBy } from 'helpers/utils/getSortBy';
import { getFilterBy } from './helpers/getFilterBy';
import { handleCreditTransactionsCSVExport } from './helpers/handleCreditTransactionsCSVExport';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import '../ScaniflyAdmin.scss';
import { CREDIT_HISTORY_CSV_COLUMN_TITLES } from './constants/CREDIT_HISTORY_CSV_COLUMN_TITLES';
import TableData from './TableData';

const CreditHistoryCompany = () => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [searchText, setSearchText] = useState('');
  const [displayedData, setDisplayedData] = useState<CreditsTransaction[]>([]);
  const [tableParams, setTableParams] = useState<
    TableParams<CreditsTransaction, CreditTransactionFilter>
  >({
    pagination: {
      current: 1,
    },
  });

  const { creditTransactions, isCreditTransactionsLoading } = useSelector(
    (state: RootState) => state.orderDesignServiceCredits
  );

  useEffect(() => {
    if (companyId) {
      dispatch(adminCompanyNameRequested(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (!isCreditTransactionsLoading) {
      setDisplayedData(creditTransactions?.items);
    }
  }, [creditTransactions?.items, isCreditTransactionsLoading]);

  useEffect(() => {
    dispatch(resetProject());
  }, [dispatch]);

  useEffect(() => {
    const { filters, sorter, pagination } = tableParams || {};
    const { order, columnKey } = sorter || {};
    if (companyId) {
      dispatch(
        creditTransactionsRequested({
          companyId,
          size: PROJECT_LIST_SIZE,
          pageIndex: pagination?.current ?? 1,
          sortBy: getSortBy(columnKey, order),
          filterBy: getFilterBy({ filters, searchText }),
        })
      );
    }
  }, [companyId, dispatch, searchText, tableParams]);

  const handleTableChange = (
    pagination: Pagination,
    filters: CreditTransactionFilter | Record<string, FilterValue | null>,
    sorter: Sorter<CreditsTransaction> | Sorter<CreditsTransaction>[],
    extra: Extra<CreditsTransaction>
  ) => {
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({ pagination, filters, sorter });
    setDisplayedData(extra.currentDataSource);
  };

  return (
    <div className="DesignServices-Wrapper">
      <div className="DesignServices-SubHeader">
        <GoBackButton />
        <Input
          placeholder={t('Shared.search')}
          role="searchbox"
          prefix={<SearchIcon />}
          aria-label="search credit transactions"
          onChange={debounce((e) => {
            setSearchText(e.target.value);
          }, 400)}
          className="ScaniflyAdmin-Search"
        />
        <div>
          <div data-testid="transaction-count" className="ScaniflyAdmin-DataDisplay">
            {creditTransactions?.totalCount ?? 0} transactions
          </div>
          <div data-testid="balance" className="ScaniflyAdmin-DataDisplay">
            Balance:{' '}
            <SidebarTag
              text={(creditTransactions?.balance ?? 0).toLocaleString('en-US')}
              type={TagTypes.score}
            />
          </div>
        </div>
        <CSVExportButton
          tableName={TABLE_NAMES.CREDIT_HISTORY}
          columnTitles={CREDIT_HISTORY_CSV_COLUMN_TITLES}
          prepareCSVData={handleCreditTransactionsCSVExport}
          companyId={companyId}
        />
      </div>
      <Table
        className="DesignServices-Table"
        columns={TableData({})}
        dataSource={displayedData}
        rowKey="id"
        scroll={{ x: 'auto', y: 'calc(100vh - 33rem)' }}
        pagination={{
          total: creditTransactions?.totalCount,
          pageSize: PROJECT_LIST_SIZE,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={(pagination, filters, sorter, extra) =>
          handleTableChange(pagination, filters, sorter, extra)
        }
        loading={{
          indicator: (
            <div className="DesignServices-Spinner">
              <Spin size="large" />
            </div>
          ),
          spinning: isCreditTransactionsLoading,
        }}
      />
    </div>
  );
};

export default CreditHistoryCompany;
