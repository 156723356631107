import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability } from '@cpm/scanifly-shared-data';
import { Button, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import cn from 'classnames';

import { projectRequested } from 'state/slices/projectSlice';

import ExpandableQRCode from 'components/QRCode/ExpandableQRCode';

import { DRONE_IMAGES } from 'helpers/constants/categories';
import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { PROJECT_STATUSES } from 'helpers/constants/projectStatuses';
import {
  draftProjectCategoriesRoute,
  draftProjectCategoryRoute,
  projectCategoryRoute,
  projectsListRoute,
  rootRoute,
  scaniflyAdminDraftProjectCategoriesRoute,
  scaniflyAdminDraftProjectCategoryRoute,
  scaniflyAdminProjectCategoryRoute,
} from 'helpers/constants/routes';
import usePermissions from 'helpers/hooks/usePermissions';

import { ReactComponent as CategoryIcon } from 'assets/icons/category-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil-icon.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh-icon.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick-icon.svg';
import { ReactComponent as XIcon } from 'assets/icons/x-gray-icon.svg';
import { kebabCase } from 'lodash-es';

import fontSizes from 'helpers/constants/fontSizes';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { DATE_OPTIONS_FOR_ALBUMS } from 'screens/ScaniflyAdmin/CustomerSupportUpload/constants';
import {
  deleteProjectCategory,
  getProjectMediaCategories,
} from 'state/slices/projectMediaCategories';
import { AppDispatch, RootState } from 'state/store';
import styled from 'styled-components';
import { MediaCategory } from 'types';
import AddNewProjectCategoryModal from './AddNewProjectCategoryModal';
import { CATEGORY_TYPES } from './constants';
import { ProjectAllMediaMap } from './ProjectAllMediaMap';
import './ProjectCategories.scss';
import { MODAL_TYPES } from './RenameCategoryModal/constants';
import RenameCategoryModal from './RenameCategoryModal/RenameCategoryModal';

const StyledTimestampWrapper = styled.div`
  margin: 0.5rem 0 0rem 1.75rem;
  font-size: ${fontSizes.extraSmall};
`;

const ProjectCategories = ({ isAdminRoute = false }: { isAdminRoute: boolean }) => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const { projectCategories } = useSelector((state: RootState) => state.projectCategories);
  const { isProjectRequestedLoading, project } = useSelector((state: RootState) => state.project);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<MediaCategory | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const isDraft =
    project?.statusDescription === PROJECT_STATUSES.DRAFT ||
    project?.statusDescription === PROJECT_STATUSES.NO_FLIGHT;
  const isUploading = project?.statusDescription === PROJECT_STATUSES.UPLOADING;
  const { company } = useSelector((state: RootState) => state.company);
  const { isScaniflyAdmin } = usePermissions();
  const notesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.COMMENTS_MENTIONS,
    company?.pricingTier
  );

  const isProjectRemote = project && project.statusDescription === PROJECT_STATUSES.REMOTE;

  const { isExact: isNewProject } = {
    ...useRouteMatch({
      path: !isAdminRoute
        ? draftProjectCategoriesRoute(projectId)
        : scaniflyAdminDraftProjectCategoriesRoute(projectId),
    }),
  };

  // On initial load request project
  useEffect(() => {
    if (!project) {
      if (!isProjectRequestedLoading && isInitialLoad) {
        dispatch(projectRequested(projectId));
        setIsInitialLoad(false);
      } else if (!isInitialLoad && !isProjectRequestedLoading) {
        history.push(projectsListRoute());
      }
    }
  }, [isInitialLoad, projectId, dispatch, project, isProjectRequestedLoading, history]);

  useEffect(() => {
    if (project) {
      dispatch(getProjectMediaCategories(projectId));
    }
  }, [project, dispatch, projectId]);

  useEffect(() => {
    if ((projectId === project?.id && isNewProject && !isDraft) || (!isNewProject && isDraft)) {
      history.push(rootRoute());
    }
  }, [project, history, isDraft, isNewProject, projectId]);

  const getDraftRoute = (projectId: string, pathName: string) => {
    return isAdminRoute
      ? scaniflyAdminDraftProjectCategoryRoute(projectId, pathName)
      : draftProjectCategoryRoute(projectId, pathName);
  };

  const getProjectRoute = (projectId: string, pathName: string) => {
    return isAdminRoute
      ? scaniflyAdminProjectCategoryRoute(projectId, pathName)
      : projectCategoryRoute(projectId, pathName);
  };

  const handleOpenCategory = (categoryName: string) => {
    if (categoryName === DRONE_IMAGES && isUploading) {
      return;
    }
    history.push(
      isDraft
        ? getDraftRoute(project.id, categoryName)
        : getProjectRoute(project?.id ?? '', categoryName)
    );
  };

  const handleDeleteCategory = (event: MouseEvent, name: string) => {
    confirm({
      title: `Delete Album: ${name}`,
      content: `Are you sure you want to delete ${name}? Any media from ${name} will be moved to Archive album.`,
      okButtonProps: { style: { background: colors.red } },
      okText: 'Delete Album',
      onOk: () => {
        dispatch(deleteProjectCategory({ projectId: project?.id ?? '', categoryName: name }));
      },
      ...CONFIRM_PROPS,
    });
    event.stopPropagation();
  };

  const handleRenameCategory = (event: MouseEvent, category: MediaCategory) => {
    setSelectedCategory(category);
    event.stopPropagation();
  };

  const handleOpenAddNewCategoryModal = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const renderCategoriesList = (
    categories: MediaCategory[],
    t: TFunction<'translation', undefined>
  ) => {
    return categories?.map((category, index) => {
      const dataTestId = `project-album-${kebabCase(category.categoryName)}`;
      const isDisabled = isUploading && category.categoryName === DRONE_IMAGES;
      const uploadedImagesExist =
        !!category.uploadedMediasCount ||
        (category.categoryName === DRONE_IMAGES && !(isDraft || isUploading) && !isProjectRemote);
      return (
        !!(category.categoryName.length || category.uploadedMediasCount) && (
          <Tooltip
            key={index}
            title={isDisabled && 'Your drone images are uploading!'}
            overlayStyle={{ maxWidth: '30rem' }}
          >
            <div
              role="button"
              tabIndex={0}
              className={cn('ProjectCategories-CategoriesList-Category', {
                'ProjectCategories-CategoriesList-Category--Disabled': isDisabled,
                'ProjectCategories-CategoriesList-Category--Tall': !uploadedImagesExist,
              })}
              onClick={() => handleOpenCategory(category.categoryName)}
              onKeyDown={(e) => e.key === 'Enter' && handleOpenCategory(category.categoryName)}
              data-testid={dataTestId}
            >
              <div>
                <CategoryIcon className="ProjectCategories-CategoriesList-Category-CategoryIcon" />
                <span className="ProjectCategories-CategoriesList-Category-Name">
                  {category.categoryName}
                </span>
              </div>
              <div>
                {uploadedImagesExist && (
                  <TickIcon className="ProjectCategories-CategoriesList-Category-TickIcon" />
                )}
                {category.categoryName === DRONE_IMAGES && isUploading && (
                  <RefreshIcon className="ProjectCategories-CategoriesList-Category-RefreshIcon" />
                )}
                {category.type === CATEGORY_TYPES.PROJECT && (
                  <>
                    <PencilIcon
                      onClick={(e) => handleRenameCategory(e as unknown as MouseEvent, category)}
                      className="ProjectCategories-CategoriesList-Category-Icon"
                      aria-label="rename album"
                    />
                    <XIcon
                      onClick={(e) =>
                        handleDeleteCategory(e as unknown as MouseEvent, category.categoryName)
                      }
                      className="ProjectCategories-CategoriesList-Category-XIcon"
                      aria-label="delete album"
                    />
                  </>
                )}
              </div>
            </div>
            {category.uploadedMediasCount && category.mediaLastUpdatedDate ? (
              <StyledTimestampWrapper>
                {t('ProjectCategories.lastUpdated')}{' '}
                {new Date(category.mediaLastUpdatedDate)
                  .toLocaleDateString('en-US', DATE_OPTIONS_FOR_ALBUMS)
                  .replaceAll('/', '-')
                  .split(', ')
                  .reverse()
                  .join(', ')}
              </StyledTimestampWrapper>
            ) : null}
          </Tooltip>
        )
      );
    });
  };

  const pin = useMemo(() => {
    return project
      ? {
          latitude: project.geolocation.latitude,
          longitude: project.geolocation.longitude,
        }
      : undefined;
  }, [project]);

  return (
    <>
      <AddNewProjectCategoryModal
        categories={projectCategories}
        projectId={projectId}
        handleModalCancel={handleModalCancel}
        modalVisible={modalVisible}
      />
      {selectedCategory && (
        <RenameCategoryModal
          categories={projectCategories}
          id={project?.id ?? ''}
          type={MODAL_TYPES.PROJECT}
          isModalVisible={!!selectedCategory}
          handleModalClose={() => setSelectedCategory(null)}
          selectedCategory={selectedCategory}
        />
      )}

      <div className="MapWrapper">
        <div className="MapWrapper-Content MapWrapper-Content--ProjectStep">
          <div className="ProjectCategories">
            {isAdminRoute && !isProjectRequestedLoading && (
              <div className="ProjectCategories-SubmittedFor">
                Submitted for: {project?.submittedFor?.firstName} {project?.submittedFor?.lastName}{' '}
                at {project?.submittedFor?.company?.name}
              </div>
            )}
            <div className="ProjectCategories-QRCodeContainer">
              <ExpandableQRCode />
            </div>
            <h2 className="ProjectCategories-ProjectName">{project?.name}</h2>
            <div className="ProjectCategories-Label">
              Upload project images for each album
              <Button
                onClick={handleOpenAddNewCategoryModal}
                className="Button--Blue ProjectCategories-Button--NewCategory"
              >
                <CategoryIcon className="ProjectCategories-Button--NewCategory-Icon" />
                New Album
              </Button>
            </div>
            <div>
              {renderCategoriesList(
                projectCategories.filter(
                  (category) =>
                    category.id !== (notesAccess && process.env.REACT_APP_NOTES_CATEGORY_ID)
                ),
                t
              )}
            </div>
          </div>
        </div>
        <div className="MapWrapper-Mapbox">
          <ProjectAllMediaMap projectId={projectId} pin={pin} projectType={project?.type} />
        </div>
      </div>
    </>
  );
};

export default ProjectCategories;
