import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, DatePicker, Input, Spin, Switch, Table } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { AccountingInfo } from 'types/AccountingInfo';

import { adminCompaniesWithAccountingInfoRequested } from 'state/slices/admin/adminCompaniesSlice';
import { invoiceStatusRefreshRequested } from 'state/slices/admin/adminInvoicesSlice';
import { AppDispatch, RootState } from 'state/store';

import { CSVExportButton, GoBackButton } from 'components';

import { TABLE_NAMES } from 'helpers/constants/TABLE_NAMES';
import usePermissions from 'helpers/hooks/usePermissions';
import useToggle from 'helpers/hooks/useToggle';
import { monthDateFormat } from 'helpers/utils/dateFormat';
import { filterAccountingInformation } from 'helpers/utils/filterAccountingInformation';
import { scaniflyBillingAdminFilter } from 'helpers/utils/scaniflyBillingAdminFilter';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import '../ScaniflyAdmin.scss';
import { AccountingTableData } from './accountingTableData';
import { ACCOUNTING_CSV_COLUMN_TITLES } from './constants';
import { handleAccountingCSVExport, TableSummary } from './helpers';

const AccountingTable = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeAccounts, toggleActiveAccounts] = useToggle(true);
  const [activeSubscription, toggleActiveSubscription] = useToggle(true);
  const [dangerZone, toggleDangerZone] = useToggle(false);
  const [filtersVisible, toggleFiltersVisible] = useToggle(false);
  const [date, setDate] = useState<moment.Moment>(moment().startOf('month').subtract(1, 'month'));
  const { isScaniflyBillingAdmin } = usePermissions();
  const { companiesWithAccountingInfo, isLoading } = useSelector(
    (state: RootState) => state.adminCompanies
  );
  const [isUpdating, setUpdating] = useState(false);

  const year = date.year();
  const month = date.month();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(adminCompaniesWithAccountingInfoRequested({ month: month, year: year }));
  }, [month, year, dispatch]);

  useEffect(() => {
    if (isScaniflyBillingAdmin) {
      dispatch(invoiceStatusRefreshRequested({ month, year }));
    }
  }, [dispatch, isScaniflyBillingAdmin, month, year]);

  useEffect(() => {
    if (isUpdating) {
      setTimeout(() => {
        setUpdating(false);
      }, 100);
    }
  }, [isUpdating]);

  const dataSource = useMemo(
    () =>
      filterAccountingInformation(
        companiesWithAccountingInfo,
        searchTerm,
        activeAccounts,
        activeSubscription,
        dangerZone
      ),
    [activeAccounts, activeSubscription, companiesWithAccountingInfo, dangerZone, searchTerm]
  );

  const handleActiveToggle = () => toggleActiveAccounts();

  const handleActiveSubscriptionToggle = () => toggleActiveSubscription();

  const handleDangerZoneToggle = () => toggleDangerZone();

  const handleDateChange = (value: moment.Moment | null) => {
    if (value) {
      setDate(value);
    }
  };

  return (
    <div className="ScaniflyAdmin-Wrapper">
      <div className="ScaniflyAdmin-SubHeader Accounting-SubHeader">
        <GoBackButton />
        <Input
          placeholder={t('Shared.search')}
          prefix={<SearchIcon />}
          aria-label="search accounts"
          onChange={debounce((e) => {
            setUpdating(true);
            setTimeout(() => {
              setSearchTerm(e.target.value);
            }, 100);
          }, 400)}
          className="ScaniflyAdmin-Search"
        />
        <p data-testid="company-count">{dataSource.length} companies</p>
        <CSVExportButton
          tableName={TABLE_NAMES.ACCOUNTING}
          columnTitles={[...ACCOUNTING_CSV_COLUMN_TITLES]}
          dataSource={dataSource}
          month={month}
          year={year}
          prepareCSVData={handleAccountingCSVExport}
        />
        <Button className="Button--White" onClick={() => toggleFiltersVisible()}>
          {filtersVisible ? t('AccountingForms.hideFilters') : t('AccountingForms.showFilters')}
        </Button>
      </div>
      {filtersVisible && (
        <div className="Accounting-TableFilters">
          <div className="Accounting-TableFilters-SwitchContainer">
            <div className="Accounting-TableFilters-Switch">
              <span id="activeAccounts">Active Accounts</span>
              <Switch
                checked={activeAccounts}
                onChange={handleActiveToggle}
                aria-labelledby="activeAccounts"
              />
            </div>
            <div className="Accounting-TableFilters-Switch">
              <span id="subscriptionAccounts">Accounts with active subscription</span>
              <Switch
                checked={activeSubscription}
                onChange={handleActiveSubscriptionToggle}
                aria-labelledby="subscriptionAccounts"
              />
            </div>
            <div className="Accounting-TableFilters-Switch">
              <span id="dangerZoneAccounts">Accounts in danger zone</span>
              <Switch
                checked={dangerZone}
                onChange={handleDangerZoneToggle}
                aria-labelledby="dangerZoneAccounts"
              />
            </div>
          </div>
          <DatePicker
            picker="month"
            value={activeAccounts ? date : null}
            format={monthDateFormat}
            allowClear={false}
            onChange={handleDateChange}
            aria-label="filter table by month"
            data-testid="accounting-table-date-picker"
          />
        </div>
      )}
      <Table
        title={() => (
          <div className="Accounting-Table-Title">
            {date.format('YYYY MMMM') + ' Accounting Table'}
          </div>
        )}
        className="ScaniflyAdmin-Table Accounting-Table"
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'Accounting-Table-Row--Light' : 'Accounting-Table-Row--Dark'
        }
        columns={scaniflyBillingAdminFilter(
          isScaniflyBillingAdmin,
          AccountingTableData,
          month,
          year
        )}
        dataSource={dataSource}
        pagination={false}
        rowKey="companyId"
        scroll={{
          x: true,
          y: filtersVisible ? 'calc(100vh - 43.5rem)' : 'calc(100vh - 38rem)',
        }}
        loading={{
          spinning: isLoading || isUpdating,
          indicator: (
            <div className="ScaniflyAdmin-Spinner">
              <Spin size="large" />
            </div>
          ),
        }}
        summary={(pageData) => (
          <TableSummary
            accountingInformationList={pageData as AccountingInfo[]}
            month={month}
            year={year}
            isScaniflyBillingAdmin={isScaniflyBillingAdmin}
          />
        )}
      />
    </div>
  );
};

export default AccountingTable;
