import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  ChecklistTemplateStatusEnum,
  ExampleMedia,
  ReferenceMedia,
  SectionType,
} from '@cpm/scanifly-shared-data';
import { Spin } from 'antd';
import { MediaCategoryType } from 'types';

import {
  copyChecklistTemplate,
  getChecklistTemplateById,
  selectChecklistTemplateById,
  updateChecklistTemplateById,
} from 'state/slices/checklists';
import { getMediaCategoriesByType, selectCategoriesByType } from 'state/slices/mediaCategories';
import { getReferenceMediaByCategory, selectReferenceMediaMap } from 'state/slices/referenceMedia';
import { AppDispatch, RootState } from 'state/store';

import { ErrorDisplay } from 'components';

import usePermissions from 'helpers/hooks/usePermissions';
import { openNotification } from 'helpers/utils/openNotification';

import { companyRequested, defaultCategoriesRequested } from 'state/slices/companySlice';
import { selectCompanyId, selectCompanyMediaCategories } from 'state/slices/companySlice/selectors';
import { getExampleMediaByTemplate, selectExampleMediaByTemplate } from 'state/slices/exampleMedia';
import { isDefaultTemplate } from '../utils';
import ChecklistTemplate from './ChecklistTemplate';
import ChecklistTemplateProvider from './ChecklistTemplateProvider';

const EditChecklistTemplate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { templateId }: { templateId: string } = useParams();
  const { isScaniflyAdmin } = usePermissions();

  const companyId = useSelector(selectCompanyId);
  const templateToEdit = useSelector((state: RootState) =>
    selectChecklistTemplateById(state, templateId)
  );
  const referenceMediaCategories = useSelector((state: RootState) =>
    selectCategoriesByType(state, MediaCategoryType.reference)
  );
  const referenceMediaMap = useSelector((state: RootState) => selectReferenceMediaMap(state));

  const exampleMediaMap = useSelector((state: RootState) =>
    selectExampleMediaByTemplate(state, templateId)
  );

  const companyMediaCategories = useSelector(selectCompanyMediaCategories);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [title, setTitle] = useState(templateToEdit?.title || 'Template Title');
  const [status, setStatus] = useState<ChecklistTemplateStatusEnum>(
    templateToEdit?.status ?? ChecklistTemplateStatusEnum.unpublished
  );
  const [sections, setSections] = useState<SectionType[]>([]);

  const getTemplate = useCallback(async () => {
    const templateResponse = await dispatch<any>(getChecklistTemplateById(templateId));
    if (templateResponse.checklistStructure) {
      setSections(templateResponse.checklistStructure);
      setTitle(templateResponse.title);
      setStatus(templateResponse.status);
    }
  }, [dispatch, templateId]);

  const getReferenceMediaCategories = useCallback(async () => {
    await dispatch<any>(getMediaCategoriesByType(MediaCategoryType.reference));
  }, [dispatch]);

  const getReferenceMediaAction = useCallback(
    async (categoryId: string): Promise<ReferenceMedia[]> =>
      await dispatch<any>(getReferenceMediaByCategory(categoryId)),
    [dispatch]
  );

  const getDefaultMediaCategoriesAction = useCallback(async (): Promise<void> => {
    await dispatch<any>(defaultCategoriesRequested());
  }, [dispatch]);

  const getCompanyMediaCategoriesAction = useCallback(
    async (companyId: string): Promise<void> => {
      await dispatch<any>(companyRequested(companyId));
    },
    [dispatch]
  );

  const getExampleMediaAction = useCallback(
    async (templateId: string): Promise<ExampleMedia[]> =>
      await dispatch<any>(getExampleMediaByTemplate(templateId)),
    [dispatch]
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const promises = [];
        if (!companyMediaCategories.length) {
          promises.push(getCompanyMediaCategoriesAction(companyId!));
          promises.push(getDefaultMediaCategoriesAction());
        }
        if (!referenceMediaCategories.length) {
          promises.push(getReferenceMediaCategories());
        }
        if (!exampleMediaMap.length) {
          promises.push(getExampleMediaAction(templateId));
        }

        if (!templateToEdit || !templateToEdit?.checklistStructure) {
          promises.push(getTemplate());
        } else if (templateToEdit?.checklistStructure && sections.length < 1) {
          setSections(templateToEdit.checklistStructure);
        }

        await Promise.all(promises);
      } catch (err: any) {
        openNotification({
          type: 'error',
          title: t('alertMessages.errorTitle'),
          text: (
            <div>
              {t('Checklists.saveFail')}
              <ErrorDisplay error={err} />
            </div>
          ),
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (companyId) {
      loadData();
    }
  }, [templateToEdit, getTemplate, sections, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = useCallback(async () => {
    const updatedTemplate = JSON.parse(JSON.stringify(templateToEdit));
    updatedTemplate.checklistStructure = sections;
    updatedTemplate.title = title;
    updatedTemplate.status = status;
    const updateResponse = await dispatch<any>(
      updateChecklistTemplateById(templateId, updatedTemplate)
    );
    if (updateResponse.checklistStructure) {
      setSections(updateResponse.checklistStructure);
    }
  }, [templateToEdit, dispatch, sections, status, templateId, title]);

  const handleRename = useCallback(async () => {
    const updateTemplate = JSON.parse(JSON.stringify(templateToEdit));
    updateTemplate.title = title;
    const udpateResponse = await dispatch<any>(
      updateChecklistTemplateById(templateId, updateTemplate)
    );
    if (udpateResponse.checklistStructure) {
      setTitle(udpateResponse.title);
    }
  }, [templateToEdit, dispatch, title, templateId]);

  const handleCopy = useCallback(
    async (newTitle: string) => {
      if (templateToEdit) {
        await dispatch<any>(
          copyChecklistTemplate({
            ...templateToEdit,
            title: newTitle,
            checklistStructure: sections,
          })
        );
      }
    },
    [templateToEdit, dispatch, sections]
  );

  const handlePublishUnpublish = useCallback(async () => {
    const updatedTemplate = JSON.parse(JSON.stringify(templateToEdit));
    updatedTemplate.checklistStructure = sections;
    updatedTemplate.title = title;
    updatedTemplate.status =
      status === ChecklistTemplateStatusEnum.published
        ? ChecklistTemplateStatusEnum.unpublished
        : ChecklistTemplateStatusEnum.published;
    const updateResponse = await dispatch<any>(
      updateChecklistTemplateById(templateId, updatedTemplate)
    );
    if (updateResponse.checklistStructure) {
      setSections(updateResponse.checklistStructure);
    }
    if (updateResponse.status) {
      setStatus(updateResponse.status);
    }
  }, [templateToEdit, dispatch, sections, templateId, title, status]);

  const hasUnsavedChanges = useMemo(() => {
    const savedChanges = JSON.stringify({
      title: templateToEdit?.title,
      checklistStructure: templateToEdit?.checklistStructure,
    });
    const localChanges = JSON.stringify({ title, checklistStructure: sections });
    return savedChanges !== localChanges;
  }, [templateToEdit, sections, title]);

  const isDefaultTemplateMemoized = useMemo(
    () => (templateToEdit?.type ? isDefaultTemplate(templateToEdit?.type) : false),
    [templateToEdit?.type]
  );

  if (!templateToEdit) return null;
  if (!Array.isArray(templateToEdit?.checklistStructure)) return null;
  if (!templateToEdit?.title) return null;

  return (
    <ChecklistTemplateProvider
      companyMediaCategories={companyMediaCategories}
      templateId={templateId}
      getReferenceMediaAction={getReferenceMediaAction}
      referenceMediaCategories={referenceMediaCategories}
      referenceMediaMap={referenceMediaMap}
      exampleMediaMap={exampleMediaMap}
      getExampleMediaAction={getExampleMediaAction}
      sections={sections}
      setSections={setSections}
      setStatus={setStatus}
      setTitle={setTitle}
      status={status}
      title={title}
    >
      {isLoading ? (
        <Spin size="default" />
      ) : (
        <ChecklistTemplate
          hasUnsavedChanges={hasUnsavedChanges}
          onCopy={handleCopy}
          onSave={!isDefaultTemplateMemoized || isScaniflyAdmin ? handleSave : undefined}
          onRename={!isDefaultTemplateMemoized || isScaniflyAdmin ? handleRename : undefined}
          onPublishUnpublish={
            !isDefaultTemplateMemoized || isScaniflyAdmin ? handlePublishUnpublish : undefined
          }
        />
      )}
    </ChecklistTemplateProvider>
  );
};

export default EditChecklistTemplate;
