import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { kebabCase } from 'lodash';

import { PERMISSION, ROLE } from '@cpm/scanifly-shared-data';
import {
  adminCompanyMemberStatusUpdateRequested,
  updateCompanyStatus,
} from 'state/slices/admin/adminCompaniesSlice';
import { adminUpdateUserStatus } from 'state/slices/admin/adminUsersSlice';
import { newUserInvited } from 'state/slices/authSlice';
import { AppDispatch } from 'state/store';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import { USER_STATUS } from 'helpers/constants/userStatus';
import { openNotification } from 'helpers/utils/openNotification';

import { createNotification } from './helpers';
import { AccountHolder } from './TableData';

const ActionDropdown = ({
  companyId,
  companyName,
  accountHolder,
  isScaniflyBillingAdmin,
  handleAddCreditModalOpen,
  handleRefundCreditModalOpen,
  handleRemoveCreditModalOpen,
  setSelectedCompany,
}: {
  companyId: string;
  companyName: string;
  accountHolder: AccountHolder;
  isScaniflyBillingAdmin: boolean;
  handleAddCreditModalOpen: () => void;
  handleRefundCreditModalOpen: () => void;
  handleRemoveCreditModalOpen: () => void;
  setSelectedCompany: ({ name, id }: { name: string; id: string }) => void;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const selectedAction = useRef<string>();
  const title = useRef<string>();
  const content = useRef<string>();
  const okText = useRef<string>();
  const okButtonProps = useRef<{ style: { background: string } }>();

  const accountHolderName = `${accountHolder.firstName} ${accountHolder.lastName}`;

  const onCompanyMembersStatusUpdateSuccess = useCallback(
    (targetStatus: string) => {
      createNotification(targetStatus);
      dispatch(updateCompanyStatus({ companyId, targetStatus }));
    },
    [companyId, dispatch]
  );

  const handleUserStatusUpdate = useCallback(
    (userId: string, targetStatus: string) => {
      dispatch(
        adminUpdateUserStatus({
          userId,
          targetStatus,
          onSuccess: onCompanyMembersStatusUpdateSuccess,
        })
      );
    },
    [dispatch, onCompanyMembersStatusUpdateSuccess]
  );

  const handleCompanyMembersStatusUpdate = useCallback(
    (companyId: string, targetStatus: string) => {
      dispatch(
        adminCompanyMemberStatusUpdateRequested({
          companyId,
          targetStatus,
          onSuccess: onCompanyMembersStatusUpdateSuccess,
        })
      );
    },
    [dispatch, onCompanyMembersStatusUpdateSuccess]
  );

  const openConfirmModal = useCallback(() => {
    let action;

    // 'deactivate' action only deactivates the account holder (final status: 'inactive')
    // 'activate' action only activates the account holder (final status: 'active')
    // 'freeze' action freezes the account holder and all members (final status: 'frozen')
    // 'reactivate' action reactivates the account holder and all members (final status: 'active')
    if (selectedAction.current === 'activate') {
      action = () => handleUserStatusUpdate(accountHolder.id, USER_STATUS.ACTIVE);
    } else if (selectedAction.current === 'deactivate') {
      action = () => handleUserStatusUpdate(accountHolder.id, USER_STATUS.INACTIVE);
    } else if (selectedAction.current === 'reactivate') {
      action = () => handleCompanyMembersStatusUpdate(companyId, USER_STATUS.ACTIVE);
    } else if (selectedAction.current === 'freeze') {
      action = () => handleCompanyMembersStatusUpdate(companyId, USER_STATUS.FROZEN);
    }

    confirm({
      title: title.current,
      content: content.current,
      okText: okText.current,
      onOk: action,
      okButtonProps: okButtonProps.current,
      ...CONFIRM_PROPS,
    });
  }, [accountHolder.id, companyId, handleCompanyMembersStatusUpdate, handleUserStatusUpdate]);

  const handleActivate = useCallback(() => {
    selectedAction.current = 'activate';
    title.current = t('AccountManager.activateAccount');
    content.current = t('AccountManager.areYouSureActivate', {
      accountHolderName: accountHolderName,
    });
    okText.current = t('AccountManager.activate');
    okButtonProps.current = { style: { background: colors.green } };
    openConfirmModal();
  }, [accountHolderName, openConfirmModal, t]);

  const handleDeactivate = useCallback(() => {
    selectedAction.current = 'deactivate';
    title.current = t('AccountManager.deactivateAccount');
    content.current = t('AccountManager.areYouSureDeactivate', {
      accountHolderName: accountHolderName,
    });
    okText.current = t('AccountManager.deactivate');
    okButtonProps.current = { style: { background: colors.red } };
    openConfirmModal();
  }, [accountHolderName, openConfirmModal, t]);

  const handleReactivate = useCallback(() => {
    selectedAction.current = 'reactivate';
    title.current = t('AccountManager.reactivateAccount');
    content.current = t('AccountManager.areYouSureReactivate', {
      accountHolderName: accountHolderName,
    });
    okText.current = t('AccountManager.reactivate');
    okButtonProps.current = { style: { background: colors.green } };
    openConfirmModal();
  }, [accountHolderName, openConfirmModal, t]);

  const handleFreeze = useCallback(() => {
    selectedAction.current = 'freeze';
    title.current = t('AccountManager.freezeAccount');
    content.current = t('AccountManager.areYouSureFreeze');
    okText.current = t('AccountManager.freeze');
    okButtonProps.current = { style: { background: colors.red } };
    openConfirmModal();
  }, [openConfirmModal, t]);

  const handleResendInvitation = useCallback(
    (email: string) => {
      const newUser = {
        email: email,
        roles: [ROLE.admin],
        permissions: Object.values(PERMISSION),
        foldersAccess: null,
        companyId: undefined,
        companyName: undefined,
      };
      dispatch(newUserInvited(newUser));
      openNotification({
        type: 'success',
        title: 'Account Invite Sent!',
        text: `The invite was sent to ${email}.`,
      });
    },
    [dispatch]
  );

  const handleAddTrialCredits = useCallback(() => {
    setSelectedCompany({ name: companyName, id: companyId });
    handleAddCreditModalOpen();
  }, [companyId, companyName, handleAddCreditModalOpen, setSelectedCompany]);

  const handleRefundCredits = useCallback(() => {
    setSelectedCompany({ name: companyName, id: companyId });
    handleRefundCreditModalOpen();
  }, [companyId, companyName, handleRefundCreditModalOpen, setSelectedCompany]);

  const handleRemoveCredits = useCallback(() => {
    setSelectedCompany({ name: companyName, id: companyId });
    handleRemoveCreditModalOpen();
  }, [companyId, companyName, handleRemoveCreditModalOpen, setSelectedCompany]);

  const actionList = useMemo(() => {
    let actionList: { title: string; actionType: Function }[] = [];

    if (accountHolder?.status === USER_STATUS.ACTIVE) {
      actionList = [
        { title: t('AccountManager.deactivate'), actionType: handleDeactivate },
        { title: t('AccountManager.freeze'), actionType: handleFreeze },
      ];
    } else if (accountHolder?.status === USER_STATUS.INACTIVE) {
      actionList = [
        { title: t('AccountManager.activate'), actionType: handleActivate },
        { title: t('AccountManager.freeze'), actionType: handleFreeze },
      ];
    } else if (accountHolder?.status === 'pending') {
      // We don't have a 'pending' user status, so I'm not really sure why this is here
      actionList = [{ title: 'Resend Invitation', actionType: handleResendInvitation }];
    } else if (accountHolder?.status === USER_STATUS.FROZEN) {
      actionList = [{ title: 'Reactivate', actionType: handleReactivate }];
    }

    // This adds the 'Add Trial Credits', 'Refund Credits', and 'Remove Credits' options to the dropdown
    // only if the user is a Scanifly Billing Admin
    if (
      isScaniflyBillingAdmin &&
      accountHolder.status !== USER_STATUS.INACTIVE &&
      accountHolder.status !== USER_STATUS.FROZEN
    ) {
      actionList = [
        ...actionList,
        { title: t('AccountManager.addTrialCredits'), actionType: handleAddTrialCredits },
        { title: t('AccountManager.refundCredits'), actionType: handleRefundCredits },
        { title: t('AccountManager.removeCredits'), actionType: handleRemoveCredits },
      ];
    }

    return actionList;
  }, [
    accountHolder.status,
    handleActivate,
    handleAddTrialCredits,
    handleDeactivate,
    handleFreeze,
    handleReactivate,
    handleRefundCredits,
    handleRemoveCredits,
    handleResendInvitation,
    isScaniflyBillingAdmin,
    t,
  ]);

  const dropdownMenu = (
    <Menu>
      {actionList.map(({ title, actionType }) => (
        // @ts-ignore our stupid antd version is not compatible with @types/react v18
        <Menu.Item onClick={actionType} key={title} id={`option-${kebabCase(title)}-${companyId}`}>
          {title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    // @ts-ignore our stupid antd version is not compatible with @types/react v18
    <Dropdown overlay={dropdownMenu} trigger={['click']}>
      <Button className="Button--White" id={`account-status-select-${companyId}`}>
        Select <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ActionDropdown;
