import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Modal, Spin, Table } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { debounce } from 'lodash-es';
import { Project } from 'types';
import { Extra, Pagination, ProjectFilter, Sorter, TableParams } from 'types/TableParams';

import { resetStatusUpdateFlags } from 'state/slices/admin/adminProjectsSlice';
import { AppDispatch, RootState } from 'state/store';

import {
  ChangeProjectStatusModal,
  CSVExportButton,
  GoBackButton,
  UploadProjectButton,
} from 'components';

import { MODAL_PROPS } from 'helpers/constants/modals';
import { PROJECT_LIST_SIZE } from 'helpers/constants/projectListSize';
import { getAdminProjectTableColumns } from './helpers/getAdminProjectTableColumns';
import { getNotification } from './helpers/getNotification';
import { handleProjectsCSVExport } from './helpers/handleProjectsCSVExport';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';

import { DDS_COLUMN_TITLES, PROJECTS_TABLE_CSV_COLUMN_TITLES } from '../constants';
import '../ScaniflyAdmin.scss';

const BaseProjects = ({
  tableName,
  isCustomerOrUserProjectsTable,
  baseData,
  setTableParams,
  setSearchText,
  extraProps = {},
  companyId,
  userId,
}: {
  tableName: string;
  isCustomerOrUserProjectsTable: boolean;
  baseData: { data: Project[]; projectCount: number };
  setTableParams: Dispatch<SetStateAction<TableParams<Project, ProjectFilter>>>;
  setSearchText: Dispatch<SetStateAction<string>>;
  extraProps?: any;
  companyId?: string;
  userId?: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [changeProjectStatusModalVisible, setChangeProjectStatusModalVisible] =
    useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>();
  const [dataSource, setDataSource] = useState<Project[]>([]);
  const [displayedData, setDisplayedData] = useState<Project[]>([]);
  const [projectCount, setProjectCount] = useState<number>(0);

  const { isProjectsLoading, statusUpdateError, statusUpdateSuccess } = useSelector(
    (state: RootState) => state.adminProjects
  );

  useEffect(() => {
    getNotification(statusUpdateSuccess, statusUpdateError);
    dispatch(resetStatusUpdateFlags());
  }, [dispatch, statusUpdateSuccess, statusUpdateError]);

  useEffect(() => {
    setDataSource(baseData.data);
    setDisplayedData(baseData.data);
    setProjectCount(baseData.projectCount);
  }, [isProjectsLoading, tableName, baseData]);

  const openChangeStatusModalForProject = (projectId: string) => {
    if (isCustomerOrUserProjectsTable) {
      setChangeProjectStatusModalVisible(true);
      setSelectedProjectId(projectId);
    }
  };

  const handleModalClose = () => {
    setChangeProjectStatusModalVisible(false);
    setSelectedProjectId(null);
  };

  const handleTableChange = (
    pagination: Pagination,
    filters: ProjectFilter | Record<string, FilterValue | null>,
    sorter: Sorter<Project> | Sorter<Project>[],
    extra: Extra<Project>
  ) => {
    if (Array.isArray(sorter)) {
      sorter = sorter[0];
    }

    setTableParams({ pagination, filters, sorter });
    setDisplayedData(extra.currentDataSource);
  };

  return (
    <>
      {isCustomerOrUserProjectsTable ? (
        /* @ts-ignore */
        <Modal
          visible={changeProjectStatusModalVisible}
          onCancel={handleModalClose}
          {...MODAL_PROPS}
        >
          <ChangeProjectStatusModal
            projectId={selectedProjectId}
            handleModalClose={handleModalClose}
            tableName={tableName}
          />
        </Modal>
      ) : null}
      <div className="ScaniflyAdmin-Wrapper Projects">
        <div className="ScaniflyAdmin-SubHeader">
          <GoBackButton />
          <Input
            placeholder={t('Shared.search')}
            prefix={<SearchIcon />}
            aria-label="search projects"
            onChange={debounce((e) => {
              setSearchText(e.target.value.toLowerCase());
            }, 400)}
            className="ScaniflyAdmin-Search"
            data-testid="searchbox"
          />
          <p data-testid="project-count">{projectCount} projects</p>
          <div>{isCustomerOrUserProjectsTable ? <UploadProjectButton /> : null}</div>
          <CSVExportButton
            tableName={tableName}
            columnTitles={
              isCustomerOrUserProjectsTable
                ? [...PROJECTS_TABLE_CSV_COLUMN_TITLES, ...DDS_COLUMN_TITLES]
                : PROJECTS_TABLE_CSV_COLUMN_TITLES
            }
            dataSource={displayedData}
            searchTerm={setSearchText}
            prepareCSVData={handleProjectsCSVExport}
            {...extraProps}
            companyId={companyId}
            userId={userId}
          />
        </div>
        <Table
          className="ScaniflyAdmin-Table"
          columns={getAdminProjectTableColumns(tableName, openChangeStatusModalForProject)}
          dataSource={dataSource}
          onChange={(pagination, filters, sorter, extra) =>
            handleTableChange(pagination, filters, sorter, extra)
          }
          rowKey="id"
          scroll={{ x: 'auto', y: 'calc(100vh - 34rem)' }}
          loading={{
            indicator: (
              <div className="ScaniflyAdmin-Spinner">
                <Spin size="large" />
              </div>
            ),
            spinning: isProjectsLoading,
          }}
          pagination={{
            total: baseData?.projectCount,
            pageSize: PROJECT_LIST_SIZE,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      </div>
    </>
  );
};

export default BaseProjects;
