import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { MaintenanceReport } from '@cpm/scanifly-shared-data';
import { Button, Divider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import cn from 'classnames';
import { useFormik } from 'formik';

import {
  allMaintenanceReportsRequested,
  maintenanceReportUpdateRequested,
} from 'state/slices/maintenanceReport/maintenanceReportSlice';
import { AppDispatch } from 'state/store';

import { renderValidationMessage, validateStatus } from 'helpers/utils/formValidationHelpers';
import { openNotification } from 'helpers/utils/openNotification';

import { ReactComponent as EditIcon } from 'assets/icons/edit-icon.svg';
import { ReactComponent as SaveIcon } from 'assets/save.svg';

import { FORM_CONTROLS, formFields, initialValues } from '../constants/reportDisplay';
import { reportDisplayValidationSchema } from '../constants/reportDisplayValidationSchema';
import './FormDisplay.scss';

const FormDisplay = ({
  report,
  isFormEditable,
  setIsFormEditable,
}: {
  report?: MaintenanceReport;
  isFormEditable: boolean;
  setIsFormEditable: Function;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const toFormData = ({ report }: { report: any }) =>
    Object.fromEntries(Object.values(FORM_CONTROLS).map((value) => [value, report?.[value]]));

  const initialData = report ? toFormData({ report }) : initialValues;

  const { touched, isValid, dirty, errors, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validationSchema: reportDisplayValidationSchema,
    onSubmit: (values) => {
      handleUpdateReport(values as MaintenanceReport);
    },
  });

  const onUpdateSuccess = () => {
    openNotification({
      type: 'success',
      title: 'Success!',
      text: `${report?.title} has been successfully updated!`,
    });
    if (report?.project) {
      dispatch(allMaintenanceReportsRequested({ projectId: report.project }));
    }
    setIsFormEditable(false);
  };

  const handleUpdateReport = (values: MaintenanceReport) => {
    if (report?.project) {
      dispatch(
        maintenanceReportUpdateRequested({
          onUpdateSuccess,
          reportId: report.id,
          projectId: report.project,
          summary: values.summary,
          weatherConsiderations: values.weatherConsiderations,
          issues: values.issues,
          recommendedFixesAndImprovements: values.recommendedFixesAndImprovements,
          notesForGeneralMaintenance: values.notesForGeneralMaintenance,
          notesForThermal: values.notesForThermal,
        })
      );
      resetForm();
    }
  };

  const handleCancel = () => {
    setIsFormEditable(false);
  };

  const isFormValid = isValid && dirty;

  const renderButton = () => {
    if (!isFormEditable) {
      return (
        <Button
          onClick={() => setIsFormEditable(true)}
          className="Button--Blue"
          data-testid="edit-button"
        >
          <EditIcon />
          {t('MaintenanceReport.buttonText.edit')}
        </Button>
      );
    } else if (!isFormValid) {
      return (
        <Button
          onClick={handleCancel}
          className="Button--Red"
          autoFocus={!isFormValid}
          data-testid="cancel-button"
        >
          {t('MaintenanceReport.buttonText.cancel')}
        </Button>
      );
    }
    return (
      <Button
        onClick={() => handleSubmit()}
        disabled={!isFormValid}
        aria-disabled={!isFormValid}
        className={cn('Button--Green', { disabled: !isFormValid })}
        autoFocus={isFormValid}
        data-testid="submit-button"
      >
        <SaveIcon />
        {t('MaintenanceReport.buttonText.save')}
      </Button>
    );
  };

  return (
    <div className="FormDisplay">
      <div className="FormDisplay-TitleAndButtonWrapper">
        <h2 className="FormDisplay-Title">{report?.title}</h2>
        {renderButton()}
      </div>
      <Divider />
      <form onSubmit={handleSubmit}>
        {formFields.map(({ fieldName, title }) => (
          <div key={fieldName} className="FormDisplay-FieldWrapper">
            <label className="FormDisplay-Label" htmlFor={fieldName}>
              {title}
            </label>
            <div className="FormDisplay-TextareaWrapper">
              <TextArea
                {...getFieldProps(fieldName)}
                placeholder="Enter notes here"
                aria-label={title}
                className={cn(
                  'CreateReportModal-Input',
                  validateStatus(touched, errors, fieldName)
                )}
                id={fieldName}
                disabled={!isFormEditable}
                autoSize={{ minRows: 1 }}
              />
              <div className="Form-Error">
                {renderValidationMessage(touched, errors, fieldName)}
              </div>
            </div>
          </div>
        ))}
      </form>
    </div>
  );
};

export default FormDisplay;
